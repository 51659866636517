
import globalConfig from '../globalConfig.js'
var config = {};
//import config from './backend_config.json'

const pako = require('pako');

export default class Backend
{
//    websocket;
    action;
    appCallback;
//    login;


    constructor()
    {
        config = globalConfig.config.backendConfig;
        this.websocket = undefined;
        this.uri = config.server_url;
        this.sess_context = config.sess_context;
        //this.video_url = config.video_url;

        this.connection_alive = 0;
        this.connection_counter = 1;

        this.packageFrame = {
            command: "",
            parameters: {
                life_time: 0,
                sess_id: "",
                sess_key: "",
                guest_key: "",
                sess_context: this.sess_context,
                dev_name: "",
                client_id: "",
                dev_type: "",
                mic_blocking: true,
                subtitle_showing: true,
                num_of_devices: 0,
            }
        };

        this.state = {
            video_id: "",
            video_type: "",
            video_time: 0.0,
            video_length: 0.0,
            video_question: "",
            query_text: "",
            intent_confidence: 0.0,
            idle_loop: "",
            mic_blocked: false,
            show_respondent_popup: false,
            device_list: [],
            event_counter: 0,
            error_code: "",
        };

        /*this.recorderState = {
            video_id: "KRAUSE_0020",
            video_length: 79.68,
            video_time: 15.23,
            video_type: "answer",
            video_question: "Auf welche Weise wurden sie in ihrem Leben diskriminiert"
        }*/

        this.info = {
            dev_type: "",
            server_audio_response: false,
            audio_resends: 0,
            interval: undefined,
            reconnected: false,
            timer_server_alive: undefined,
            timer_reconnect: undefined,
            alive: true,
            respondent_popup_client: false,
            respondent_msg: undefined,
            respondent_ack: false,
            respondent_resends: 0,
        };

        this.client_status = {
            command: "status_update",
            video_dbg: undefined
        };
            

        this.loginPckg = {};



        this.messageEvent = this.messageEvent.bind(this);
        this.errorEvent = this.errorEvent.bind(this);
        this.reopenEvent = this.reopenEvent.bind(this);
        this.openEvent = this.openEvent.bind(this);
        this.processMsg = this.processMsg.bind(this);
        this.reconnect = this.reconnect.bind(this);
        this.serverConnectObserver = this.serverConnectObserver.bind(this);
        this.audioResend = this.audioResend.bind(this);
        this.sendData = this.sendData.bind(this);
        this.sendDevType = this.sendDevType.bind(this);
        this.play_video_file = this.play_video_file.bind(this);
        this.sendClientStatus = this.sendClientStatus.bind(this);
        this.select_respondent = this.select_respondent.bind(this);
        this.check_respondent_ack = this.check_respondent_ack.bind(this);
        this.login = {};
        this.connection_id = '';
        console.log(this.packageFrame);
    }

    serverConnectObserver() {
        //console.log("********time is up", this.connection_counter, this.connection_alive);
        if(this.connection_counter !== this.connection_alive) {
            this.connection_counter = this.connection_alive;
            this.info.alive = true;
        } 
        else {
            console.log(">>>>>>> connection dead");
            this.info.alive = false;
            this.appCallback("connection_lost");
            clearInterval(this.info.interval);
            clearInterval(this.info.timer_server_alive);
            this.websocket.close()
            this.connection_counter = 1;
            this.connection_alive = 0;
            //this.info.timer_reconnect = setInterval(this.reconnect, 2000);
            this.reconnect();
            console.log("reconnecting");
        }
    }

    

    messageEvent(ev)
    {
        var msg = pako.ungzip(ev.data);
        var st = new TextDecoder("utf-8").decode(msg);
        var pckg = JSON.parse(st);
        this.processMsg(pckg);
        this.info.alive = true;
    }

    errorEvent(err)
    {
        console.log("!!!!!! Error server connection", err);
        //this.appCallback("serverError");
        //setTimeout(this.reconnect, 1000);
    }

    reopenEvent(ev)
    {
        //clearInterval(this.info.timer_reconnect);i
        this.info.alive = true;
        console.log("REOPEN EVENT: connection established", this.websocket);
        //this.info.interval = setInterval(this.sendClientStatus, 1000);
        if(this.info.dev_type != 'integrated') {
            this.info.timer_server_alive = setInterval(this.serverConnectObserver, 5000);
        }
        this.appCallback("reconnected");
        this.info.reconnected = false;
        this.info.respondent_popup_client = false;
        setTimeout(this.sendDevType, 100);
    }
    
    openEvent(ev)
    {
        console.log("OPEN EVENT: connection established");
        this.info.interval = setInterval(this.sendClientStatus, 1000);
        this.appCallback("connected");
        //this.info.reconnected = false;
        //setTimeout(this.sendDevType, 100);
    }

    sendDevType ()
    {
        if (this.info.reconnected) {
            console.log("reconnect: send dev type");
            //var data = {
            //    command: "dev_type",
            //    parameters:{
            //        life_time: 0,
            //        sess_id: "",
            //        sess_key: "",
            //        dev_name: "",
            //        client_id: "",
            //        sess_context: "",
            //        dev_type: this.info.dev_type,
            //        mic_blocking: true,
            //        subtitle_showing: true
            //    }
            //};
        
            var data = this.packageFrame;
            data.command = "dev_type";
            data.parameters.dev_type = this.info.dev_type;
            console.log("Dev Type: ", data);
            this.sendData(data);
            this.info.reconnected = false
            this.info.interval = setInterval(this.sendClientStatus, 1000);

        } else {
            console.log("reconnect: dev type not able to be set yet");
            setTimeout(this.sendDevType, 500);
        }

    }

    play_video_file(play_info) {
        var msg = {
            command: "play_video_file",
            play_info: {
                video_file: play_info.video_file,
                next_idle_loop: play_info.next_idle_loop 
            }
        };

        console.log("play_vid: ", msg);
        this.info.respondent_popup_client = false;
        //this.select_respondent("selection_finished");

        this.sendData(msg);
    }

    select_respondent(name) {
        var msg = {
            command: "select_respondent",
            respondent_list: [name]
        };

        console.log("called select_respondent: ", msg);
        if (name != "selection_finished") {
            this.info.respondent_popup_client = true;
        } else {
            this.info.respondent_popup_client = false;
        }

        this.info.respondent_msg = msg;
        this.info.respondent_ack = false;
        this.info.respondent_resends = 0;

        this.sendData(msg);

        setTimeout(this.check_respondent_ack, 500);
    }

    check_respondent_ack() {
        
        if(this.info.respondent_ack === false 
            && this.info.respondent_resends < 3) {
            this.sendData(this.info.respondent_msg);
            this.info.respondent_resends += 1;
            setTimeout(this.check_respondent_ack, 500);
            console.log(">>> NACK Respondent");
        } else if (this.info.respondent_resends > 2) {
            this.info.respondent_popup_client = false;
            this.appCallback("connection_lost"); 
        } else {
            console.log(">>> ACK Respondent");
        }
        

    }

    processMsg(msg)
    {

        //console.log("processing");
        //if(msg.package !== undefined)
        {
            if(msg.command !== undefined)
            {
                switch(msg.command)
                {
                    case "reqAction":
                        //console.log("action requested", msg.video.url_prefix);
                        if(this.action === 'joinSess'){
                            this.packageFrame.command = this.action;
                            this.packageFrame.parameters.sess_id = this.login.id;
                            this.packageFrame.parameters.sess_key = this.login.key;
                            this.packageFrame.parameters.guest_key = ""
                            this.packageFrame.parameters.dev_name = this.login.name;
                            this.packageFrame.parameters.client_id = this.connection_id;
                            console.log("joinSess: ", this.packageFrame);
                            //this.packageFrame.parameters.mic_blocking = this.login.
                        }
                        else if(this.action === 'newSess') {
                            var date = Date.now();
                            this.packageFrame.command = this.action;
                            this.packageFrame.parameters.sess_id = this.login.sessionID;
                            this.packageFrame.parameters.sess_key = this.login.masterKey;
                            this.packageFrame.parameters.guest_key = this.login.guestKey;
                            this.packageFrame.parameters.life_time = this.login.expiresBy; 
                            //TODO delete this line after testing
                            //this.packageFrame.parameters.life_time = date + 100*1000;

                            this.packageFrame.parameters.sess_context = this.sess_context;
                            this.packageFrame.parameters.client_id = this.connection_id;
                            this.packageFrame.parameters.mic_blocking = this.login
                                .options.microphonesEnabled;
                            this.packageFrame.parameters.subtitle_showing = this.login
                                .options.showQuestions;
                            this.packageFrame.parameters.num_of_devices = parseInt(this.login
                                .numberOfDevices);
                        }

                        this.sendData(this.packageFrame);
                        //this.appCallback({type: 'video_info', video: msg.video});
                        this.info.reconnected = true;
                        break;

                    case "credentials_ack_host":
                        console.log("credit ack host", msg.video.url_prefix);
                        this.appCallback("credentials_ack_host");
                        this.appCallback({type: 'video_info', video: msg.video});
                        //setTimeout(this.play_video_file, 10000, 
                        //{video_file: "KWIEK_0015"});
                        break;
                    
                    case "credentials_ack_guest":
                        console.log("credit ack guest", msg.video.url_prefix);
                        this.appCallback("credentials_ack_guest");
                        this.appCallback({type: 'video_info', video: msg.video});
                        //setTimeout(this.play_video_file, 10000, 
                        //{video_file: "KWIEK_0015"});
                        break;

                    case "credentialsNACK":
                        this.appCallback("credentialsNACK");
                        clearInterval(this.info.interval);
                        this.info.alive = true;
                        this.connection_alive += 5;
                        clearInterval(this.info.timer_server_alive);
                        console.log("intervals cleared");
                        //this.logout();
                        //this.info.alive = true;
                        break;

                    case "max_clients":
                        console.log("max clients reached");
                        this.appCallback("max_clients");
                        break;

                    case "ack_new_sess":
                        console.log("New session created");
                        this.appCallback("ack_new_sess");
                        this.logout();
                        break;

                    case "nack_new_sess":
                        console.log("Could not create new session");
                        this.appCallback("nack_new_sess");
                        this.logout();
                        break;

                    case "playAnswer":
                        console.log("playAnswer received");
                        this.appCallback("playAnswer");
                        break;

                    case 'show_respondent_list': 
                        console.log("respondent list received: ", msg.respondent_list);
                        this.appCallback({type: 'show_respondent_list', 
                            list: msg.respondent_list});
                        break;

                    case "ack_audio_request":
                        console.log("ACK Audio, Hacken setzen");
                        this.info.server_audio_response = true;
                        this.appCallback("ack_audio_request");
                        break;

                    case "nack_audio_request":
                        console.log("NACK Audio, X setzen");
                        this.info.server_audio_response = true;
                        this.appCallback("nack_audio_request");
                        break;

                    case "ack_respondent_request":
                        console.log("ACK RECEIVED RESP");
                        this.info.respondent_ack = true;
                        break;

                    case "bad_audio_popup":
                        this.appCallback("bad_audio_popup");
                        break;
                    
                    case "no_match_popup":
                        this.appCallback("no_match_popup");
                        break;

                    case "status":
                        //console.log("status msg");
                        this.statusUpdate(msg);
                        break;

                    case "flagged_answers_download":
                        this.appCallback({type: "flagged_answers_download",
                            data: msg.flagged_answers});

                    default:
                        this.appCallback(msg.command);
                        break;
                }
            }
            else
            {
                // no command specified
            }
        }
        //else
        {
            // no package
        }
    }

    statusUpdate(msg)
    {
        let event_list = [];
        //console.log("recv status, old state: ", msg.status, this.state);
        if(this.info.dev_type !== "") {
            if(msg.status.event_counter > this.state.event_counter) { 
                //determin event list
                if(this.state.video_id !== msg.status.video_id 
                    && msg.status.video_type === "answer") {
                    event_list.push('play_answer');
                } else if(this.state.video_id !== msg.status.video_id 
                    && msg.status.video_type === "idle") {
                    if(this.info.respondent_popup_client === true) {
                        event_list.push('show_popup');
                        console.log("Event SHOW POPUP received");
                    }
                }

                if(msg.status.video_jump_event) {
                    event_list.push('play_answer');
                    console.log("jump event recv: ", msg.status);
                }

                if(this.state.mic_blocked !== msg.status.mic_blocked) {
                    event_list.push('mic_event');
                }
                if(msg.status.video_id === "IDLE" 
                    && msg.status.video_type === "idle" 
                    && this.state.video_id !== "IDLE") {
                    event_list.push('skip_answer_event');
                }

                if (msg.status.mic_blocking !== this.state.mic_blocking) {
                    event_list.push('change_session_details');
                } else if (msg.status.subtitle_showing !== this.state.subtitle_showing) {
                    event_list.push('change_session_details');
                }

                if(msg.status.error_code !== this.state.error_code) {
                    event_list.push(msg.status.error_code);
                }
                //else {
                //    event_list.push('dev_list_event');
                //}

            } 



            this.state = msg.status;
            //this.state.video_url = this.video_url + msg.status.video_id;
            //this.state.idle_url = this.video_url + msg.status.idle_loop;
            this.state.event_list = event_list;
            //console.log("state in statUpdate: ", this.state);

            this.appCallback({'type': "status_update", 'state': this.state});
            this.state.event_list = [];
        }
        this.connection_alive += 1;
        
    }

    set_dev_type(type)
    { 
        this.info.dev_type = type;
        this.info.timer_server_alive = setInterval(this.serverConnectObserver, 5000);
    }


    sendData(data)
    {
        console.log("Data: ", data);
        var serialized;
        serialized = JSON.stringify(data);
        //console.log(serialized);
        this.websocket.send(pako.gzip(serialized));
    }

    setVideoDbg(video_dbg) {
        this.client_status.video_dbg = video_dbg;
    }

    /*setToggleButtons(buttons) {
        this.client_status.toggle_buttons = buttons;
        console.log(this.client_status);
    }*/

    sendAudio(data) 
    {
        this.sendData(data);
        this.info.audio = data;
        setTimeout(this.audioResend, 1000);
    }

    sendClientStatus()
    {
        this.sendData(this.client_status);
        //console.log("-----> send status", this.client_status);
    }

    audioResend() 
    {
        if (this.info.server_audio_response) {
            this.info.server_audio_response = false;
            this.info.audio_resends = 0;
            console.log("-----> audio received");
        } else if (this.info.server_audio_response === false && this.info.audio_resends < 2) {
            if(this.websocket.readyState == 1) {
                this.sendData(this.info.audio);
            }
            this.info.audio_resends += 1;
            setTimeout(this.audioResend, 1000);
            console.log("-----> audio resending");
        } else {
            // resending failed
            // TODO what different message then the X should be presented to user?
            this.info.audio_resends = 0;
            this.appCallback("failed_audio_request");
            console.log("-----> audio sending aborted");
        }
    }


    logout() {
        clearInterval(this.info.interval);
        clearInterval(this.info.timer_server_alive);
        this.websocket.close();
        
        this.info = {
            dev_type: "",
            server_audio_response: false,
            audio_resends: 0,
            interval: undefined,
            reconnected: false,
            timer_server_alive: undefined,
            timer_reconnect: undefined,
            alive: true,
            respondent_popup_client: false,
            respondent_msg: undefined,
            respondent_ack: false,
            respondent_resends: 0,
        };

    }
        

    
    connect(action, loginData, connection_id, callback)
    {
        console.log(">>>>>>> CONNECTING <<<<<<<<");
        this.action = action;
        this.login = loginData;
        this.connection_id = connection_id;
        console.log(connection_id);
        this.appCallback = callback;
        console.log("Login Data in Backend: ", loginData);
        this.websocket = new WebSocket(this.uri);
        console.log(this.websocket);
        this.websocket.binaryType = "arraybuffer";
        this.websocket.addEventListener("open", this.openEvent);
        this.websocket.addEventListener("message", this.messageEvent);
        this.websocket.addEventListener("error", this.errorEvent);
        //this.info.interval = setInterval(this.sendClientStatus, 1000);
    }

    reconnect() 
    {
        //console.log("hello from the reconnect()", this.websocket);
        if(this.websocket.readyState != 1 && this.info.alive == false) {// && this.websocket.readyState != 0) {
            this.websocket.close();
            this.websocket = new WebSocket(this.uri); 
            console.log("call to reconnect");
            this.websocket.binaryType = "arraybuffer";
            this.websocket.addEventListener("open", this.reopenEvent);
            this.websocket.addEventListener("message", this.messageEvent);
            this.websocket.addEventListener("error", this.errorEvent);

            setTimeout(this.reconnect, 1000);
        } 
    }
        
}


