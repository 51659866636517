import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types
import CardCarousel from './cardCaroussel.js'; /* Original Code @ https://codepen.io/WillyW/pen/wZebow */
import './carouselSelector.css';

import FlatArrowIcon from '../icons/arrow_flat_right.svg';

export default class CarouselSelector extends React.Component {
    static defaultProps = {
        width: window.innerWidth,
        height: '240px',
        top: 0,
        visibility: 'visible',
        buttonWidth: 50,
        defaultPortraitList: [],
    };

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        top: PropTypes.number,
        visibility: PropTypes.string,
        buttonWidth: PropTypes.number,
        defaultPortraitList: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            componentShouldUpdate: false,
            portraitList: this.props.defaultPortraitList,
        }
        this.cardsContainer = null;
        this.carousel = null;
        this.cardCarousel = React.createRef();
        this.getSelected = this.getSelected.bind(this);
        this.setPortraitList = this.setPortraitList.bind(this);
        //const cardsController = document.querySelector(".card-carousel + .card-controller");
    }

    shouldComponentUpdate(nextProps, nextState){
        if (nextState.componentShouldUpdate === true) {
            this.setState({componentShouldUpdate: false})
            return true;
        }
        //update only on resize events to prevent reset of the carousel
        if ((nextProps.height !== this.props.height) ||
            (nextProps.width !== this.props.width) ||
            (nextProps.visibility !== this.props.visibility)){
            return true;
        } else {
            return false;
        }
    }

    componentDidMount(){
        this.cardsContainer = document.querySelector(".card-carousel");
        this.carousel = new CardCarousel(this.cardsContainer);
        //var e;
        //e.keyCode = 39;
        //this.carousel.controller(e)
    }

    getSelected(){
        var card;
        for (var i in this.cardCarousel.current.children) {
            card = this.cardCarousel.current.children[i];
            if (card.className !== undefined){
                if (card.className.includes('highlight')){
                    return this.state.portraitList[i].id;
                }
            }
        }
        return this.state.portraitList[0].id; //default
    }

    setPortraitList(portraitList){
        //note: this only sets a reference to the list
        this.setState({
            componentShouldUpdate: true,
            portraitList: portraitList
        })
    }

    render() {
        const scale = 0.87;

        const carouselStyle = {height: '100%', visibility: this.props.visibility}
        //const cardStyle = {height: this.props.width*0.62, width: this.props.width*0.56, visibility: this.props.visibility}
        const cardStyle = {height: this.props.height*scale, width: this.props.height*scale*0.848, visibility: this.props.visibility}
        //const containerStyle = {backgroundImage: "url('testportraits/physicist01.png')", backgroundSize: '100%'}

        return(
        <div className="flex-container-column"
            style = {{
                position: 'absolute',
                height: this.props.height,
                minHeight: this.props.height,
                visibility: this.props.visibility}}>
            <div 
                className="carousel-container position-absolute"
                style={{
                    height: '100%',
                    left: this.props.buttonWidth,
                    width: this.props.width - 2*this.props.buttonWidth,}}>
                <div ref={this.cardCarousel} className="card-carousel" style={carouselStyle}>
                    {this.state.portraitList.map((portrait,index) =>
                    <div className="card fade-in fade-out" id={index} key={index} style={cardStyle}>
                        <div className="image-container" 
                            style={{
                                backgroundImage: `url('${portrait.imgUrl}')`, 
                                backgroundSize: '99%'}}>
                        </div>
                        <label className="card-text">{portrait.name}</label>
                    </div>
                    )}
                </div>
            </div>
            <button 
                className="btn-invisible position-absolute"
                style={{
                    width: this.props.buttonWidth, 
                    height: '100%', 
                    left: '0', 
                    top: '0'}}
                    onClick={() => {var e = {keyCode: 37}; this.carousel.controller(e)}}>
                <img 
                    style={{width: this.props.buttonWidth*0.6, 
                        maxWidth: this.props.buttonWidth*0.6, 
                        maxHeight: '80%'}}
                    id="leftarrow" 
                    className="filter-supportelement flip-horizontal" 
                    src={FlatArrowIcon} 
                    alt="left arrow" 
                    width="80%"  
                    draggable="false"/>
            </button>
            <button 
                className="btn-invisible position-absolute" 
                style={{
                    width: this.props.buttonWidth, 
                    height: '100%', 
                    right: '0', 
                    top: '0'}}
                    onClick={() => {var e = {keyCode: 39}; this.carousel.controller(e)}}>
                <img 
                    style={{width: this.props.buttonWidth*0.6, 
                        maxWidth: this.props.buttonWidth*0.6, 
                        maxHeight: '80%'}}
                    id="rightarrow"
                    className="filter-supportelement" 
                    src={FlatArrowIcon} 
                    alt="left arrow" 
                    width="80%"  
                    draggable="false"/>
            </button>
        </div>
    )};
}
