import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import './sessionBanner.css'
import globalConfig from '../globalConfig.js'
var config = {};

export default class SessionBanner extends React.Component {
    static defaultProps = {
        type: "header", //"header" or "footer"
        width: window.innerWidth,
        height: window.innerHeight
    };

    static propTypes = {
        type: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);
    }

    render(){
        var pathLargeImage, pathSmallImage;
        var className;
        try {
            if (this.props.type === "header"){
                pathLargeImage = config.banners.headerlarge
                pathSmallImage = config.banners.headersmall
                className = "session-banner session-banner-header" 
            } else {
                pathLargeImage = config.banners.footerlarge
                pathSmallImage = config.banners.footersmall
                className = "session-banner session-banner-footer"
            }
        } catch (e) {
            //there is no path in config
            return(null)
        }
        var imgSrc = ""
        if (this.props.width < 1080){
            imgSrc = pathSmallImage
        } else {
            imgSrc = pathLargeImage
        }
        
        if (imgSrc === "" || imgSrc === undefined) {
            return (null)
        } else {
            imgSrc = window.location.origin + '/default/' + imgSrc
            return (
                <div className={className}>
                    <img src={imgSrc} onError={(e) => e.target.style.display = 'none'}/>
                </div>
            );
        }
    }
}
