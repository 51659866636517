import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import LoadingIcon from '../icons/loading_icon_bold.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class WaitingForServerOverlay extends React.Component {
    static defaultProps = {
        visible: false, //hide overlay by default
    };

    static propTypes = {
        visible: PropTypes.bool,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);
        this.state = {
        }
    };

    render(){
        
        return (
            <div className="modal-background" style={{display: this.props.visible ? 'flex' : 'none'}}>
                <div> 
                    <img 
                        height="80px"
                        className="filter-lightgrey spin"
                        src={LoadingIcon} 
                        alt="reconnecting" />
                    <p>{config.copy.waitingforserver.msg}</p>
                </div>
            </div>
        );
    }
}