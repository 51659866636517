import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import LogoutButton from '../utils/logoutButton.js';
import ModalPopup from '../utils/modalPopup.js';
import SessionBanner from '../utils/sessionBanner.js';

import VideoIcon from '../icons/video_icon.svg';
import MicIcon from '../icons/mic_icon.svg';
import ControlsIcon from '../icons/playpause_icon.svg';
import IntegratedIcon from '../icons/integrated_session_icon.svg';

import globalConfig from '../globalConfig.js'
var config = {};


export default class appSelector extends React.Component {
    static defaultProps = {
        setSelectedApp: undefined,
        setVideoFormat3D: undefined,
        handleLogout: undefined,
        handleReload: undefined,
        userType: 'guest',
    };

    static propTypes = {
        setSelectedApp: PropTypes.func,
        setVideoFormat3D: PropTypes.func,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        userType: PropTypes.string,
    };
    
    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            popupOpen: false,
        }
        this.handleResize = this.handleResize.bind(this);
        this.handleSelectApp = this.handleSelectApp.bind(this);
        this.handleSetFormat3D = this.handleSetFormat3D.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };

    handleResize(){
        //detect when it is necessary to rearrage flex-box items in columns/rows
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleSelectApp(appName) {
        if (appName === 'player') {
            //ask for video format
            this.setState({popupOpen: true})

        } else {
            //continue directly to app
            this.props.setSelectedApp(appName);
        }
    }

    handleSetFormat3D(format) {
        this.props.setVideoFormat3D(format)
        this.props.setSelectedApp('player');
    }

    handleClose() {
        this.setState({popupOpen: false})
    }

    render() {
        const BUTTON_WIDTH = 260;
        const BUTTON_HEIGHT = 150;
        const BUTTON_RADIUS = 15;
        const BUTTON_MARGIN = 2;

        var selectorButtons = [];
        if (this.props.userType === 'host'){
            selectorButtons = [ 
                { id: 0, src: VideoIcon, imageheight:"100%", buttontext: config.copy.appselector.video, apps: 'player'},
                { id: 1, src: MicIcon, imageheight:"100%", buttontext: config.copy.appselector.recorder, apps: 'recorder'},
                { id: 2, src: ControlsIcon, imageheight:"100%", buttontext: config.copy.appselector.controls, apps: 'controls'},
            ]
        } else { // === 'guest'
            selectorButtons = [ 
                { id: 0, src: VideoIcon, imageheight:"100%", buttontext: config.copy.appselector.video, apps: 'player'},
                { id: 1, src: MicIcon, imageheight:"100%", buttontext: config.copy.appselector.recorder, apps: 'recorder'},
                { id: 2, src: IntegratedIcon, imageheight:"100%", buttontext: config.copy.appselector.integrated, apps: 'integrated'},
            ]
        }

        var buttonAlignment;
        if (this.state.width > 3*(BUTTON_WIDTH + 2*BUTTON_MARGIN)) {
            buttonAlignment = 'row';
        } else {
            buttonAlignment = 'column';
        }

        return (
            <div className="app-body">
                <SessionBanner type="header" width={this.state.width}/>
                <div id="container"
                    style={{
                        width:"100%",
                        height: "100%",
                        display: 'flex',
                        flexDirection: buttonAlignment,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <LogoutButton handleLogout={this.props.handleLogout} handleReload={this.props.handleReload}/>
                    {selectorButtons.map((loginbtn) =>
                        <button
                            onClick={() => this.handleSelectApp(loginbtn.apps)}
                            className="btn btn-primary"
                            key={loginbtn.id}
                            style={{
                                width: BUTTON_WIDTH, 
                                height: BUTTON_HEIGHT,
                                padding: '0px',
                                margin: BUTTON_MARGIN,
                                borderRadius: BUTTON_RADIUS}}>
                            <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                <img 
                                    key={"loginimg" + loginbtn.id}  
                                    src={loginbtn.src} 
                                    alt="" 
                                    height="110px"
                                    style={{marginBottom:'-2px'}}/>
                                {loginbtn.buttontext}
                            </div>
                        </button>
                    )} 
                </div>
                <SessionBanner type="footer" width={this.state.width}/>
                <ModalPopup 
                    title = {config.copy.appselector.formatpopup.title}
                    msg = {config.copy.appselector.formatpopup.body}
                    abortButtonText = {config.copy.appselector.formatpopup.format3d}
                    actionButtonText = {config.copy.appselector.formatpopup.format2d}
                    open = {this.state.popupOpen}
                    maxwidth = {Math.min(window.innerWidth, 640)}
                    handleSecondaryAction = {() => this.handleSetFormat3D(true)}
                    handlePrimaryAction = {() => this.handleSetFormat3D(false)}
                    handleCancel={this.handleClose}/>
            </div>
        );
    };
}