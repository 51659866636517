import React from 'react';
import PropTypes from 'prop-types'; /*npm install --save prop-types*/

import ArrowRightIcon from '../icons/play_icon_bold.svg';

import './collapseBar.css';

class CollapseBar extends React.Component {

    static defaultProps = {
        controlsOpen: true,     /* State of collapsable control */
        name: 'unknown',        /* Name of collapsable control */
    };
    
    static propTypes = {
        controlsOpen: PropTypes.bool,
        name: PropTypes.string,
    };

    constructor(props) {
        super(props)

        this.state = {
            controlsOpen: props.controlsOpen, /* State of collapsable control */
        }
    }

    render () {
        var imgFilter;
        if (this.state.controlsOpen) {
            imgFilter = "rotate-90clockwise";
        } else {
            imgFilter = "rotate-90counterclockwise";
        }

        return (
            <div className="collapsable">
                <button className="btn btn-primary collapseBarBtn"
                    onClick={() => this.setState({ controlsOpen: !this.state.controlsOpen })}
                    aria-controls="collapsable-element"
                    aria-expanded={this.state.controlsOpen}>
                    <div className="flex-container-row" style={{wrap: 'nowrap', justifyContent: 'space-between'}}>
                        <p className="collapseBarBtnText" style={{width: 'calc(100%-30px)'}}>{this.props.name}</p>
                        <img 
                            className={imgFilter}
                            src={ArrowRightIcon} 
                            alt="arrow"/>
                    </div>
                </button>
                <div id="collapsable-element" className={this.state.controlsOpen ? "collapsable-container collapsable-show" : "collapsable-container collapsable-hide"}>
                    {this.props.children}
                </div>
            </div>
        )
    }
};

export default CollapseBar