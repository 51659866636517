import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import ModalPopup from '../utils/modalPopup.js';
import SessionBanner from '../utils/sessionBanner.js';

import JoinIcon from '../icons/join_icon.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class LoginPage extends React.Component {
    static defaultProps = {
        maxWidth: 640,
    };

    static propTypes = {
        maxWidth: PropTypes.number,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            sessionID: undefined,
            sessionKey: undefined,
            deviceName: undefined,
            popupOpen: false,
            errortype: 0,
        }

        this.handleResize = this.handleResize.bind(this);
        this.handleDeviceNameChange = this.handleDeviceNameChange.bind(this);
        this.handleSessionIDChange = this.handleSessionIDChange.bind(this);
        this.handleSessionKeyChange = this.handleSessionKeyChange.bind(this);
        this.handleSessionKeyEnter = this.handleSessionKeyEnter.bind(this);
        this.handleJoinSession = this.handleJoinSession.bind(this);

        this.handlePopupClose = this.handlePopupClose.bind(this);
        this.handleGoHome = this.handleGoHome.bind(this);

        this.inputSessionIDRef = React.createRef();
        this.inputSessionKeyRef = React.createRef();
        this.inputDeviceNameRef = React.createRef();
        this.buttonJoinSessionRef = React.createRef();
    };


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (this.props.match && this.props.match.params){
            this.setState({
                sessionID: this.props.match.params.sessionID,
            })
        }

        if (this.props.location && this.props.location.state){
            if (this.props.location.state.sessionKey) {
                this.setState({
                    sessionKey: this.props.location.state.sessionKey,
                })
            }
            if (this.props.location.state.errortype) {
                this.setState({
                    popupOpen: true,
                    errortype: this.props.location.state.errortype || 0,
                });
            }
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleResize(){
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    handleDeviceNameChange(e){
        this.setState({deviceName: e.target.value});
    }

    handleSessionIDChange(e){
        this.setState({sessionID: e.target.value});
    }

    handleSessionKeyChange(e){
        this.setState({sessionKey: e.target.value});
    }

    handleSessionKeyEnter(e){
        if (e.keyCode === 13) {
            this.buttonJoinSessionRef.current.click();
        }
    }

    handleJoinSession(){
        this.props.history.push({pathname: "/app", state: {name: this.state.deviceName, id: this.state.sessionID, key: this.state.sessionKey}})
    }

    handlePopupClose(){
        this.setState({popupOpen: false});
    }

    handleGoHome(){
        this.props.history.push("/");
    }


    render() {

        var buttonDisabled = false;

        //check inputs!
        
        if (this.state.deviceName === undefined || this.state.deviceName === "" ||
            this.state.sessionID === undefined || this.state.sessionID === "" ||
            this.state.sessionKey === undefined || this.state.sessionKey === ""){
                buttonDisabled = true;
        }
        return (
            <main className="app-body">
                <SessionBanner type="header" width={this.state.width}/>
                <div className="flex-container-column" style={{height: "100%", justifyContent: 'center'}}>
                    <div style={{width: '100%', maxWidth: this.props.maxWidth, padding: '25px'}}>
                        <div className="grid-container" width="100%">
                            <label className="grid-itemleft label-onbackground" htmlFor="devicenameinput">{config.copy.login.devicename}</label>
                            <input type="text" className="grid-itemright input-onbackground" name="devicenameinput" 
                                ref={this.inputDeviceNameRef} 
                                onChange={this.handleDeviceNameChange}/>
                            <label className="grid-itemleft label-onbackground" htmlFor="sessionidinput">{config.copy.login.sessionid}</label>
                            <input type="text" className="grid-itemright input-onbackground" name="sessionidinput" 
                                ref={this.inputSessionIDRef} 
                                defaultValue={this.state.sessionID} onChange={this.handleSessionIDChange}/>
                            <label className="grid-itemleft label-onbackground" htmlFor="sessionkeyinput">{config.copy.login.password}</label>
                            <input type="text" autoComplete="off" className="grid-itemright input-onbackground" name="sessionkeyinput" 
                                ref={this.inputSessionKeyRef}
                                defaultValue={this.state.sessionKey} onChange={this.handleSessionKeyChange} onKeyUp={this.handleSessionKeyEnter}/>
                            <button
                                ref={this.buttonJoinSessionRef}
                                className="btn btn-primary grid-itemspan" 
                                onClick={this.handleJoinSession} 
                                disabled={buttonDisabled}
                                style={{width: '100%', flex: '1 1 0px', padding:'0px', align: 'center'}}>
                                <img 
                                    src={JoinIcon} 
                                    alt="Copy Icon" 
                                    height="90%"
                                    style={{marginRight:"5px"}}/>
                                    {config.copy.login.joinsession}
                            </button>
                        </div>
                    </div>
                </div>
                <SessionBanner type="footer" width={this.state.width}/>
                <ModalPopup 
                    title = {config.copy.login.errorpopup.title}
                    msg = {config.copy.login.errorpopup.messagetypes[this.state.errortype]}
                    abortButtonText = {config.copy.login.errorpopup.cancel}
                    actionButtonText = {config.copy.login.errorpopup.home}
                    open = {this.state.popupOpen}
                    maxwidth = {this.props.maxWidth}
                    handleSecondaryAction = {this.handlePopupClose}
                    handlePrimaryAction = {this.handleGoHome}/>
            </main>
        );
    };
}