import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import AudioRecorder from './audioRecorder.js'

import MicIcon from '../icons/mic_icon.svg';
import OkIcon from '../icons/ok_icon.svg';
import LoadingIcon from '../icons/loading_icon.svg';
import NotOkIcon from '../icons/close_icon.svg';
import BlockedIcon from '../icons/mic_icon_blocked.svg';

import globalConfig from '../globalConfig.js'
var config = {};

const MINIMAL_RECORDING_LENGTH = 1000;

export default class MicInput extends React.Component {
    static defaultProps = {
        width: window.innerWidth,
        height: window.innerHeight,
        handleAudioRecording: undefined,
    };

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        handleAudioRecording: PropTypes.func,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            microphone: 'ready',
            audioTestMode: false,
        }

        this.handleStartRecordingMouse = this.handleStartRecordingMouse.bind(this);
        this.handleStopRecordingMouse = this.handleStopRecordingMouse.bind(this);
        this.handleStartRecordingTouch = this.handleStartRecordingTouch.bind(this);
        this.handleStopRecordingTouch = this.handleStopRecordingTouch.bind(this);
        this.setProcessingDone = this.setProcessingDone.bind(this);
        this.setProcessingError = this.setProcessingError.bind(this);
        this.setMicrophoneReady = this.setMicrophoneReady.bind(this);
        this.setMicrophoneBlocked = this.setMicrophoneBlocked.bind(this);
        this.localhandleAudioRecording = this.localhandleAudioRecording.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.audioRecorderRef = React.createRef();
        this.testAudioRef = React.createRef();
        this.recordingStartTime = undefined;
        this.debugEnableTimer=null;
        this.debugEnableCounter=0;
        this.stopTimeout=null;
        this.isTouchDevice=false;
    };

    handleOnClick()
    {
        if (this.debugEnableTimer===null)
        {
            this.debugEnableTimer=setTimeout(() => {this.debugEnableCounter=0; this.debugEnableTimer=null;},2000);
        }
        else
        {
            this.debugEnableCounter++;
            if (this.debugEnableCounter==4)
            {
                this.toggleDebug();
                clearTimeout(this.debugEnableTimer);
                this.debugEnableTimer=null;
                this.debugEnableCounter=0;
            }
        }
    }

    toggleDebug()
    {
//        this.setState({microphone: 'ready'});
        this.audioRecorderRef.current.abortRecording();

        if (this.state.audioTestMode===true)
            this.setState({audioTestMode: false});
        else
            this.setState({audioTestMode: true});
    }

    handleStartRecordingMouse(){
        if (this.isTouchDevice===false) this.handleStartRecording();
    }
    handleStartRecordingTouch(){
        this.isTouchDevice=true;
        this.handleStartRecording();
    }
    handleStopRecordingMouse(){
        if (this.isTouchDevice===false) this.handleStopRecording();
    }
    handleStopRecordingTouch(){
        this.isTouchDevice=true;
        this.handleStopRecording();
    }

    handleStartRecording(){
        if (this.state.microphone !== 'ready'){
            return;
        }
        if (this.stopTimeout!==null)
        {
            clearTimeout(this.stopTimeout);
            this.stopTimeout=null;
            this.audioRecorderRef.current.stopRecording();
        }
//        console.log("MicInput Start Recording");
        this.audioRecorderRef.current.startRecording();
        this.recordingStartTime = Date.now();
        this.setState({microphone: 'recording'});       
    }

    setProcessingDone(){
        if (this.state.microphone === 'processing'){
            this.setState({microphone: 'done'});
        } 
    }

    setProcessingError(){
        if (this.state.microphone === 'processing'){
            this.setState({microphone: 'error'});
        } 
    }

    setMicrophoneBlocked(){
        if (this.state.microphone !== 'blocked'){
            this.setState({microphone: 'blocked'});
        } 
    }

    handleStopRecording(){
        if (this.state.microphone !== 'recording'){
            return;
        }
        //check if there is a recording of useable length (>1s)
//        console.log(this.state.microphone);
        if (Date.now() - this.recordingStartTime < MINIMAL_RECORDING_LENGTH){
//            console.log("MicInput Abort Recording");
            this.audioRecorderRef.current.abortRecording();
            this.setState({microphone: 'ready'});
            return;
        }
        
//        console.log("MicInput Process Recording");
        this.setState({microphone: 'processing'});
        this.stopTimeout=setTimeout(this.audioRecorderRef.current.stopRecording.bind(this.audioRecorderRef.current), 500); //continue recording a little longer to catch the last word properly
    }

    setMicrophoneReady(){
        clearInterval(this.timer);
        this.setState({microphone: 'ready'});
        this.audioRecorderRef.current.initAnalyser();
    }
     

    localhandleAudioRecording(chunk){
//        console.log("Local audio test");
        const blob = new Blob(chunk, { 'type' : "audio/ogg" });
        this.testAudioRef.current.src=window.URL.createObjectURL(blob);
        this.setMicrophoneReady();
    }

    render() {
        const BUTTON_MAX_SIZE = 200;
        const BUTTON_MIN_SIZE = 60;
        var canvas_diam = Math.min(this.props.width, this.props.height);
        var BUTTON_SIZE = Math.max(Math.min(BUTTON_MAX_SIZE, canvas_diam/2.5), BUTTON_MIN_SIZE);
        const BUTTON_RADIUS = BUTTON_SIZE/2;
        var imgID;
        switch (this.state.microphone){
            default:
            case 'ready':
                imgID = 0;
                break;
            case 'recording':
                imgID = 1;
                break;
            case 'processing':
                imgID = 2;
                break;
            case 'done':
                imgID = 3;
                break;
            case 'error':
                imgID = 4;
                break;
            case 'blocked':
                imgID = 5;
                break;
        }

        var micImages = [ 
            { id: 0, src: MicIcon, imageheight:"70%", statustext: config.copy.recorder.instruction, class: "", visibility: 'hidden'},
            { id: 1, src: MicIcon, imageheight:"70%", statustext: config.copy.recorder.instruction, class: "", visibility: 'hidden'},
            { id: 2, src: LoadingIcon, imageheight:"70%", statustext: config.copy.recorder.waiting, class: "spin", visibility: 'hidden'},
            { id: 3, src: OkIcon, imageheight:"70%", statustext: config.copy.recorder.playing, class: "", visibility: 'hidden'},
            { id: 4, src: NotOkIcon, imageheight:"70%", statustext: config.copy.recorder.error, class: "", visibility: 'hidden'},
            { id: 5, src: BlockedIcon, imageheight:"70%", statustext: config.copy.recorder.blocked, class: "", visibility: 'hidden'},
        ]

        micImages[imgID].visibility = 'visible';

        //top: '-12px', /*create overlap, so that animation appears behind box-shadow of caroussel selector*/
        return (           
            <div className="flex-container-column"
                style={{
                    justifyContent: 'flex-end',
                    height: this.props.height, /*create overlap, so that animation appears behind box-shadow of caroussel selector*/
                    minHeight: this.props.height, /*create overlap, so that animation appears behind box-shadow of caroussel selector*/
                    width: this.props.width}}
                onClick={this.handleOnClick}
                onMouseDown={this.handleStartRecordingMouse}
                onMouseUp={this.handleStopRecordingMouse}
                onTouchStart={this.handleStartRecordingTouch}
                onTouchEnd={this.handleStopRecordingTouch}
                >
                <AudioRecorder  
                    ref={this.audioRecorderRef} 
                    canvasWidth={this.props.width} 
                    canvasHeight={this.props.height} 
                    minRadius={BUTTON_RADIUS}
                    handleAudioRecording={ (this.state.audioTestMode===true)? 
                        (this.localhandleAudioRecording):
                        (this.props.handleAudioRecording)}
                />
                { (this.state.audioTestMode===true)?(
                    <div className="label-onbackground position-absolute"style={{
                        top: this.props.height/2 - BUTTON_RADIUS - 80,
/*                        left: this.props.width/2 - BUTTON_RADIUS - 90,*/
                        width: this.props.width,
                        textAlign: 'center'}}>
                        <audio ref={this.testAudioRef} controls src=""/></div>
                ):null}
                <button 
                    className="btn btn-primary btn-primary-highlight position-absolute"
                    style={{
                        width: BUTTON_SIZE, 
                        height: BUTTON_SIZE,
                        left: this.props.width/2 - BUTTON_RADIUS,
                        top: this.props.height/2 - BUTTON_RADIUS,
                        padding: '5px',
                        borderRadius: BUTTON_RADIUS}}>
                    {micImages.map((image) =>
                        <img 
                            id={"micimage" + image.id}
                            key={image.id}
                            style={{visibility: image.visibility}}
                            src={image.src} 
                            alt="Microphone Icon" 
                            className={"position-absolute " + image.class} 
                            height={image.imageheight}/>)}
                </button>
                <div 
                    className="label-onbackground position-absolute" 
                    style={{
                        top: this.props.height/2 + BUTTON_RADIUS + 20,
                        textAlign: 'center'}}>
                    {micImages[imgID].statustext}
                </div>
            </div>
        );
    };
}
