import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import ModalPopup from './modalPopup.js';
import globalConfig from '../globalConfig.js'
var config = {};

export default class ErrorPopup extends React.Component {
    static defaultProps = {
        maxWidth: Math.min(window.innerWidth, 640),
    };

    static propTypes = {
        maxWidth: PropTypes.number,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);
        this.state =  {
            title: config.copy.errorpopup["badaudio"].title,
            msg: config.copy.errorpopup["badaudio"].title,
            popupOpen: false,
        }

        this.handleClose = this.handleClose.bind(this);
        this.showPopup = this.showPopup.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState){
        //only update if popup opens or closes
        if(nextState.popupOpen !== this.state.popupOpen){
            return true;
        } else {
            return false;
        }
    }

    handleClose(){
        this.setState({popupOpen: false})
    }

    showPopup(errortype = "badaudio"){
        //errortype can be "badaudio" or "nomatch", add more to config if needed
        this.setState({
            popupOpen: true,
            title: config.copy.errorpopup[errortype].title,
            msg: config.copy.errorpopup[errortype].msg,
        })
    }

    render(){
        return (
            <ModalPopup 
                title = {this.state.title}
                msg = {this.state.msg}
                abortButtonText = {config.copy.errorpopup.aborttext}
                actionButtonText = {config.copy.errorpopup.oktext}
                open = {this.state.popupOpen}
                maxwidth = {this.props.maxWidth}
                handleSecondaryAction = {this.handleClose}
                handlePrimaryAction = {this.handleClose}/>
        );
    }
}