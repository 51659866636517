import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import VideoPlayer from './videoPlayer.js';
import MoveBox from './moveBox.js';
import VideoCanvas3D from './videoCanvas3D.js';
import KeyboardInputController from '../utils/keyboardInputController.js';
import SettingsPopup from '../utils/settingsPopup.js';

import globalConfig from '../globalConfig.js'
var config = {};

export default class AppPlayer extends React.Component {
    static defaultProps = {
        width: window.innerWidth,
        height: window.innerHeight, //NOTE: is currently ignored and set fix to '100vh'
        showLogoutButton: true,
        handleLogout: undefined,
        handleReload: undefined,
        speedtestURL: "",
        speedOverride: -1,
        videoDimensions3D: false, //default is 2D
        videoUrlPrefix: "",
        videoUrlPostfix2D: "",
        videoUrlPostfix3D: "",
        videoCropWidth: 1.0,
        videoCropOffset: 0.0,
        allowUserControls: undefined,
    };

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        showLogoutButton: PropTypes.bool,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        speedtestURL: PropTypes.string,
        speedOverride : PropTypes.number,
        videoDimensions3D: PropTypes.bool,
        videoUrlPrefix: PropTypes.string,
        videoUrlPostfix2D: PropTypes.string,
        videoUrlPostfix3D: PropTypes.string,
        videoCropWidth : PropTypes.number,
        videoCropOffset : PropTypes.number,
        allowUserControls: PropTypes.bool,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            windowHeight: this.props.height, //NOTE: is currently ignored and set fix to '100vh'
            videoDimensions3D: this.props.videoDimensions3D,
            moveBoxVideoWidth: 1920,
            moveBoxVideoHeight: 1080,
            settingsOpen: false,
            selectedVideoQuality: -1,
            fontsize: 24,
            subtitleWidth: 100,
            currentQuestion: "",
            showCurrentQuestion: true,
            triplebuffer3d: false
        }
        this.handleToggleVideoDimensions = this.handleToggleVideoDimensions.bind(this);
        this.updateMoveBoxDimensions = this.updateMoveBoxDimensions.bind(this);
        this.handleShowSettings = this.handleShowSettings.bind(this);
        this.handleCloseSettings = this.handleCloseSettings.bind(this);
        this.handleSelectVideoQuality = this.handleSelectVideoQuality.bind(this);
        this.handleSetFontsize = this.handleSetFontsize.bind(this);
        this.handleSetSubtitleWidth = this.handleSetSubtitleWidth.bind(this);
        this.setVideoQuestion = this.setVideoQuestion.bind(this);
        this.setShowSubtitles = this.setShowSubtitles.bind(this);
        this.handleSetTripleBuffer3D = this.handleSetTripleBuffer3D.bind(this);

        this.videoPlayerRef_2d = React.createRef();
        this.videoPositionRef_2d = React.createRef();

        this.videoPlayerRef_3d = React.createRef();
        this.videoPositionRef_3d = React.createRef();

        this.debugVideoFileName = React.createRef();

    }

    componentDidMount() {
        window.addEventListener("resize", () => this.setState({windowHeight: window.innerHeight}));
        this.updateMoveBoxDimensions();

        //retrieve settings from previous session
        if (config.application === "demo"){
            //ignore cookie saves in demo mode, use always adaptive
            this.handleSelectVideoQuality(-1);
        } else {
            var videoQualityIndex = localStorage.getItem('videoQuality');
            if (videoQualityIndex !== null){
                this.handleSelectVideoQuality(JSON.parse(videoQualityIndex))
            }
        }

        var fontsize = localStorage.getItem('videoFontsize');
        if (fontsize !== null){
            //console.log(['fontsize: ', fontsize, Number(fontsize)])
            this.setState({fontsize: Number(fontsize)})
        }

        var subtitleWidth = localStorage.getItem('videoSubtitleWidth');
        if (subtitleWidth !== null){
            //console.log(['subtitleWidth: ', subtitleWidth, Number(subtitleWidth)])
            this.setState({subtitleWidth: Number(subtitleWidth)})
        }

        var triplebuffer = localStorage.getItem('triplebuffer3d');
        if (triplebuffer !== null){ 
            //console.log(['fontsize: ', fontsize, Number(fontsize)])
            this.setState({triplebuffer3d: (triplebuffer=="false"?false:true)});
        }
        
    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", () => this.setState({windowHeight: window.innerHeight}));
    }; 

    updateMoveBoxDimensions() {
        var moveBoxVideoWidth;
        var moveBoxVideoHeight;
        var videoRatio;

        if (this.videoPlayerRef_2d.current){
            moveBoxVideoWidth = this.videoPlayerRef_2d.current.videoCropWidth;
            moveBoxVideoHeight = this.videoPlayerRef_2d.current.videoHeight;
        } else {
            return;
        }
        
        //scale video to fit in viewport
        videoRatio = Math.min(this.props.width/moveBoxVideoWidth, this.props.height/moveBoxVideoHeight);
        
        videoRatio = Math.floor((videoRatio*10))/10; //adjust ratio to avoid rounding errors in most cases
        moveBoxVideoWidth = Math.floor(videoRatio*moveBoxVideoWidth);
        moveBoxVideoHeight = Math.floor(videoRatio*moveBoxVideoHeight)
        
        this.setState({
            moveBoxVideoWidth: moveBoxVideoWidth,
            moveBoxVideoHeight: moveBoxVideoHeight,
        })
    }

    handleShowSettings() {
        this.setState({settingsOpen: true})
    }

    handleCloseSettings() {
        this.setState({settingsOpen: false})
    }

    handleSelectVideoQuality(index){
        this.setState({selectedVideoQuality: index})
        if (this.videoPlayerRef.current){
            this.videoPlayerRef.current.setState({
                qualityOverride: index
            })
        }
        localStorage.setItem('videoQuality', JSON.stringify(index));
    }

    handleSetFontsize(val){
        this.setState({fontsize: val})
        localStorage.setItem('videoFontsize', JSON.stringify(val));
    }

    handleSetSubtitleWidth(val){
        this.setState({subtitleWidth: val})
        localStorage.setItem('videoSubtitleWidth', JSON.stringify(val));
    }

    handleToggleVideoDimensions() {
        console.log(this.state.videoDimensions3D)
        this.setState({videoDimensions3D: !this.state.videoDimensions3D})
    }

    handleSetTripleBuffer3D(val){
        this.setState({triplebuffer3d: val});
        localStorage.setItem('triplebuffer3d', JSON.stringify(val));
    }

    setVideoQuestion(newQuestion){
        if (this.state.showCurrentQuestion === true) { /* otherwise subtitles are disabled */
            this.setState({currentQuestion: newQuestion})
        } else { //delete any active question string
            if (this.state.currentQuestion !== '') {
                this.setState({currentQuestion: ''})
            }
        }
    }

    setShowSubtitles(showSubtitles){
        this.setState({showCurrentQuestion: showSubtitles})
    }

    render() {
        function getDynamicVideoRef(refval) {
            if (refval.current){
                return refval.current.video;
            } else {   
                return undefined;
            }
        }

        if (this.state.videoDimensions3D) {
            this.videoPlayerRef = this.videoPlayerRef_3d;
        } else {
            this.videoPlayerRef = this.videoPlayerRef_2d;
        }

        return (
            <main className="app-body" style={{overflow: 'hidden', height: '100vh'}}>
                {this.state.videoDimensions3D ?
                    <VideoCanvas3D
                        showLogoutButton={this.props.showLogoutButton}
                        handleLogout={this.props.handleLogout}
                        handleReload={this.props.handleReload}
                        id="videocanvas_3d"
                        key="canvas1"
                        fullscreen={true}
                        ref={this.videoPositionRef_3d}
                        player={this.videoPlayerRef_3d}
                        currentQuestion={this.state.currentQuestion}
                        fontsize={this.state.fontsize}
                        triplebuffer3d={this.state.triplebuffer3d}>
                        <VideoPlayer 
                            id="player_3d"  
                            key="player1" 
                            ref={this.videoPlayerRef_3d} 
                            speedtestURL={this.props.speedtestURL} 
                            speedOverride={this.props.speedOverride}
                            updateMoveBoxDimensions={this.updateMoveBoxDimensions}
                            qualityOverride={this.state.selectedVideoQuality}
                            overrideWidth={config.videoConfig.screenResolutionOverride.width}
                            overrideHeight={config.videoConfig.screenResolutionOverride.height}
                            videoUrlPrefix={this.props.videoUrlPrefix}
                            videoUrlPostfix2D={this.props.videoUrlPostfix2D}
                            videoUrlPostfix3D={this.props.videoUrlPostfix3D}
                            />
                    </VideoCanvas3D>
                    
                :
                    <MoveBox
                        showLogoutButton={this.props.showLogoutButton}
                        handleLogout={this.props.handleLogout}
                        handleReload={this.props.handleReload}
                        handleShowSettings={this.handleShowSettings}
                        id="videocanvas_2d" 
                        key="canvas2"
                        videoWidth={this.state.moveBoxVideoWidth} 
                        videoHeight={this.state.moveBoxVideoHeight} 
                        ref={this.videoPositionRef_2d}
                        player={this.videoPlayerRef_2d}
                        currentQuestion={this.state.currentQuestion}
                        fontsize={this.state.fontsize}
                        subtitleWidth={this.state.subtitleWidth}
                        allowUserControls={this.props.allowUserControls}>
                        <VideoPlayer 
                            id="player_2d" 
                            key="player2" 
                            ref={this.videoPlayerRef_2d} 
                            speedtestURL={this.props.speedtestURL} 
                            speedOverride={this.props.speedOverride}
                            updateMoveBoxDimensions={this.updateMoveBoxDimensions}
                            qualityOverride={this.state.selectedVideoQuality}
                            overrideWidth={config.videoConfig.screenResolutionOverride.width}
                            overrideHeight={config.videoConfig.screenResolutionOverride.height}
                            videoUrlPrefix={this.props.videoUrlPrefix}
                            videoUrlPostfix2D={this.props.videoUrlPostfix2D}
                            videoUrlPostfix3D={this.props.videoUrlPostfix3D}
                            videoWidthCrop={this.props.videoCropWidth}
                            videoCropOffset={this.props.videoCropOffset}
                            />
                    </MoveBox>
                }
                <KeyboardInputController
                    registeredComponents={{
                        position: [this.videoPositionRef_2d, this.videoPositionRef_3d], /*register for handleMove, handleScale callbacks */
                        vidctrl: [
                            () => getDynamicVideoRef(this.videoPlayerRef_2d),
                            () => getDynamicVideoRef(this.videoPlayerRef_3d)
                        ], /*register for htmlVideoPlayer callbacks, like play, pause */
                        effect3D: [], /* register for 3D effect callbacks handleChangeConvergence, ...*/
                        settings: this.handleShowSettings /* open settings menu */
                    }}
                />
                {this.videoPlayerRef.current ?
                <SettingsPopup
                    open={this.state.settingsOpen}
                    title = {config.copy.player.settings.title}
                    closeButtonText = {config.copy.player.settings.close} 
                    handleClose={this.handleCloseSettings}
                    maxwidth={640}
                    videoQualityOptions={this.videoPlayerRef.current.qualityList}
                    videoQualitySelected={this.state.selectedVideoQuality}
                    handleSelectVideoQuality={this.handleSelectVideoQuality}
                    handleSetFontsize={this.handleSetFontsize}
                    handleSetSubtitleWidth={this.handleSetSubtitleWidth}
                    fontsize={this.state.fontsize}
                    subtitleWidth={this.state.subtitleWidth}
                    handleSetTripleBuffer3D={this.handleSetTripleBuffer3D}
                    mode3d={this.state.videoDimensions3D}
                    triplebuffer3d={this.state.triplebuffer3d}
                />
                : null }
                <div style={{display: 'flex', overflow: 'hidden', position: 'absolute', bottom: '0', left:'0', zIndex: '10'}}>
                    {(this.videoPositionRef_2d.current && this.videoPositionRef_2d.current.debugState !== 0) ||
                            (this.videoPositionRef_3d.current && this.videoPositionRef_3d.current.debugInfo !== 0) ?
                        <React.Fragment>
                        <input 
                            type="text" 
                            className="grid-itemright input-onbackground" 
                            name="devicenameinput" 
                            ref={this.debugVideoFileName} 
                            onKeyUp={(e)=>{if (e.keyCode === 13) {this.videoPlayerRef.current.playAnswer(e.target.value,"KRAUSE_IDLE_LOOP_1")}}}/>
                        <button 
                            className="btn btn-secondary" 
                            onClick={() => this.videoPlayerRef.current.playAnswer(this.debugVideoFileName.current.value,"KRAUSE_IDLE_LOOP_1")}
                            >Play</button>
                        </React.Fragment>
                    : null }
                </div>
            </main>
        );
    };
}