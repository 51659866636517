import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import CloseIcon from '../icons/close_icon_bold.svg';

import './popupSelector.css';

import globalConfig from '../globalConfig.js'
var config = {};

export default class PopupSelector extends React.Component {
    static defaultProps = {
        width: window.innerWidth,
        height: window.innerHeight,
        imageList: []
    };

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        imageList: PropTypes.array,
    };

    constructor(props) {
        config = globalConfig.config;
        
        super(props);
        this.state = {
            popupOpen: false,
            selectedTargetValue: undefined,
        }
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectTarget = this.handleSelectTarget.bind(this);

        this.currentImageList = [];
    };


    handleOpen(respondentList){
        //construct currentImageList from all items in this.props.imageList with matching id in respondentList
        this.currentImageList = [];
        if (respondentList.length > 0) {
            this.props.imageList.forEach(item => {
                if (respondentList.includes(item.id)) {
                    this.currentImageList.push(item);
                }
            });
        } else {
            //just use full list as default
            this.currentImageList = this.props.imageList;
        }
        //then open popup
        this.setState({
            popupOpen: true,
            selectedTargetValue: undefined,
        });
    }

    handleClose(){
        this.setState({popupOpen: false});
        //console.log("CLOSED POPUP");
        this.props.handleSelectRespondent("selection_finished");
    }

    handleSelect(){
        this.props.handleSelectRespondent(this.currentImageList[this.state.selectedTargetValue].id);
        this.setState({popupOpen: false});
        //this.handleClose();
    }

    handleSelectTarget (val) {
        this.setState({selectedTargetValue: val});
    }

    render(){
        const MODAL_IMG_MARGIN = 2;
        const MODAL_PADDING = 8;
        var nof_images = this.props.imageList.length;
        var nof_images_per_row;
        var itemSize;

        if (this.props.height - 100 -2*MODAL_PADDING > this.props.width - 2*MODAL_PADDING) {
            //scale for square lockup
            nof_images_per_row = Math.ceil(Math.sqrt(nof_images));
            itemSize = Math.floor(100/nof_images_per_row) - 2*MODAL_IMG_MARGIN;
        } else {
            //scale for maximum 2 rows
            nof_images_per_row = Math.ceil(nof_images/2);
            itemSize = Math.floor(100/nof_images_per_row) - 2*MODAL_IMG_MARGIN;
        }


        return (
            <div
                className="modal-background"
                style={{display: this.state.popupOpen ? 'flex' : 'none'}}>
                <div className="modal-foreground flex-container-column popupselector-background" 
                    style={{maxHeight: this.props.height - 2*MODAL_PADDING, maxWidth: this.props.width - 2*MODAL_PADDING}}>
                    <div className="flex-container-row" style={{justifyContent: 'space-between'}}>
                        <label className="label-onbackground">{config.copy.recorder.selectorpopup.title}</label>
                        <img 
                            onClick={this.handleClose}
                            src={CloseIcon} 
                            alt="Close" 
                            className="modal-close-icon filter-mediumgrey" 
                            draggable="false"/>
                    </div>
                    <div className="flex-container-row modal-itemcontainer"                   
                        style={{flex: '0 1 ' + this.props.height-200 + 'px'}}>
                        {this.currentImageList.map((image,index) => 
                            <button
                                className={this.state.selectedTargetValue === index ? "button-radio-selector active" : "button-radio-selector"}
                                key={index}
                                onClick={() => this.handleSelectTarget(index)}
                                style={{  
                                    flex: '0 1 ' +  itemSize + '%',
                                    paddingBottom: itemSize + '%',
                                    margin: MODAL_IMG_MARGIN + '%',
                                    backgroundImage: `url('${image.imgUrl}')`}}>
                            </button>
                        )}
                    </div>
                    <div className="flex-container-row modal-buttoncontainer">
                        <button className="btn btn-primary" onClick={this.handleClose}>
                            {config.copy.recorder.selectorpopup.skip}
                        </button>
                        {this.state.selectedTargetValue === undefined ?
                            <button 
                                className="btn btn-primary" 
                                onClick={this.handleSelect}
                                disabled>
                                {config.copy.recorder.selectorpopup.play}
                            </button>
                            :
                            <button 
                                className="btn btn-primary" 
                                onClick={this.handleSelect}>
                                {config.copy.recorder.selectorpopup.play}
                            </button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
