function createRandomKey(totalLength, blockLength){
    if (totalLength < 1 || blockLength < 1) {
        return;
    }

    var randomString
    var randomStringLength;
    var randomKey = '';
    var i = 0;

    //create random string of sufficient length
    randomString = Math.random().toString(36).slice(2);
    randomStringLength = randomString.length;
    while (randomStringLength < totalLength){
        randomString = randomString + Math.random().toString(36).slice(2);
        randomStringLength = randomString.length;
    }

    randomString = randomString.toUpperCase();

    //create blocks
    if (blockLength >= totalLength){
        randomKey = randomString.substring(0, totalLength);
    } else {
        while(i + blockLength < totalLength){
            randomKey = randomKey + randomString.substring(i,i+blockLength);
            i = i + blockLength;
            randomKey = randomKey + '-';
        }
        randomKey = randomKey + randomString.substring(i,totalLength);

    }

    return randomKey
}

export default createRandomKey