import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import CloseIcon from '../icons/close_icon_bold.svg';

import '../styles/slider.css'
import globalConfig from '../globalConfig.js'
var config = {};

export default class SettingsPopup extends React.Component {
    static defaultProps = {
        title: 'Einstellungen',
        closeButtonText: 'Fertig und Beenden',
        open: false,
        maxwidth: window.innerWidth,
        handleClose: undefined,
        videoQualityOptions: [],
        videoQualitySelected: -1,
        handleSelectVideoQuality: undefined,
        handleSetFontsize: undefined,
        handleSetSubtitleWidth: undefined,
        handleSetTripleBuffer3D: undefined,
        fontsize: 18,
        subtitleWidth: 100,
        triplebuffer3d: false,
        mode3d: false
    };

    static propTypes = {
        title: PropTypes.string,
        closeButtonText: PropTypes.string,
        open: PropTypes.bool,
        maxwidth: PropTypes.number,
        handleClose: PropTypes.func,
        videoQualityOptions: PropTypes.array,
        videoQualitySelected: PropTypes.number,
        handleSelectVideoQuality: PropTypes.func,
        handleSetFontsize: PropTypes.func,
        handleSetSubtitleWidth: PropTypes.func,
        handleSetTripleBuffer3D: PropTypes.func,
        fontsize: PropTypes.number,
        subtitleWidth: PropTypes.number,
        triplebuffer3d: PropTypes.bool,
        mode3d: PropTypes.bool
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);
        this.state = {
            fontsize: this.props.fontsize,
            subtitleWidth: this.props.subtitleWidth,
            triplebuffer3d : this.props.triplebuffer3d
        }
        this.handleSelectVideoQuality = this.handleSelectVideoQuality.bind(this);
        this.handleFontsizeChange = this.handleFontsizeChange.bind(this);
        this.handleSubtitleWidthChange = this.handleSubtitleWidthChange.bind(this);
        this.handleTripleBuffer3D = this.handleTripleBuffer3D.bind(this);
    }

    handleSelectVideoQuality(e) {
        this.props.handleSelectVideoQuality(e.target.selectedIndex - 1)
    }

    handleFontsizeChange(e){
        var ptsize = Number(e.target.value);
        this.setState({fontsize: ptsize})
        this.props.handleSetFontsize(ptsize);
    }

    handleSubtitleWidthChange(e){
        var percentWidth = Number(e.target.value);
        this.setState({subtitleWidth: percentWidth})
        this.props.handleSetSubtitleWidth(percentWidth);
    }

    handleTripleBuffer3D(e){
        var enabled = !this.state.triplebuffer3d;
        this.setState({triplebuffer3d: enabled});
        this.props.handleSetTripleBuffer3D(enabled);
    }

    render(){
        return (
            <div
                className="modal-background"
                style={{display: this.props.open ? 'flex' : 'none'}}>
                <div className="modal-foreground flex-container-column" style={{maxWidth: this.props.maxwidth}}>
                    <div className="flex-container-row" style={{justifyContent: 'space-between'}}>
                        <label className="label-onbackground">{this.props.title}</label>
                        <img 
                            onClick={this.props.handleClose}
                            src={CloseIcon} 
                            alt="Close" 
                            className="filter-mediumgrey modal-close-icon"/>
                    </div>
                    <div className="flex-container-column modal-itemcontainer" style={{flexWrap: 'nowrap'}}>
                        <div className="flex-container-row" style={{justifyContent: 'space-between'}}>
                            <p className="label-onbackground" style={{ flex: '0 0 8em'}}>
                                {config.copy.player.settings.videoquality + ':'} 
                            </p>
                            {this.props.videoQualityOptions.length > 0 ? 
                            <select className="input-onbackground" style={{flex: '0 1 100%'}} value={this.props.videoQualitySelected} onChange={this.handleSelectVideoQuality}>
                                <option key={-1} value={-1}>{config.copy.player.settings.auto}</option>
                                {this.props.videoQualityOptions.map((item, index) => 
                                    <option key={index} value={index}>{item}</option>
                                )}
                            </select>
                            :
                            <p className="label-onbackground" style={{flex: '0 1 100%'}}>
                                {config.copy.player.settings.loadvideoforoptions} 
                            </p>
                            }
                        </div>
                        <div className="flex-container-row" style={{justifyContent: 'space-between', marginBottom: '16px'}}>
                            <label className="label-onbackground" style={{whiteSpace: 'nowrap', flex: '0 0 8em'}} htmlFor="fontSizeInput">{config.copy.player.settings.fontsize + ':'}</label>
                            <div className="flex-container-row">
                                <input type="range" className="slider primary" style={{marginBottom: '2px', marginTop: '-1.3em', marginLeft: '0px', marginRight: '8px'}} 
                                    name="fontSizeInput" min="8" max="56" defaultValue={this.state.fontsize} 
                                    onInput={this.handleFontsizeChange}/>
                                <label className="label-onbackground">{this.state.fontsize + 'pt'}</label>
                            </div>
                        </div>
                        <div className="flex-container-row" style={{justifyContent: 'space-between', marginBottom: '16px'}}>
                            <label className="label-onbackground" style={{whiteSpace: 'nowrap',  flex: '0 0 8em'}} htmlFor="subtitleWidthInput">{config.copy.player.settings.subtitlewidth + ':'}</label>
                            <div className="flex-container-row">
                                <input type="range" className="slider primary" style={{marginBottom: '2px', marginTop: '-1.3em', marginLeft: '0px', marginRight: '8px'}} 
                                    name="subtitleWidthInput" min="80" max="200" defaultValue={this.state.subtitleWidth} 
                                    onInput={this.handleSubtitleWidthChange}/>
                                <label className="label-onbackground">{this.state.subtitleWidth + '%'}</label>
                            </div>
                        </div>

                        {this.props.mode3d===true?
                        <div className="flex-container-row" style={{justifyContent: 'space-between', marginBottom: '16px'}}>
                        <label htmlFor="syncbuffer3d" className="label-onbackground">{config.copy.player.settings.triplebuffermode}</label>
                            <button name="syncbuffer3d" 
                                className={this.state.triplebuffer3d === true ? "btn-checkbox active" : "btn-checkbox"}
                                style={{marginLeft: '15px', marginTop: '10px'}} 
                                onClick={this.handleTripleBuffer3D}
                                disabled = {this.state.triplebuffer3d === "disabled" ? "disabled" : false}>
                            </button>
                        </div>: <div/> }
                    </div>
                    <div className="flex-container-row modal-buttoncontainer">
                        <button className="btn btn-primary" onClick={this.props.handleClose}>
                            {this.props.closeButtonText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}