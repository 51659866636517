import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

export default class VideoCanvas3D extends React.Component {
    static defaultProps = {
        fullscreen: false,
        player: undefined,
        videoWidth: 3840,
        videoHeight: 1080,
        handleLogout: undefined,
        handleReload: undefined,
        triplebuffer3d: false
    };

    static propTypes = {
        fullscreen: PropTypes.bool,
        player: PropTypes.object,
        videoWidth: PropTypes.number,
        videoHeight: PropTypes.number,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        triplebuffer3d: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            canvasHeight: this.props.fullscreen ? window.screen.width*window.devicePixelRatio : window.innerHeight,
            canvasWidth: this.props.fullscreen ? window.screen.width*window.devicePixelRatio : window.innerWidth,
        }
        this.canvas = React.createRef();
        this.handleOpenFullscreen = this.handleOpenFullscreen.bind(this);
        this.handleCloseFullscreen = this.handleCloseFullscreen.bind(this);
        this.handleCanvasResize = this.handleCanvasResize.bind(this);
        this.handleCopyToCanvas = this.handleCopyToCanvas.bind(this);
        this.toggleDebug = this.toggleDebug.bind(this);
        this.canvasContext = undefined;
        this.timer = undefined;
        this.videoWidth = 1920*2;
        this.videoHeight = 1080;
        this.debugInfo = 0;

        this.handleMove = this.handleMove.bind(this);
        this.handleScale = this.handleScale.bind(this);
        this.videoPositionX=-0.25; //-this.props.videoWidth/4;
        this.videoPositionY=-0.5; //-this.props.videoHeight/2;
        this.videoScaleX=1.0;
        this.videoScaleY=1.0;
        this.videoConvergence=0;
        this.videoSettingsUpdated=false;
        this.timeCopyToCanvas=0.0;
        this.bandwidthUpdateCounter=0;

        this.bandwidthMax=0;
        this.bandwidthList=[];
        for (var i=0; i<220; i++)
        {
            this.bandwidthList.push(0);
        }
    }

    handleOpenFullscreen() {
        var elem = document.documentElement;

        if (window.innerHeight === window.screen.height*window.devicePixelRatio){
            return;
        }

        if (elem.requestFullscreen) {
          elem.requestFullscreen().catch(err => {});
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          try{elem.webkitRequestFullScreen()} catch(err) {console.log(err); }
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen().catch(err => {});
        }
    }

    /* Close fullscreen */
    handleCloseFullscreen() {
        if (window.innerHeight !== window.screen.height*window.devicePixelRatio){
            return;
        }

        if (document.exitFullscreen) {
        document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
        }
    }
    
    handleCanvasResize(){
        console.log([window.screen.width*window.devicePixelRatio, window.screen.height*window.devicePixelRatio])
        if (window.innerHeight === window.screen.height*window.devicePixelRatio) { //fullscreen
            this.setState({
                canvasWidth: window.screen.width*window.devicePixelRatio,
                canvasHeight: window.screen.height*window.devicePixelRatio,
            })
        } else {
            this.setState({
                canvasWidth: window.innerWidth,
                canvasHeight: window.innerHeight,
            })
        }

    }

    handleMove(deltaX, deltaY){
        this.videoPositionX += deltaX/1000;
        this.videoPositionY += deltaY/1000;
        this.videoSettingsUpdated=true;
//        this.canvasContext.drawImage(this.props.player.current.video.current,0,0,1,1,0,0,3840,1080*2);
        console.log("Move: X"+this.videoPositionX+"  Y"+this.videoPositionY);
    }

    handleScale(delta) {
        this.videoScaleX += delta-1;
        this.videoScaleY += delta-1;
        this.videoSettingsUpdated=true;
//        this.canvasContext.drawImage(this.props.player.current.video.current,0,0,1,1,0,0,3840,1080*2);
        console.log("Scale: X"+this.videoScaleX+"  Y"+this.videoScaleY);
    };

    handleConvergence(delta) {
        this.videoConvergence+=delta;
        this.videoSettingsUpdated=true;
//        this.canvasContext.drawImage(this.props.player.current.video.current,0,0,1,1,0,0,3840,1080*2);
        console.log("Convergence: "+this.videoConvergence);
    };

    toggleDebug() {
        console.log("Toggle Debugging Info");
        if (this.debugInfo>=2) this.debugInfo=0; else this.debugInfo++;
    };


    handleCopyToCanvas(){

        this.timeStartCopyToCanvas = (new Date()).getTime();

        var viewportX1, viewportY1, viewportX2, viewportY2, viewportW, viewportH;
        
        var cropX1, cropY1, cropW1, cropH1;
        var outX1, outY1, outW1, outH1;
        var cropX2, cropY2, cropW2, cropH2;
        var outX2, outY2, outW2, outH2;

        this.videoWidth=this.props.player.current.video.current.videoWidth;//this.props.videoWidth;
        this.videoHeight=this.props.player.current.video.current.videoHeight;//this.props.videoHeight;

        var vidW = this.videoWidth/2, vidH = this.videoHeight;
        var dispW = window.innerWidth/2, dispH = window.innerHeight/1;

        var scalingX, scalingY;
        scalingX=((dispW*this.videoScaleX)/vidW);
        scalingY=((dispH*this.videoScaleY)/vidH);

        viewportX1=(this.videoWidth*this.videoPositionX)+((vidW/2)/this.videoScaleX);
        viewportY1=(this.videoHeight*this.videoPositionY)+((vidH/2)/this.videoScaleY);

        viewportX2=(this.videoWidth*this.videoPositionX)+((vidW/2)/this.videoScaleX)+this.videoConvergence;
        viewportY2=(this.videoHeight*this.videoPositionY)+((vidH/2)/this.videoScaleY);

        viewportW=vidW/this.videoScaleX;
        viewportH=vidH/this.videoScaleY;



        cropX1=(viewportX1>0)?0:-viewportX1;
        cropY1=(viewportY1>0)?0:-viewportY1;
        cropW1=(-viewportX1+viewportW>vidW)?(vidW-cropX1):(viewportW-viewportX1-cropX1);
        cropH1=(-viewportY1+viewportH>vidH)?(vidH-cropY1):(viewportH-viewportY1-cropY1);

        outX1=(cropX1+viewportX1)*scalingX;
        outY1=(cropY1+viewportY1)*scalingY;
        outW1=(cropW1*scalingX);
        outH1=(cropH1*scalingY);

        cropX2=(viewportX2>0)?0:-viewportX2;
        cropY2=(viewportY2>0)?0:-viewportY2;
        cropW2=(-viewportX2+viewportW>vidW)?(vidW-cropX2):(viewportW-viewportX1-cropX2);
        cropH2=(-viewportY2+viewportH>vidH)?(vidH-cropY2):(viewportH-viewportY1-cropY2);

        outX2=(cropX2+viewportX2)*scalingX;
        outY2=(cropY2+viewportY2)*scalingY;
        outW2=(cropW2*scalingX);
        outH2=(cropH2*scalingY);

        outX2+=dispW;
        cropX2+=vidW;

        /*
//        outX1=(viewportX1>0)?dispW-((dispW*cropW1*this.videoScaleX)/vidW):0;
//        outY1=(viewportY1>0)?dispH-((dispH*cropH1*this.videoScaleY)/vidH):0;        
//        outW1=(viewportX1>0)?(dispW-outX1):(dispW*cropW1*this.videoScaleX)/vidW;
//        outH1=(viewportY1>0)?(dispH-outY1):(dispH*cropH1*this.videoScaleY)/vidH;

        cropX2=(viewportX2>0)?0:-viewportX2;
        cropY2=(viewportY2>0)?0:-viewportY2;
        cropW2=(-viewportX2+viewportW>vidW)?(vidW-cropX2):(viewportW-viewportX2-cropX2);
        cropH2=(-viewportY2+viewportH>vidH)?(vidH-cropY2):(viewportH-viewportY2-cropY2);

        outX2=(viewportX2>0)?dispW-((dispW*cropW2*this.videoScaleX)/vidW):0;
        outY2=(viewportY2>0)?dispH-((dispH*cropH2*this.videoScaleY)/vidH):0;        
        outW2=(viewportX2>0)?(dispW-outX2):(dispW*cropW2*this.videoScaleX)/vidW;
        outH2=(viewportY2>0)?(dispH-outY2):(dispH*cropH2*this.videoScaleY)/vidH;
        */

        if (this.props.triplebuffer3d===true)
        {
            this.copyContext.drawImage(this.props.player.current.video.current,0,0);

            if (this.videoSettingsUpdated===true)
            {
    //            this.canvasContext.drawImage(this.props.player.current.video.current,0,0,1,1,0,0,dispW*2,dispH);
                this.canvasContext.drawImage(this.copyCanvas,0,0,1,1,0,0,dispW*2,dispH);
            }

    //        this.canvasContext.drawImage(this.props.player.current.video.current,cropX1,cropY1,cropW1,cropH1,outX1,outY1,outW1,outH1);
    //        this.canvasContext.drawImage(this.props.player.current.video.current,cropX2,cropY2,cropW2,cropH2,outX2,outY2,outW2,outH2);
            this.canvasContext.drawImage(this.copyCanvas,cropX1,cropY1,cropW1,cropH1,outX1,outY1,outW1,outH1);
            this.canvasContext.drawImage(this.copyCanvas,cropX2,cropY2,cropW2,cropH2,outX2,outY2,outW2,outH2);
//            console.log("Flo mode");
        }
        else
        {
            if (this.videoSettingsUpdated===true)
            {
    //            this.canvasContext.drawImage(this.props.player.current.video.current,0,0,1,1,0,0,dispW*2,dispH);
                this.canvasContext.drawImage(this.props.player.current.video.current,0,0,1,1,0,0,dispW*2,dispH);
            }
            this.canvasContext.drawImage(this.props.player.current.video.current,cropX1,cropY1,cropW1,cropH1,outX1,outY1,outW1,outH1);
            this.canvasContext.drawImage(this.props.player.current.video.current,cropX2,cropY2,cropW2,cropH2,outX2,outY2,outW2,outH2);
//            console.log("Normal mode");
        }

        if (this.debugInfo>0) // Buffer fill state debugging
        {
            this.canvasContext.font = "15px Roboto";

            var fragcnt = this.props.player.current.playerInfo.bufferedFragmentCount;
            this.canvasContext.strokeStyle = '#FFFFFF';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(2, 2);
            this.canvasContext.lineTo(228, 2);
            this.canvasContext.lineTo(228, 13);
            this.canvasContext.lineTo(2, 13);
            this.canvasContext.lineTo(2, 2);
            this.canvasContext.stroke();
    
            if (fragcnt<5)
                this.canvasContext.fillStyle='#FF0000';
            else if (fragcnt<10)
                this.canvasContext.fillStyle='#FFFF00';
            else
                this.canvasContext.fillStyle='#00FF00';
    
            if (fragcnt>21) fragcnt=21;
            this.canvasContext.fillRect(5,5,(fragcnt*10)+7,5);

            var i,j;
            var frag;
            var yoffset=0;
            var x,y;
            var quality=-1;
            var cpu,drawtime;

            yoffset=30;

            this.canvasContext.fillStyle='#E0E0E0';
            this.canvasContext.fillText("CPU:", 5, yoffset+12);

            this.canvasContext.strokeStyle = '#FFFFFF';
            this.canvasContext.beginPath();
            this.canvasContext.rect(50,yoffset,188,15);
            this.canvasContext.stroke();

            cpu=(this.props.player.current.playerInfo.transcodeTime/500.0);
            if (cpu<0.0) cpu=0.0;
            if (cpu>1.0) cpu=1.0;

            if (cpu<0.5) this.canvasContext.fillStyle='#00FF00';
            else if (cpu<0.8) this.canvasContext.fillStyle='#FFFF00';
            else this.canvasContext.fillStyle='#FF0000';
            cpu*=180.0;

            this.canvasContext.fillRect(54,yoffset+4,cpu,7);

            drawtime=(this.timeCopyToCanvas/20.0);
            if (drawtime<0.0) drawtime=0.0;
            if (drawtime>1.0) drawtime=1.0;

            if (drawtime<0.5) this.canvasContext.fillStyle='#008000';
            else if (drawtime<0.8) this.canvasContext.fillStyle='#808000';
            else this.canvasContext.fillStyle='#800000';

            drawtime*=180.0;

            if (drawtime+cpu>180) drawtime=180-cpu;
            //if (drawtime>cpu) 
            this.canvasContext.fillRect(54+cpu,yoffset+4,drawtime,7);

            yoffset+=30;

            if (this.props.player.current.playerInfo.videoInfo!==undefined)
            {
                this.canvasContext.fillStyle='#E0E0E0';
                this.canvasContext.fillText("Video: "+this.props.player.current.playerInfo.videoInfo.width+"x"+this.props.player.current.playerInfo.videoInfo.height+"@"+this.props.player.current.playerInfo.videoInfo.fps+" FPS",5, yoffset+10);
        
                yoffset+=25;
            }

            this.canvasContext.fillStyle='#E0E0E0';
            var performanceInfo = (this.props.player.current.playerInfo.performanceInfoAvailable===true)?" (P)":"";
            this.canvasContext.fillText("Bandwidth: (Max="+Math.round(this.bandwidthMax/(1024*1024))+" Mb/s)"+performanceInfo,5, yoffset+10);
            yoffset+=5;
            this.canvasContext.strokeStyle = '#FFFFFF';
            this.canvasContext.beginPath();
            this.canvasContext.rect(5,13+yoffset,232,40);
            this.canvasContext.stroke();
    
            this.bandwidthUpdateCounter++;
            if (this.props.player.current && (this.bandwidthUpdateCounter>5))
            {
                this.bandwidthList.push(this.props.player.current.playerInfo.estimatedDownstream);
                this.bandwidthList.shift();
                this.bandwidthUpdateCounter=0;
            }
    //        if (this.props.player.current.playerInfo.estimatedDownstream>this.bandwidthMax) this.bandwidthMax=this.props.player.current.playerInfo.estimatedDownstream;
    
            this.bandwidthMax=0;
            for (j=0; j<this.bandwidthList.length; j++)
            {
                if (this.bandwidthList[j]>this.bandwidthMax) this.bandwidthMax=this.bandwidthList[j];
            }
    
            var scale = 30/this.bandwidthMax;
    //        var scale = 15/this.props.player.current.playerInfo.currentVideoBandwidth;
            var val;
            for (j=0; j<this.bandwidthList.length; j++)
            {
                val=Math.round(this.bandwidthList[j]*scale);
                if (val>30) val=30;
                this.canvasContext.strokeStyle = '#00FF00';
                this.canvasContext.beginPath();
                this.canvasContext.moveTo(10+j, 13+yoffset+35);
                this.canvasContext.lineTo(10+j, 13+yoffset+35-val);
                this.canvasContext.stroke();
            }
    
            val=this.props.player.current.playerInfo.currentVideoBandwidth*scale;
            if (val>30) val=30;
    
            this.canvasContext.strokeStyle = '#FF0000';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(10, 13+yoffset+35-val);
            this.canvasContext.lineTo(10+this.bandwidthList.length, 13+yoffset+35-val);
            this.canvasContext.stroke();
    
            yoffset+=70;
    

            for (j=0; j<3; j++)
            {
                quality=-1;
    //                    if (j===0) frag=this.props.player.current.playerInfo.currentFragment;
                if (j===0) frag=this.props.player.current.answerVideo;
                else if (j===1) frag=this.props.player.current.idleLoop1;
                else if (j===2) frag=this.props.player.current.idleLoop2;
    
                this.canvasContext.fillStyle='#E0E0E0';
                this.canvasContext.fillText(frag.name+": "+frag.fileID, 5, yoffset+10);
                yoffset+=5;
    

                if (frag.fragmentList[0]===undefined)
                {
    //                        this.canvasContext.fillStyle='#303030';
    //                        this.canvasContext.fillRect(5,yoffset+20,200,20);
    
                    this.canvasContext.fillStyle='#808080';
                    this.canvasContext.fillText("Buffer Empty", 15, yoffset+20+15);
                    this.canvasContext.strokeStyle = '#FFFFFF';
                    this.canvasContext.beginPath();
                    this.canvasContext.rect(5,13+yoffset,232,35);
                    this.canvasContext.stroke();
                    yoffset+=25+40;
                    continue;
                } 
    
                for (i=0; i<frag.fragmentList[0].entrycnt; i++)
                {
                    if (frag.fragmentList[0].fragments[i]===undefined) break;
    
                    if (frag.currentPlayFragment===i)
                        this.canvasContext.fillStyle='#FF0000';
                    else if (frag.fragmentList[0].fragments[i].loadstate===0)
                        this.canvasContext.fillStyle='#505050';
                    else if (frag.fragmentList[0].fragments[i].loadstate===1)
                        this.canvasContext.fillStyle='#FFFF00';
                    else if (frag.fragmentList[0].fragments[i].loadstate===2)
                        this.canvasContext.fillStyle='#00FF00';
                    else
                        this.canvasContext.fillStyle='#0000FF';
    
                    // Check for switched quality setting
                    if ((frag.fragmentList[0].fragments[i].loadstate===2)&&(quality>=0)&&(quality!==frag.fragmentList[0].fragments[i].quality)) 
                    {
                        this.canvasContext.fillStyle='#008000';
                    }
                    quality=frag.fragmentList[0].fragments[i].quality;
    
                    x = (i % 32)*7;
                    y = (i >> 5)*7;
    
                    this.canvasContext.fillRect(x+10,y+20+yoffset,4,4);
                }
                this.canvasContext.strokeStyle = '#FFFFFF';
                this.canvasContext.beginPath();
                this.canvasContext.rect(5,13+yoffset,232,y+17);
                this.canvasContext.stroke();
                yoffset+=y+40;
            }
        }

        if (this.debugInfo>1) // debug
        {
//            console.log("Crop: X:"+cropX1+"  Y:"+cropY1+ "  W:"+cropW1+"  H:"+cropH1+"   Viewport X:"+viewportX1+"   Y:"+viewportY1+"   W:"+viewportW+"   H:"+viewportH+ " VW:"+this.videoWidth+" VH:"+this.videoHeight);
            this.canvasContext.drawImage(this.props.player.current.video.current,0,0,vidW*2,vidH,0,1080,vidW,vidH/2);

            // Cropped viewport
            this.canvasContext.strokeStyle = '#FFFFFF';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(cropX1/2          , ((cropY1)/2)+1080);
            this.canvasContext.lineTo(cropX1/2          , ((cropY1+cropH1)/2)+1080);
            this.canvasContext.lineTo((cropX1+cropW1)/2 , ((cropY1+cropH1)/2)+1080);
            this.canvasContext.lineTo((cropX1+cropW1)/2 , ((cropY1)/2)+1080);
            this.canvasContext.lineTo((cropX1)/2        , ((cropY1)/2)+1080);
            this.canvasContext.stroke();

            // Full virtual viewport1 (green)
            this.canvasContext.strokeStyle = '#00FF00';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo((-viewportX1/2)               , ((-viewportY1)/2)+1080);
            this.canvasContext.lineTo((-viewportX1/2)               , ((-viewportY1+viewportH)/2)+1080);
            this.canvasContext.lineTo(((-viewportX1+viewportW)/2)   , ((-viewportY1+viewportH)/2)+1080);
            this.canvasContext.lineTo(((-viewportX1+viewportW)/2)   , ((-viewportY1)/2)+1080);
            this.canvasContext.lineTo(((-viewportX1)/2)             , ((-viewportY1)/2)+1080);
            this.canvasContext.stroke();

            // Full virtual viewport2 (red)
            this.canvasContext.strokeStyle = '#FF0000';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo((-viewportX2/2)+(vidW/2)               , ((-viewportY2)/2)+1080);
            this.canvasContext.lineTo((-viewportX2/2)+(vidW/2)               , ((-viewportY2+viewportH)/2)+1080);
            this.canvasContext.lineTo(((-viewportX2+viewportW)/2)+(vidW/2)   , ((-viewportY2+viewportH)/2)+1080);
            this.canvasContext.lineTo(((-viewportX2+viewportW)/2)+(vidW/2)   , ((-viewportY2)/2)+1080);
            this.canvasContext.lineTo(((-viewportX2)/2)+(vidW/2)             , ((-viewportY2)/2)+1080);
            this.canvasContext.stroke();
            
            // Display viewport
            this.canvasContext.strokeStyle = '#FF0000';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(outX1, outY1);
            this.canvasContext.lineTo(outX1, outY1+outH1);
            this.canvasContext.lineTo(outX1+outW1, outY1+outH1);
            this.canvasContext.lineTo(outX1+outW1, outY1);
            this.canvasContext.lineTo(outX1, outY1);
            this.canvasContext.stroke();

            // Display2 viewport
            this.canvasContext.strokeStyle = '#00FFFF';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(outX2, outY2);
            this.canvasContext.lineTo(outX2, outY2+outH2);
            this.canvasContext.lineTo(outX2+outW2, outY2+outH2);
            this.canvasContext.lineTo(outX2+outW2, outY2);
            this.canvasContext.lineTo(outX2, outY2);
            this.canvasContext.stroke();
        }

        var timeStopCopyToCanvas = (new Date()).getTime();
        var lasttime = timeStopCopyToCanvas - this.timeStartCopyToCanvas;

        this.timeCopyToCanvas = ((this.timeCopyToCanvas*0.9)+(lasttime*0.1));
    }

    componentDidMount() {
        this.copyCanvas = document.createElement('canvas');
        this.copyCanvas.width = 3840*2;
        this.copyCanvas.height = 2160;
        this.copyContext = this.copyCanvas.getContext("2d");

        this.canvasContext = this.canvas.current.getContext("2d");
        this.timer = window.setInterval(this.handleCopyToCanvas,20);

        window.addEventListener("resize", this.handleCanvasResize);

        if (this.props.fullscreen && window.innerHeight !== window.screen.height*window.devicePixelRatio) {
            // browser is not in fullscreen, but fullscreen is requested by props
            this.handleOpenFullscreen();
        } else if (!this.props.fullscreen && window.innerHeight === window.screen.height*window.devicePixelRatio){
            //browser is in fullscreen, but fullscreen is not requested in props
            this.handleCloseFullscreen();
        }
    };
      
    componentWillUnmount() {
        window.clearInterval(this.timer);
        window.removeEventListener("resize", this.handleCanvasResize);
    };

    render() {
        return (
            <canvas 
                id="videoCanvas" 
                ref={this.canvas} 
                width={this.state.canvasWidth}
                height={this.state.canvasHeight}
                style={{
                    position: 'relative',
                    top: '0px',
                    left: '0px',
                    margin: '0',
                    }}>
                {this.props.children}
            </canvas>
        );
    };
}