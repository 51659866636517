import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import Recorder from  './recorder.js'

import globalConfig from '../globalConfig.js'
var config = {};

//import utils from '../utils/debugUtils.js' //DEBUG ONLY: for downloading file:

window.AudioContext = window.AudioContext || window.webkitAudioContext;

//graphic settings for visual feedback on audio input
const DRAW_AUDIO_AMPLITUDE_MAX = 1000;
const NUM_CIRCLES = 8; //should be a divisor of 32 and <= 16
const DRAW_TIME_DELAY = 100; //ms between redraws
const FREQ_ANALYSER_SMOOTHONG_CONSTANT = 0.6; //between 0 and 1, higher equals more smoothing

export default class AudioRecorder extends React.Component {
    static defaultProps = {
        top: 0,
        canvasWidth: 1200,
        canvasHeight: 800,
        minRadius: 50,
        handleAudioRecording: undefined,
    };

    static propTypes = {
        top: PropTypes.number,
        canvasWidth: PropTypes.number,
        canvasHeight: PropTypes.number,
        minRadius: PropTypes.number,
        handleAudioRecording: PropTypes.func,
    };

    constructor(props) {
        config = globalConfig.config;
        
        super(props);
        this.state = {
            drawAudioInput: false,
        }

        this.gotStream = this.gotStream.bind(this);

        this.canvas = React.createRef();
        this.audioContext = new AudioContext(  );
        this.audioInput = null;
        this.realAudioInput = null;
        this.inputPoint = null;
        this.mediaRecorder = null;
        this.rafID = null;
        this.analyserContext = null;
        this.chunks = [];
        this.analyserNode = null;
        this.lastCanvasUpdate = null;
        this.recorder = null;
        this.averageVolume = -1;
        this.lineColor = getComputedStyle(document.documentElement).getPropertyValue('--color-actionelements');
        this.bgColor = getComputedStyle(document.documentElement).getPropertyValue('--color-background');

        if (config.recorderConfig.levelIndicator.type==="fft_rings")
        {
            this.SHOW_FFT_CIRCLES = true; // switch between pulsating circles and oscilloscope ring visual microphone info
            this.SHOF_FFF_MIRROR_CIRCLE = false; // mirror circle of oscilloscope view to be more symmetric    
            this.SHOW_FFT_FFTSIZE = 32; //use minimum allowed value here for fast computation
        }
        else if (config.recorderConfig.levelIndicator.type==="oscilloscope")
        {
            this.SHOW_FFT_CIRCLES = false; // switch between pulsating circles and oscilloscope ring visual microphone info
            this.SHOF_FFF_MIRROR_CIRCLE = false; // mirror circle of oscilloscope view to be more symmetric    
            this.SHOW_FFT_FFTSIZE = 1024*8; //use minimum allowed value here for fast computation
        }
        else if (config.recorderConfig.levelIndicator.type==="oscilloscope_mirrored")
        {
            this.SHOW_FFT_CIRCLES = false; // switch between pulsating circles and oscilloscope ring visual microphone info
            this.SHOF_FFF_MIRROR_CIRCLE = true; // mirror circle of oscilloscope view to be more symmetric    
            this.SHOW_FFT_FFTSIZE = 1024*4; //use minimum allowed value here for fast computation
        }
        else
        {
            // Fallback
            this.SHOW_FFT_CIRCLES = true; // switch between pulsating circles and oscilloscope ring visual microphone info
            this.SHOF_FFF_MIRROR_CIRCLE = false; // mirror circle of oscilloscope view to be more symmetric    
            this.SHOW_FFT_FFTSIZE = 32; //use minimum allowed value here for fast computation
        }
        
    };

    componentDidMount(){
        //TODO: for macOS/iOS, see this examples: https://kaliatech.github.io/web-audio-recording-tests/dist/#/test1
        //code: https://github.com/kaliatech/web-audio-recording-tests-simpler/blob/master/js/RecorderService.js
        
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) 
        {
            console.log('getUserMedia supported.');
            navigator.mediaDevices.getUserMedia (
                // constraints - only audio needed for this app
                {
                    audio: true, //{ deviceId: {exact: "304F0423E7685D6A4966751A7594FA4FD96E06F9"}}
                })
                // Success callback
                .then(this.gotStream)
                // Error callback
                .catch(function(err) {
                    console.log('The following getUserMedia error occured: ' + err);
                });

                navigator.mediaDevices.enumerateDevices()
                .then(function(devices) 
                {
                    devices.forEach(function(device) 
                    {
                        if (device.kind=="audioinput") console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
                    });
                })
                .catch(function(err) 
                {
                    console.log(err.name + ": " + err.message);
                });
    
        } else {
            console.log('getUserMedia not supported on your browser!');
        }

        // Webworker whitelisting
        this.enableWebworker=false;
        if (navigator.userAgent.search("Android 7")>-1) this.enableWebworker=true;

    }

    componentDidUpdate(){
        this.initAnalyser()
    }

    startRecording(){
        // start recording
        if (this.audioContext.state === 'suspended'){ //audioContext could be suspended before User Input. Resume before starting the Recording.
            this.audioContext.resume().then(() => {this.startRecording()});
        } else {
            this.chunks = [];
            if (this.recorder && this.recorder.state==="inactive"){
                this.recordingAborted = false;
                this.recorder.start(); 
            }
            this.setState({drawAudioInput: true});
        }
    }

    abortRecording(){
        this.recordingAborted = true;
        this.initAnalyser(); //clear the canvas
        this.stopRecording();
    }

    stopRecording(){
        // stop recording
        if (this.recorder){//} && this.recorder.state==="recording"){
            this.recorder.stop();
        }
        this.setState({drawAudioInput: false});
    }

    cancelAnalyserUpdates() {
        window.cancelAnimationFrame( this.rafID );
        this.rafID = null;
    }


    initAnalyser(){
        this.analyserContext = this.canvas.current.getContext('2d');

        this.analyserContext.fillStyle = this.lineColor;
        this.analyserContext.strokeStyle = this.lineColor;
        this.analyserContext.lineCap = 'round';
        this.analyserContext.lineWidth = 2;

        this.analyserContext.clearRect(0, 0, this.props.canvasWidth, this.props.canvasHeight);
        this.lastCanvasUpdate = Date.now()
        this.recordingStartTime = undefined;
    }

    updateAnalysers(time) {
        var freqByteData;
        var multiplier;
        var i,j;
        var magnitude;
        var offset;
        var currentCanvasUpdate;

        //refire timer
        this.rafID = window.requestAnimationFrame( () => {this.updateAnalysers()} );

        //check if currently recording
        if (this.state.drawAudioInput === false){
            return;
        }

        currentCanvasUpdate = Date.now()
        if (currentCanvasUpdate - this.lastCanvasUpdate < DRAW_TIME_DELAY){
            return;
        }

        this.lastCanvasUpdate = currentCanvasUpdate;

        // analyzer draw code here
        freqByteData = new Uint8Array(this.analyserNode.frequencyBinCount);
        var timeByteData = new Uint8Array(this.analyserNode.frequencyBinCount);

        this.analyserContext.clearRect(0, 0, this.props.canvasWidth, this.props.canvasHeight);

        //var minCanvasRadius = Math.min(this.props.canvasWidth, this.props.canvasHeight)/2;
        var maxCanvasRadius = Math.max(this.props.canvasWidth, this.props.canvasHeight)/2;
        var averageVolumeRadius = this.props.minRadius + 0.3*(maxCanvasRadius - this.props.minRadius);

        var centerX = this.props.canvasWidth/2;
        var centerY = this.props.canvasHeight/2;

        // Create gradient
        var grd = this.analyserContext.createRadialGradient(centerX,centerY, 0, centerX,centerY, 1.2*maxCanvasRadius);
        grd.addColorStop(0, this.lineColor);
        grd.addColorStop(1, this.bgColor);

        // Fill with gradient
        this.analyserContext.fillStyle = grd;
        this.analyserContext.fillRect(0, 0, this.props.canvasWidth, this.props.canvasHeight); 

        this.analyserContext.fillStyle = this.lineColor; //restore fillstyle for lines

        multiplier = (this.analyserNode.frequencyBinCount / NUM_CIRCLES)/2;

        // Draw circle for each frequency band
        if (this.SHOW_FFT_CIRCLES===true)
        {
            this.analyserNode.getByteFrequencyData(freqByteData); 

            averageVolumeRadius = this.props.minRadius + 0.15*(maxCanvasRadius - this.props.minRadius);
            for (i = 0; i < NUM_CIRCLES; ++i) {
                magnitude = 0;
                offset = Math.floor( i * multiplier );
                for (j = 0; j< multiplier; j++)
                    magnitude += freqByteData[offset + j];
                
                magnitude = magnitude / multiplier*(i+1);
                
                //average volume to always fill screen
                if (this.averageVolume === -1) {
                    this.averageVolume = magnitude;
                } else {
                    this.averageVolume = 0.95*this.averageVolume + 0.05*magnitude;
                }

                //magnitude = magnitude/DRAW_AUDIO_AMPLITUDE_MAX*minCanvasRadius + this.props.minRadius;
                magnitude = (magnitude/this.averageVolume*averageVolumeRadius)+this.props.minRadius;
                this.analyserContext.beginPath();
                this.analyserContext.arc(centerX,centerY,magnitude,0,2*Math.PI,false);
                this.analyserContext.stroke();
            } 
        }
        else
        {
            this.analyserNode.getByteTimeDomainData(timeByteData)
            this.analyserContext.beginPath();
            var sigmax=0;
            var timeData=new Int16Array(timeByteData.length);
            for (i=0; i<this.analyserNode.frequencyBinCount; i++)
            {
            timeData[i]=(timeByteData[i]-128);
            if (sigmax<timeData[i]) sigmax=timeData[i];
            }
            var scale = 100/(sigmax);
            if (scale>2) scale=2;
            scale*=averageVolumeRadius/300;

            var j=0;
            if (this.SHOF_FFF_MIRROR_CIRCLE===false)
            {
                for (i=0; i<=this.analyserNode.frequencyBinCount; i++)
                {
                    if (i===this.analyserNode.frequencyBinCount) j=0; else j=i;

                    var angle = (j/this.analyserNode.frequencyBinCount)*Math.PI*2;
                    var ampl = (timeData[j]*scale)+(averageVolumeRadius);

                    var x=(Math.sin(angle)*ampl)+centerX;
                    var y=(Math.cos(angle)*ampl)+centerY;

                    if (i===0) this.analyserContext.moveTo(x,y);
                    else this.analyserContext.lineTo(x,y);
                }
                this.analyserContext.stroke();
            }
            else
            {
                for (i=0; i<this.analyserNode.frequencyBinCount; i++)
                {
                    if (i===this.analyserNode.frequencyBinCount) j=0; else j=i;

                    var angle = (j/this.analyserNode.frequencyBinCount)*Math.PI;
                    var ampl = (timeData[j]*scale)+(averageVolumeRadius);

                    var x=(Math.sin(angle)*ampl)+centerX;
                    var y=-(Math.cos(angle)*ampl)+centerY;

                    if (i===0) this.analyserContext.moveTo(x,y);
                    else this.analyserContext.lineTo(x,y);
                }
                for (i=0; i<this.analyserNode.frequencyBinCount; i++)
                {
                    if (i===this.analyserNode.frequencyBinCount) j=0; else j=i;

                    var angle = ((1.0-(j/this.analyserNode.frequencyBinCount))*Math.PI)+Math.PI;
                    var ampl = (timeData[j]*scale)+(averageVolumeRadius);

                    var x=(Math.sin(angle)*ampl)+centerX;
                    var y=-(Math.cos(angle)*ampl)+centerY;

                    if (i===0) this.analyserContext.moveTo(x,y);
                    else this.analyserContext.lineTo(x,y);
                }
                this.analyserContext.stroke();
            }
        }
    
    }


    gotStream(stream) {
        this.inputPoint = this.audioContext.createGain();

        // Create an AudioNode from the stream and connect to analyser for graphic representation of the input
        this.realAudioInput = this.audioContext.createMediaStreamSource(stream);
        this.audioInput = this.realAudioInput;

        this.audioInput.connect(this.inputPoint);
        this.analyserNode = this.audioContext.createAnalyser();
        this.analyserNode.fftSize = this.SHOW_FFT_FFTSIZE; //use minimum allowed value here for fast computation
        this.analyserNode.smoothingTimeConstant = FREQ_ANALYSER_SMOOTHONG_CONSTANT;
        this.inputPoint.connect( this.analyserNode );

//        this.inputPoint.connect( this.inputPoint.context.destination );

        this.chunks = [];
        var localProps = this.props;
        var classRef = this;

        var recorderoptions = {
            encoderSampleRate : 16000,
            originalSampleRateOverride : 16000,
            sourceNode: this.realAudioInput,
            webworkerEnabled : this.enableWebworker,
        };          
        this.recorder = new Recorder ( recorderoptions ); 
        this.recorder.ondataavailable = function(e) {
            classRef.chunks.push(e);
        };

        this.recorder.onstop = this.recorder_onstop.bind(this);

        this.initAnalyser();
        this.updateAnalysers();
    }

    recorder_onstop(e) 
    {     
        //check if the recording was aborted
        if (this.recordingAborted === true){
            this.chunks=[];
            return;
        }
        if (this.props.handleAudioRecording) {
            this.props.handleAudioRecording(this.chunks)
        }
        this.chunks=[];
    };


    render() { 
        return (            
            <canvas className="position-absolute"id="analyser" ref={this.canvas} width={this.props.canvasWidth} height={this.props.canvasHeight}/>
        );
    }
}
