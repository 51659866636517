/* Original Code @ https://codepen.io/WillyW/pen/wZebow */


class DraggingEvent {
  constructor(target = undefined) {
    this.target = target;
  }
  
  event(callback) {
    let handler;
    
    this.target.addEventListener("mousedown", e => {
      e.preventDefault()
      
      handler = callback(e)
      
      window.addEventListener("mousemove", handler)
      
      document.addEventListener("mouseleave", clearDraggingEvent)
      
      window.addEventListener("mouseup", clearDraggingEvent)
      
      function clearDraggingEvent() {
        window.removeEventListener("mousemove", handler)
        window.removeEventListener("mouseup", clearDraggingEvent)
      
        document.removeEventListener("mouseleave", clearDraggingEvent)
        
        handler(null)
      }
    })
    
    this.target.addEventListener("touchstart", e => {
      handler = callback(e)
      
      window.addEventListener("touchmove", handler)
      
      window.addEventListener("touchend", clearDraggingEvent)
      
      document.body.addEventListener("mouseleave", clearDraggingEvent)
      
      function clearDraggingEvent() {
        window.removeEventListener("touchmove", handler)
        window.removeEventListener("touchend", clearDraggingEvent)
        
        handler(null)
      }
    }, { passive: true })
  }
  
  // Get the distance that the user has dragged
  getDistance(callback) {
    function distanceInit(e1) {
      let startingX, startingY;
      
      if ("touches" in e1) {
        startingX = e1.touches[0].clientX
        startingY = e1.touches[0].clientY
      } else {
        startingX = e1.clientX
        startingY = e1.clientY
      }
      

      return function(e2) {
        if (e2 === null) {
          return callback(null)
        } else {
          
          if ("touches" in e2) {
            return callback({
              x: e2.touches[0].clientX - startingX,
              y: e2.touches[0].clientY - startingY
            })
          } else {
            return callback({
              x: e2.clientX - startingX,
              y: e2.clientY - startingY
            })
          }
        }
      }
    }
    
    this.event(distanceInit)
  }
}

export default class CardCarousel extends DraggingEvent {
  constructor(container, controller = undefined) {
    super(container)
    
    // DOM elements
    this.container = container
    this.controllerElement = controller
    this.cards = container.querySelectorAll(".card")

    // Carousel data
    this.centerIndex = Math.floor((this.cards.length - 1) / 2);
    this.cardWidth = this.cards[this.centerIndex].offsetWidth / this.container.offsetWidth * 100;
    this.xScale = {};
    this.maxZIndex=2;
    //remember ratio of card width to container height for later resizes
    this.cardWidthToOffsetHeightRatio = this.cards[this.centerIndex].offsetWidth / this.container.offsetHeight;

    this.container.classList.add("smooth-return");
    
    // Resizing
    window.addEventListener("resize", this.updateCardWidth.bind(this))
    
    if (this.controllerElement) {
      this.controllerElement.addEventListener("keydown", this.controller.bind(this))      
    }

    // Initializers
    this.build()
    
    // Bind dragging event
    super.getDistance(this.moveCards.bind(this))
  }

  
  updateCardWidth() {
    var newcardWidth = this.container.offsetHeight*this.cardWidthToOffsetHeightRatio;
    this.cardWidth = newcardWidth / this.container.offsetWidth * 100;
    this.rebuild()
  }
  

  build() {

    for (let i = 0; i < this.cards.length; i++) {
      const x = i - this.centerIndex;
      const scale = this.calcScale(x)
      const scale2 = this.calcScale2(x)
      const zIndex = -(Math.abs(i - this.centerIndex))
      
      const leftPos = this.calcPos(x, scale2)
     
      this.xScale[x] = this.cards[i]
      
      this.updateCards(this.cards[i], {
        x: x,
        scale: scale,
        leftPos: leftPos,
        zIndex: zIndex
      })
    }
  }

  rebuild() {
    for (let i = 0; i < this.cards.length; i++) {
      const x = this.cards[i].getAttribute("data-x"); //keep current order
      const scale = this.calcScale(x)
      const scale2 = this.calcScale2(x)
      const zIndex = this.cards[i].style.zIndex; //keep current order
      
      const leftPos = this.calcPos(x, scale2)
     
      this.xScale[x] = this.cards[i]
      
      this.updateCards(this.cards[i], {
        x: x,
        scale: scale,
        leftPos: leftPos,
        zIndex: zIndex
      })
    }
  }

  
  controller(e) {
    const temp = {...this.xScale};
      
      if (e.keyCode === 39) {
        // Left arrow
        for (let x in this.xScale) {
          const newX = (parseInt(x) - 1 < -this.centerIndex) ? this.cards.length - 1 - this.centerIndex : parseInt(x) - 1;
          temp[newX] = this.xScale[x]
        }
      }
      
      if (e.keyCode === 37) {
        // Right arrow
        for (let x in this.xScale) {
          const newX = (parseInt(x) + 1 > this.cards.length - 1 - this.centerIndex) ? -this.centerIndex : parseInt(x) + 1;
          temp[newX] = this.xScale[x]
        }
      }
      
      this.xScale = temp;
      
      for (let x in temp) {
        const scale = this.calcScale(x),
              scale2 = this.calcScale2(x),
              leftPos = this.calcPos(x, scale2),
              zIndex = -Math.abs(x)

        this.updateCards(this.xScale[x], {
          x: x,
          scale: scale,
          leftPos: leftPos,
          zIndex: zIndex
        })
      }
  }
  
  calcPos(x, scale) {
    let formula;
    
    if (x < 0) {
      formula = (scale * 100 - this.cardWidth) / 2
      
      return formula

    } else if (x > 0) {
      formula = 100 - (scale * 100 + this.cardWidth) / 2
      
      return formula
    } else { //x==0
      formula = 100 - (scale * 100 + this.cardWidth) / 2
      
      return formula
    }
  }
  
  updateCards(card, data) {
    if (data.x || data.x === 0) {
      card.setAttribute("data-x", data.x)
    }
    
    if (data.scale || data.scale === 0) {
      card.style.transform = `scale(${data.scale}) translate(0px, ${this.container.offsetHeight*(data.scale-0.9)}px)`

      if (data.scale === 0) {
        card.style.opacity = data.scale
      } else {
        card.style.opacity = 1;
      }
    }
   
    if (data.leftPos) {
      card.style.left = `${data.leftPos}%`
      //card.style.transform = 'translate(200, 200)'
    }
    
    if (data.zIndex || data.zIndex === 0) {
      //highlight center card
      if (data.zIndex === 0) {
        card.classList.add("highlight")
      } else {
        card.classList.remove("highlight")
      }

      //hide cards at the ends of the carousel
      if(Math.abs(data.zIndex) > this.maxZIndex){
        card.style.display = 'none';
      } else {
        card.style.display = 'flex';
      }
      card.style.zIndex = data.zIndex
    }
  }
  
  calcScale2(x) {
    //used to calculate x-offsets
    let formula;
   
    if (x <= 0) {
      //formula = 1 - -1 / 5 * x
      formula = 1 + 0.3 * x
      
      return formula
    } else if (x > 0) {
      formula = 1 - 0.3 * x
      
      return formula
    }
  }
  
  calcScale(x) {
    //used to calculate scale

    //const formula = 1 - 1 / 5 * Math.abs(x); //Math.pow(x, 2)
    const formula = 1 - 0.15 * Math.abs(x);
    
    if (formula <= 0) {
      return 0 
    } else {
      return formula      
    }
  }
  
  checkOrdering(card, x, xDist) {    
    const original = parseInt(card.dataset.x)
    const rounded = Math.round(xDist)
    let newX = x + rounded;
    
    
    if (rounded !== 0) {
      if (newX > original) {
        if (newX > this.cards.length - 1 - this.centerIndex) {
          
          newX = newX - this.cards.length;
        }
      } else if (newX < original) {
        if (newX < - this.centerIndex) {
          newX = newX + this.cards.length;
        }
      }
      
      this.xScale[newX] = card;
    }
    
    const temp = -Math.abs(newX)
    this.updateCards(card, {zIndex: temp})
    
    return newX - rounded;
    
  }
  
  moveCards(data) {
    let xDist;
   
    if (data != null) {
      this.container.classList.remove("smooth-return")
      xDist = data.x / 250;
    } else {

      
      this.container.classList.add("smooth-return")
      xDist = 0;

      for (let x in this.xScale) {
        this.updateCards(this.xScale[x], {
          x: x,
          /*zIndex: Math.abs(Math.abs(x) - this.centerIndex) THIS SEEMS COMPLETELY WRONG... */
          zIndex: -(Math.abs(x))
        })
      }
    }

    for (let i = 0; i < this.cards.length; i++) {
      const x = this.checkOrdering(this.cards[i], parseInt(this.cards[i].dataset.x), xDist),
            scale = this.calcScale(x + xDist),
            scale2 = this.calcScale2(x + xDist),
            leftPos = this.calcPos(x + xDist, scale2)
      
      
      this.updateCards(this.cards[i], {
        scale: scale,
        leftPos: leftPos
      })
    }
  }
}

