import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

// Import Icons
import TextInputIcon from '../icons/chat_icon_bold.svg';
import CloseIcon from '../icons/close_icon_bold.svg';
import BlockedIcon from '../icons/mic_icon_blocked.svg';//'../icons/mic_blocked_icon_bold.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class TextInputButton extends React.Component {
    static defaultProps = {
        // Props that can be updated:
        buttonHeight: 50,
        width: window.innerWidth,
        label: "",
        blocked: false
    };

    static propTypes = {
        // Props that can be updated:
        buttonHeight: PropTypes.number,
        width: PropTypes.number,
        label: PropTypes.string,
        blocked: PropTypes.bool
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            popupOpen: false,
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.showPopup = this.showPopup.bind(this);

        this.inputFieldRef = React.createRef();
    };

    handleClose(){
        this.setState({popupOpen: false})
    }

    handleSend(){
        if (this.props.handleSend){
            this.props.handleSend(this.inputFieldRef.current.value)
        }
        this.handleClose();
    }

    showPopup(){
        if (this.inputFieldRef.current){
            this.inputFieldRef.current.value = "";
        }
        this.setState({
            popupOpen: true
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.popupOpen === true && prevState.popupOpen === false) {
            if (this.inputFieldRef.current){
                this.inputFieldRef.current.focus();
            }
        }
      }

    render() {
        return (
            <React.Fragment>
            <button 
                className="btn btn-secondary" 
                style={{minHeight: this.props.buttonHeight, maxHeight: this.props.height, width: this.props.width}}
                onClick={this.showPopup}>
                <img 
                    id="textinputimg" 
                    src={TextInputIcon} 
                    alt="" 
                    height="90%"
                    style={{marginTop: "2px", marginRight: "6px"}}/>
                {this.props.label}
            </button>
            <div
                className="modal-background"
                style={{display: this.state.popupOpen ? 'flex' : 'none'}}>
                <div className="modal-foreground flex-container-column" 
                    style={{maxWidth: this.props.width,
                            height: '90%',
                            margin: '16px'}}>
                    <div className="flex-container-row" style={{justifyContent: 'space-between'}}>
                        <label className="label-onbackground">{config.copy.recorder.textinputpopup.title + ':'}</label>
                        <img 
                            onClick={this.handleClose}
                            src={CloseIcon} 
                            alt="Close" 
                            className="filter-mediumgrey modal-close-icon"/>
                    </div>
                    <textarea 
                        ref={this.inputFieldRef}
                        className="flex-container-row input-onbackground" 
                        rows='50' 
                        style={{resize: 'none', maxHeight: '100%', width: 'calc(100% - 6px)', overflowY: 'scroll', margin: '8px'}}
                        onKeyUp={(e)=>{
                            if (e.key === 'Enter' || e.keyCode === 13) {this.handleSend()}
                        }}
                        onKeyDown={(e)=>{
                            e.stopPropagation()
                        }}>
                    </textarea>
                    <div className="flex-container-row modal-buttoncontainer" style={{marginTop: '8px'}}>
                        <button className="btn btn-secondary" onClick={this.handleClose} style={{minHeight: this.props.buttonHeight}}>
                            {config.copy.recorder.textinputpopup.abort}
                        </button>
                        {this.props.blocked ? 
                        <button className="btn btn-primary" disabled style={{minHeight: this.props.buttonHeight}}>
                        <img 
                            id="micblockedimg" 
                            src={BlockedIcon} 
                            alt="mic blocked" 
                            height="90%"
                            style={{marginTop: "2px", marginRight: "6px"}}/>
                        </button>
                        :
                        <button className="btn btn-primary" onClick={this.handleSend} style={{minHeight: this.props.buttonHeight}}>
                            {config.copy.recorder.textinputpopup.send}
                        </button>}
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    };
}
