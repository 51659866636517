import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

// Import Components
import Backend from './backendCalls.js';
import createRandomKey from '../utils/randomKeys.js';
import SessionBanner from '../utils/sessionBanner.js';
import ErrorPopup from '../utils/errorPopup.js';
import WaitingForServerOverlay from '../utils/waitingForServerOverlay.js';

// Import Icons
import CreateIcon from '../icons/create_icon.svg';

// Import Styles
import '../styles/slider.css'

import globalConfig from '../globalConfig.js'
var config = {};

export default class createSession extends React.Component {
    static defaultProps = {
        maxWidth: 640,
    };

    static propTypes = {
        maxWidth: PropTypes.number,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);

        this.state = {
            daysValid: "1",
            sessionName: this.createDefaultSessionName(),
            numberOfDevices: "50",
            microphonesEnabled: true,
            showQuestions: true,
            width: window.innerWidth,
            height: window.innerHeight,
            waitingForServer: false,
        }
        this.handleRangeInputChange = this.handleRangeInputChange.bind(this);
        this.handleSessionNameChange = this.handleSessionNameChange.bind(this);
        this.handlenumberOfDevicesChange = this.handlenumberOfDevicesChange.bind(this);
        this.handleMicrophonesEnabled = this.handleMicrophonesEnabled.bind(this);
        this.handleShowQuestionsEnabled = this.handleShowQuestionsEnabled.bind(this);
        this.handleCreateSession = this.handleCreateSession.bind(this);
        this.createCredentials = this.createCredentials.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleServerStatus = this.handleServerStatus.bind(this);

        this.loginData = {
            daysValid: undefined,
            expiresBy: undefined, 
            name: undefined, 
            numberOfDevices: undefined, 
            sessionID: undefined,
            masterKey: undefined,
            guestKey: undefined,
            options: undefined
        }

        this.errorPopupRef = React.createRef();
        this.server = new Backend();
    };


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        this.createCredentials();

    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleResize(){
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    handleRangeInputChange(e){
        this.setState({daysValid: e.target.value});
    }

    handleSessionNameChange(e){
        this.setState({sessionName: e.target.value});
    }

    handlenumberOfDevicesChange(e){
        this.setState({numberOfDevices: e.target.value});
    }

    handleMicrophonesEnabled(e){
        e.target.classList.toggle('active');
        this.setState({microphonesEnabled: !this.state.microphonesEnabled});
    }

    handleShowQuestionsEnabled(e){
        e.target.classList.toggle('active');
        this.setState({showQuestions: !this.state.showQuestions});
    }

    createCredentials(){
        //create ID and password
        var sessionID = createRandomKey(16,4);
        var masterKey = createRandomKey(6,6);
        var guestKey = ""; //leave guestKey empty if guest sessions are disabled
        if (config.configflags.includes("enable_guest_session")) {
            guestKey = createRandomKey(6,6);
        }

        this.loginData.sessionID = sessionID;
        this.loginData.masterKey = masterKey;
        this.loginData.guestKey = guestKey;
    }

    createDefaultSessionName(){
        var date = new Date()
        var randomID = Math.random().toString(36).slice(2).substring(0,6);
        var newSessionName = date.toISOString().substring(0, 10) + '-' + randomID;
        
        return newSessionName;
    }



    handleCreateSession(){

        //update login data
        var dateToday = Date.now();
        var expireDate = dateToday + 24*60*60*1000*this.state.daysValid;
        //console.log(new Date(expireDate))

        this.loginData.daysValid = this.state.daysValid;
        this.loginData.expiresBy = expireDate;
        this.loginData.name = this.state.sessionName;
        this.loginData.numberOfDevices = this.state.numberOfDevices;
        this.loginData.options = {
            microphonesEnabled: this.state.microphonesEnabled,
            showQuestions: this.state.showQuestions,
        }

        //create a new 
        console.log("login Data in create Session: ", this.loginData)
        this.server.connect('newSess', this.loginData, "", this.handleServerStatus);
        this.setState({waitingForServer: true})
    }

    handleServerStatus(status){
        if (status === "ack_new_sess"){
            this.setState({waitingForServer: false})
            //make deep copy before leaving the site
            this.props.history.push("/session/confirm", { 
                loginData: {
                    daysValid: this.loginData.daysValid,
                    expiresBy: this.loginData.expiresBy, 
                    name: this.loginData.name, 
                    numberOfDevices: this.loginData.numberOfDevices, 
                    sessionID: this.loginData.sessionID,
                    masterKey: this.loginData.masterKey,
                    guestKey: this.loginData.guestKey,
                    options: {
                        microphonesEnabled: this.state.microphonesEnabled,
                        showQuestions: this.state.showQuestions,
                    }
                }
            });
        } else if (status === "nack_new_sess"){
            //show error popup
            this.setState({waitingForServer: false})
            if (this.errorPopupRef.current){
                this.errorPopupRef.current.showPopup("newsessrefused");
            }
        }
    }

    render() {
        return (
            <main className="app-body">
                <ErrorPopup ref={this.errorPopupRef}/>
                <WaitingForServerOverlay visible={this.state.waitingForServer}/>
                <SessionBanner type="header" width={this.state.width}/>
                <div className="flex-container-column" style={{height: "100%", justifyContent: 'center'}}>
                    <div style={{width: '100%', maxWidth: this.props.maxWidth, padding: '25px'}}>
                        <div className="grid-container" width="100%">
                            <label className="grid-itemleft label-onbackground" htmlFor="sessionnameinput" >{config.copy.create.sessionname}</label>
                            <input type="text" className="grid-itemright input-onbackground" name="sessionnameinput" 
                                defaultValue={this.state.sessionName} onChange={this.handleSessionNameChange}/>
                            <label className="grid-itemleft label-onbackground" htmlFor="daysValidInput" >{config.copy.create.validduration}</label>
                            <div className="grid-itemright flex-container-row">
                                <input type="range" className="slider primary" style={{margin: '2px', marginTop: '-1.3em'}} 
                                    name="daysValidInput" min="1" max="7" defaultValue={this.state.daysValid} 
                                    onInput={this.handleRangeInputChange}/>
                                <label className="label-onbackground" 
                                    style={{flexShrink: '0', textAlign: 'left', marginLeft: '10px', minWidth: '3em'}}>
                                    {this.state.daysValid + ' ' + (this.state.daysValid === '1' ? config.copy.create.day : config.copy.create.days)}</label>
                            </div>
                            <label className="grid-itemleft label-onbackground" htmlFor="numberofdevicesinput">{config.copy.create.maxnofdevices}</label>
                            <input type="text" className="grid-itemright input-onbackground" name="numberofdevicesinput" 
                                defaultValue={this.state.numberOfDevices} onChange={this.handlenumberOfDevicesChange}/>
                            <div className="grid-itemspan flex-container-row">
                                <button name="microphoneaktivinput" className="btn-checkbox active" style={{marginLeft: '15px'}} 
                                    onClick={this.handleMicrophonesEnabled}></button>
                                <label htmlFor="microphoneaktivinput" className="label-onbackground">{config.copy.create.options.deactivatemics}</label>
                            </div>
                            <div className="grid-itemspan flex-container-row">
                                <button name="questionsenabledinput" className="btn-checkbox active" style={{marginLeft: '15px'}} 
                                    onClick={this.handleShowQuestionsEnabled}></button>
                                <label htmlFor="questionsenabledinput" className="label-onbackground">{config.copy.create.options.showquestions}</label>
                            </div>
                            <button
                                className="btn btn-primary grid-itemspan" 
                                type="submit" 
                                onClick={this.handleCreateSession} 
                                style={{width: '100%', flex: '1 1 0px', padding:'0px', align: 'center'}}>
                                <img
                                    src={CreateIcon} 
                                    alt="Create Icon" 
                                    height="90%"
                                    style={{marginRight:"5px"}}/>
                                {config.copy.create.createsession}
                            </button>
                        </div>
                    </div>
                </div>
                <SessionBanner type="footer" width={this.state.width}/>
            </main>
        );
    };
}
