import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import ConnectedIcon from '../icons/connected_icon_bold.svg';
import DisconnectedIcon from '../icons/disconnected_icon_bold.svg';
import MicIcon from '../icons/mic_icon_bold.svg';
import VideoIcon from '../icons/video_icon_bold.svg';
import ControlsIcon from '../icons/playpause_icon_bold.svg';
import FastIcon from '../icons/speed_fast_icon_bold.svg';
import SlowIcon from '../icons/speed_slow_icon_bold.svg';
import IntegratedSessionIcon from '../icons/integrated_session_icon_bold.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class DeviceOverview extends React.Component {
    static defaultProps = {
        deviceList: undefined,
        imageSize: 40,
    };

    static propTypes = {
        deviceList: PropTypes.array,
        imageSize: PropTypes.number,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            debug: false,
        }

        var i;
        this.debugCanvasRef = []
        this.speedOKRef = []
        this.deviceInfo = [];
        for (i=0; i<500; i++)
        {
            var dref = React.createRef();
            this.debugCanvasRef.push(dref);
            dref = React.createRef();
            this.speedOKRef.push(dref);
            this.deviceInfo.push({speedOK: true, speedVisible: false});
        }

        this.divOnClickBind=this.divOnClick.bind(this);
        this.debugEnableTimer=null;
        this.debugEnableCounter=0;
    };

    toggleDebug()
    {
        if (this.state.debug===true)
            this.setState({debug: false});
        else
            this.setState({debug: true});
    }

    divOnClick()
    {
        if (this.debugEnableTimer===null)
        {
            this.debugEnableTimer=setTimeout(() => {this.debugEnableCounter=0; this.debugEnableTimer=null;},3000);
        }
        else
        {
            this.debugEnableCounter++;
            if (this.debugEnableCounter==4)
            {
                this.toggleDebug();
                clearTimeout(this.debugEnableTimer);
                this.debugEnableTimer=null;
                this.debugEnableCounter=0;
            }
        }
    }

    detectBrowserType(useragent)
    {
        var pos;
        var tmpstr;
        var detected_os;
        var browser_type;
        detected_os="Unknown OS"
        browser_type="Unknown Browser"
        if (useragent.search("Android")>-1)
        {
            pos=useragent.search("Android");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;)]/);
            detected_os=tmpstr.substr(0,pos);
        }
        else if (useragent.search("iPhone OS")>-1)
        {
            pos=useragent.search("iPhone OS");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search("like");
            detected_os=tmpstr.substr(0,pos-1);
        }
        else if (useragent.search("iPad")>-1)
        {
            pos=useragent.search("OS");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/like/i);
            detected_os="iPad "+tmpstr.substr(0,pos-1);
        }
        else if (useragent.search("Mac OS X")>-1)
        {
            pos=useragent.search("Mac OS X");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;)]/);
            detected_os=tmpstr.substr(0,pos);
        }
        else if (useragent.search("Linux")>-1)
        {
            pos=useragent.search("Linux");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;)]/);
            detected_os=tmpstr.substr(0,pos);
        }
        else if (useragent.search("Windows")>-1)
        {
            pos=useragent.search("Windows");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;)]/);
            tmpstr=tmpstr.substr(0,pos);
            switch (tmpstr)
            {
                case "Windows NT 10.0": detected_os="Windows 10"; break;
                default: detected_os="Windows";
            }

        }

        if (useragent.search("Edg")>-1)
        {
            pos=useragent.search("Edg");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;)]/);
            if (pos!==-1)
                browser_type=tmpstr.substr(0,pos);
            else
                browser_type=tmpstr.substr(0);

            browser_type=browser_type.substr(0,3)+"e"+browser_type.substr(3);
        }
        else if (useragent.search("Chrome")>-1)
        {
            pos=useragent.search("Chrome");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;) ]/);
            browser_type=tmpstr.substr(0,pos);
        }
        else if (useragent.search("Firefox")>-1)
        {
            pos=useragent.search("Firefox");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;)]/);
            if (pos!==-1)
                browser_type=tmpstr.substr(0,pos);
            else
                browser_type=tmpstr.substr(0);
        }
        else if (useragent.search("Safari")>-1)
        {
            pos=useragent.search("Safari");
            tmpstr=useragent.substr(pos);
            pos=tmpstr.search(/[;) ]/);
            if (pos!==-1)
                browser_type=tmpstr.substr(0,pos);
            else
                browser_type=tmpstr.substr(0);
        }

        return detected_os+" "+browser_type;
    }

    componentDidUpdate()
    {
//        if (this.state.debug===true)
        {
            var i;
            for (i=0; i<this.props.deviceList.length; i++)
            {
                var dref = this.debugCanvasRef[i].current;
                if (dref===null) continue;

                var video_dbg = this.props.deviceList[i].video_dbg;
                var device = this.props.deviceList[i];
                if (video_dbg===undefined) continue;

                this.deviceInfo[i].speedOK=device.speedOK;
                dref.title=video_dbg.user_agent;

                //////////////// CPU Info
                var xoffset=0;
                var yoffset=0;
                var ctx;

                if (this.props.deviceList[i].vid!==true) 
                {
                    dref.height=20;
                }
                else
                {
                    dref.height=80;
                }
                
                
                ctx = dref.getContext("2d");
                ctx.font = "12px Roboto";
                ctx.fillStyle = '#000000';
                ctx.fillRect(0,0,500,500);

                ctx.fillStyle='#E0E0E0';
                ctx.fillText(this.detectBrowserType(video_dbg.user_agent), xoffset+5, yoffset+12);

                ctx.font = "15px Roboto";

                yoffset+=21;

                this.deviceInfo[i].speedVisible=false;
                if (this.props.deviceList[i].vid!==true) continue;

                var cpu;
                ctx.fillStyle='#E0E0E0';
                ctx.fillText("CPU", xoffset+5, yoffset+12);
        
                ctx.strokeStyle = '#FFFFFF';
                ctx.beginPath();
                ctx.rect(xoffset+50,yoffset,50,15);
                ctx.stroke();
        
                cpu=(video_dbg.transcodeTime/500.0);
                if (cpu<0.0) cpu=0.0;
                if (cpu>1.0) cpu=1.0;
        
                if (cpu<0.5) ctx.fillStyle='#00FF00';
                else if (cpu<0.8) ctx.fillStyle='#FFFF00';
                else ctx.fillStyle='#FF0000';
                cpu*=42.0;
        
                ctx.fillRect(xoffset+54,yoffset+4,cpu,7);

                //////////////// Buffered fragment count
                xoffset=0;
                yoffset+=20;

                ctx.fillStyle='#E0E0E0';
                ctx.fillText("Buf", xoffset+5, yoffset+12);
        
                ctx.strokeStyle = '#FFFFFF';
                ctx.beginPath();
                ctx.rect(xoffset+50,yoffset,50,15);
                ctx.stroke();
        
                var frags=video_dbg.bufferedFragmentCount;
                if (frags>20) frags=20;
                frags/=20.0;

                if (frags<0.3) ctx.fillStyle='#FF0000';
                else if (frags<0.5) ctx.fillStyle='#FFFF00';
                else ctx.fillStyle='#00FF00';
                frags*=42.0;
        
                ctx.fillRect(xoffset+54,yoffset+4,frags,7);

                //////////////// Bandwidth
                xoffset=0;
                yoffset+=20;

                ctx.fillStyle='#E0E0E0';
                ctx.fillText("Bw", xoffset+5, yoffset+12);
        
                var vidbw=video_dbg.currentVideoBandwidth;
                var sysbw=video_dbg.estimatedDownstream;
                var scale;

                vidbw/=sysbw;
                scale = Math.max(vidbw,1.0);
                vidbw/=scale;
                sysbw=1.0/scale;

                ctx.fillStyle='#00FF00';

                this.deviceInfo[i].speedVisible=true;
                if (vidbw>0.8)
                {
                    ctx.strokeStyle = '#FF0000';
                    this.deviceInfo[i].speedOK=false;
                }
                else
                {
                    ctx.strokeStyle = '#FFFFFF';
                    this.deviceInfo[i].speedOK=true;
                }

                sysbw*=42.0;
                vidbw*=42.0;


                ctx.beginPath();
                ctx.rect(xoffset+50,yoffset,50,15);
                ctx.stroke();

                ctx.fillStyle = '#00FF00';
                ctx.fillRect(xoffset+54,yoffset+4,sysbw,7);


                ctx.strokeStyle = '#FF0000';
                ctx.beginPath();
                ctx.moveTo(xoffset+54+vidbw,yoffset+4);
                ctx.lineTo(xoffset+54+vidbw,yoffset+4+7);
                ctx.moveTo(xoffset+54+vidbw+1,yoffset+4);
                ctx.lineTo(xoffset+54+vidbw+1,yoffset+4+7);
                ctx.moveTo(xoffset+54+vidbw-1,yoffset+4);
                ctx.lineTo(xoffset+54+vidbw-1,yoffset+4+7);
                ctx.stroke();


                //////////////// Video resolution Info
                xoffset=110;
                yoffset-=40;

                if ((video_dbg.videoInfo!==undefined)&&(video_dbg.videoInfo!==null))
                {
                    ctx.fillStyle='#E0E0E0';
                    ctx.fillText(video_dbg.videoInfo.width+"x"+video_dbg.videoInfo.height+"@"+video_dbg.videoInfo.fps,xoffset+5, yoffset+12);
                }

                //////////////// Video info
                xoffset=110;
                yoffset+=20;


                ctx.fillStyle='#E0E0E0';
                if (video_dbg.currentFragment==="idleLoop1")
                    ctx.fillText("Vid: idle1",xoffset+5, yoffset+10);
                else if (video_dbg.currentFragment==="idleLoop2")
                    ctx.fillText("Vid: idle1",xoffset+5, yoffset+10);
                else
                {
                    var time = video_dbg.timestampInVideo;
                    var secs = (time % 60);
                    var mins = (time-secs)/60;
                    secs=Math.floor(secs);
                    var secString = (secs<10)?"0"+secs:secs;
                    ctx.fillText("Vid: "+mins+":"+secString,xoffset+5, yoffset+12);
                }
            }
        }
    }

    render(){
        const lineColor = getComputedStyle(document.documentElement).getPropertyValue('--color-supportelements-active');

        var imageSize = this.props.imageSize+20 + 'px ';
        var imageSizeWithGap = (this.props.imageSize + 15) + 'px ';
        var gridColumDescriptor = imageSize + 'minmax(100px, 1fr) '+ (this.state.debug===true?'minmax(100px,2fr)':'') + imageSizeWithGap;
        
        return (
            <div style={{width: "100%", display: 'grid', gridGap: '0px 0px', gridTemplateColumns: gridColumDescriptor}} onClick={this.divOnClickBind}>
                <div className="label-onbackground" 
                    style={{textAlign: 'left', marginTop: '8px', borderBottom: '1px solid ' + lineColor}}>{config.copy.controls.devices.function}</div>
                <div className="label-onbackground" 
                    style={{textAlign: 'left', paddingLeft: '15px', marginTop: '8px',  borderBottom: '1px solid ' + lineColor}}>{config.copy.controls.devices.devicename}</div>
                {(this.state.debug===true)?<div className="label-onbackground" 
                    style={{textAlign: 'left', paddingLeft: '15px', marginTop: '8px',  borderBottom: '1px solid ' + lineColor}}>{config.copy.controls.devices.debug}</div>:null}
                <div className="label-onbackground" 
                    style={{gridColumn: (this.state.debug===true)?'4 / 5':'3 / 4', textAlign: 'left', marginTop: '8px', borderBottom: '1px solid ' + lineColor}}>{config.copy.controls.devices.connection}</div>
                {this.props.deviceList.map((device, index) => [
                
                ((device.vid===true)&&(device.mic===false)&&(device.ctrl===false))?
                    <img 
                        className={device.vid ? "filter-mediumgrey" : "filter-supportelement"}
                        src={VideoIcon} 
                        key={'imgvid' + index}
                        alt=""
                        height={this.props.imageSize}
                        draggable="false"
                        style={{}}/>:
                    (((device.vid===false)&&(device.mic===true)&&(device.ctrl===false))?
                    <img 
                        className={device.mic ? "filter-mediumgrey" : "filter-supportelement"}
                        src={MicIcon} 
                        key={'imgmic' + index}
                        alt=""
                        height={this.props.imageSize}
                        draggable="false"
                        style={{}}/>:
                    (((device.vid===false)&&(device.mic===false)&&(device.ctrl===true))?
                    <img 
                        className={device.ctrl ? "filter-mediumgrey" : "filter-supportelement"}
                        src={ControlsIcon} 
                        key={'imgctrl' + index}
                        alt=""
                        height={this.props.imageSize}
                        draggable="false"
                        style={{}}
                    />:
                    (!((device.vid===false)&&(device.mic===false)&&(device.ctrl===false))?
                    <img 
                        className={device.ctrl ? "filter-mediumgrey" : "filter-supportelement"}
                        src={IntegratedSessionIcon} 
                        key={'imgctrl' + index}
                        alt=""
                        height={this.props.imageSize}
                        draggable="false"
                        style={{}}
                    />:
                    <img key={'imgctrl' + index}/>))),
/*                <img 
                    className={device.mic ? "filter-mediumgrey" : "filter-supportelement"}
                    src={MicIcon} 
                    key={'imgmic' + index}
                    alt=""
                    height={this.props.imageSize}
                    draggable="false"
                    style={{}}/>,
                <img 
                    className={device.vid ? "filter-mediumgrey" : "filter-supportelement"}
                    src={VideoIcon} 
                    key={'imgvid' + index}
                    alt=""
                    height={this.props.imageSize}
                    draggable="false"
                    style={{}}/>,
                <img 
                    className={device.ctrl ? "filter-mediumgrey" : "filter-supportelement"}
                    src={ControlsIcon} 
                    key={'imgctrl' + index}
                    alt=""
                    height={this.props.imageSize}
                    draggable="false"
                    style={{}}/>,
*/
                
                <div className="label-onbackground" key={'name' + index} style={{textAlign: 'left', marginLeft: '15px',marginTop: '8px',  borderBottom: '1px solid ' + lineColor}}>{device.name}</div>,
                <div className="label-onbackground" key={'debuginfo' + index} style={{textAlign: 'left', marginLeft: '15px',marginTop: '8px',  borderBottom: '1px solid ' + lineColor, display:(this.state.debug===true)?'flex':'none'}}><canvas width="230" height="60" style={{width:"100%", minwidth:"100%"}} ref={this.debugCanvasRef[index]}/></div>,
                (((this.deviceInfo[index].speedOK===false)&&(device.connected===true))?
                <img 
                    className={this.deviceInfo[index].speedOK===true ? "filter-mediumgrey" : "filter-alert"}
                    src={this.deviceInfo[index].speedOK===true ? FastIcon : SlowIcon}
                    key={'imgspeed' + index}
                    alt=""
                    height={this.props.imageSize}
                    ref={this.speedOKRef[index]}
                    draggable="false"
                    style={{marginLeft: '5px'}}/>:
                <img 
                    className={device.connected ? "filter-ok" : "filter-alert"}
                    src={device.connected ? ConnectedIcon : DisconnectedIcon}
                    key={'img' + index}
                    alt=""
                    height={this.props.imageSize}
                    draggable="false"
                    style={{marginLeft: '5px'}}/>),

                ]
                )}
            </div>
 
        );
    }
}