import { useEffect } from 'react';


import React from 'react';
import { Switch, Route , BrowserRouter} from 'react-router-dom' //npm install --save react-router-dom
import Helmet from "react-helmet"; /* this currently throws a warning UNSAFE_componentWillUnmount. Could be fixed using react-helmet-async, which is only available to react 16, not 17 */

import Home from './home.js';
import PageNotFound from './utils/pageNotFound.js';
import BrowserNotSupported from './utils/browserNotSupported.js';
import CreateSession from './sessions/createSession.js';
import ConfirmSession from './sessions/confirmSession.js';
import LoginPage from './sessions/loginPage.js';
import AppLoader from './sessions/appLoader.js';

import './App.css';
import globalConfig from './globalConfig.js'


function App(configparam) {

  //save global config
  globalConfig.config = configparam.config;

  const config = globalConfig.config;
  console.log('screen size: ' + [window.screen.height, window.screen.width, window.devicePixelRatio])
  var browserNotSupported = false;

  /* check user agent and redirect if browser is not supported */
  if (navigator.userAgent.search("Trident") >= 0) {
    browserNotSupported = true;
  }

  useEffect(() => {  
    /* dynamically load colorscheme, icons and manifest */
    
    require('./styles/' + config.colorscheme);
    
    //make all paths absolute to work in a blob
    var manifest = config.manifest;
    for (var i=0; i < manifest.icons.length; i++){
      manifest.icons[i].src = window.location.origin + '/' + manifest.icons[i].src;
    }
    manifest.start_url = window.location.origin;
  
    //get objectURL for manifest
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    //set manifest
    document.querySelector('#headID_manifest').setAttribute('href', manifestURL);


    var iconRef;
    var href;

    //update tile color and theme color
    iconRef = document.querySelector('#headID_mstilecolor');
    iconRef.setAttribute('content', config.manifest.ms_tile_color);
    iconRef = document.querySelector('#headID_themecolor');
    iconRef.setAttribute('content', config.manifest.theme_color);
    iconRef = document.querySelector('#headID_link_4');
    iconRef.setAttribute('color', config.manifest.theme_color);    
  });

  return (

    <BrowserRouter>

      <Helmet>
          <html lang={config.language} />
          <title>{config.manifest.name}</title>
          <meta name="description" content={config.description}/>
          <meta name="theme-color" content={config.manifest.theme_color}/>
          <meta name="background-color" content={config.manifest.background_color}/>
          <meta name="msapplication-TileColor" content={config.manifest.ms_tile_color} />
      </Helmet>
      
      {browserNotSupported ? <BrowserNotSupported/> :
      <div className="App">
        {config.application === "demo" ?
        /* Session management is disabled in demo mode*/
        
        <Switch> 
          <Route exact path='/' component={Home}/>
          <Route path='/app' component={AppLoader}/>
          <Route path='/*' component={PageNotFound}/>
        </Switch>
        : /* Full app */
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/session/login' component={LoginPage}/>
          <Route exact path='/session/login/:sessionID' component={LoginPage}/>
          <Route path='/session/create' component={CreateSession}/>
          <Route path='/session/confirm' component={ConfirmSession}/>
          <Route path='/app' component={AppLoader}/>
          <Route path='/*' component={PageNotFound}/>
        </Switch>
        }
      </div>
}

    </BrowserRouter>
  );
}

export default App;
