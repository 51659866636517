import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// Import styles
import './fonts.css';
import './index.css';
import './styles/colors.css';
import './styles/typography.css';
import './styles/layout.css';

// Import Components
import App from './App';

const root = document.getElementById('root');
const url = "/default/config.json"
const configPromise = fetch(url);
    configPromise.then((res) => res.json())
      .then(config => ReactDOM.render(<React.StrictMode><App config={config} /></React.StrictMode>, root) );

/*      
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
