import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import ModalPopup from '../utils/modalPopup.js';

import globalConfig from '../globalConfig.js'
var config = {};

export default class DeleteSessionModal extends React.Component {
    static defaultProps = {
        windowWidth: window.innerWidth,
        handleDeleteSession: undefined,
    };

    static propTypes = {
        windowWidth: PropTypes.number,
        handleDeleteSession: PropTypes.func,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            popupOpen: false,
        }
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDeleteSession = this.handleDeleteSession.bind(this);
    };

    handleOpen(){
        this.setState({popupOpen: true});
    }

    handleClose(){
        this.setState({popupOpen: false});
    }

    handleDeleteSession(){
        this.handleClose();
        this.props.handleDeleteSession();
    }

    render(){
        return (
            <ModalPopup 
                title = {config.copy.controls.session.deletesessionpopup.title}
                msg = {config.copy.controls.session.deletesessionpopup.msg}
                abortButtonText = {config.copy.controls.session.deletesessionpopup.cancel}
                actionButtonText = {config.copy.controls.session.deletesessionpopup.delete}
                open = {this.state.popupOpen}
                maxwidth = {this.props.windowWidth}
                handleSecondaryAction = {this.handleClose}
                handlePrimaryAction = {this.handleDeleteSession}/>


        );
    }
}