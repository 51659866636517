//           responseUrl = "https://stream.lrz.de/opencast-lediz/smil:engage-player_14942972-c291-4d7f-be3e-a14cfea2d0b0_presenter.smil/chunklist_w993208047_b1201984.m3u8" //long response (40sec)
//           responseUrl = "https://stream.lrz.de/opencast-lediz/smil:engage-player_f5128671-06e9-4150-a98f-eaaf142b6b5a_presenter.smil/chunklist_w1311366665_b2112295.m3u8"; //3D long response
//           responseUrl = "https://stream.lrz.de/opencast-lediz/smil:engage-player_cc988836-955c-4aae-bc08-f95fa4813c55_presenter.smil/chunklist_w258472984_b1191057.m3u8" //short response
//           responseUrl = "https://stream.lrz.de/opencast-lediz/smil:engage-player_1ce209ee-5352-45b4-8136-c60ac5d58896_presenter.smil/chunklist_w1953444785_b2036532.m3u8" //3D short response
//           responseUrl = "https://10.11.1.1/stream/KRAUSE_0020/playlist.m3u8"; // long response
//           responseUrl = "http://localhost:3000/stream/KRAUSE_0020/playlist.m3u8"; // long response
//           responseUrl = "http://10.11.1.1/stream/KRAUSE_0021/playlist.m3u8"; // long response
//           responseUrl = "https://10.11.1.1/stream/KRAUSE_0017/playlist.m3u8"; // long response
//           responseUrl = "http://localhost:3000/stream/KRAUSE_0021/playlist.m3u8"; // long response

import React from "react";

import m3u8_parser from './m3u8parser.js';
import PropTypes from 'prop-types'; //npm install --save prop-types
import { callbackify } from "util";
import PlayButtonOverlay from './playButtonOverlay.js';

const https = require('https');
//defines
const BUFFER_TARGET_TIME = 0.8; //seconds
const FRAGMENT_PRELOAD_COUNT = 20;

// debug options
const DEBUG_VIDEOSOURCE_CONSOLE_OUTPUT = false;

const VIDEOPLAYER_DEBUG_LOGGING = false;


export default class VideoPlayer extends React.Component {
    static defaultProps = {
        speedtestURL: "",
        updateMoveBoxDimensions: () => {},
        speedOverride: -1,
        qualityOverride: -1,
        videoUrlPrefix: "",
        videoUrlPostfix2D: "",
        videoUrlPostfix3D: "",
        videoWidthCrop: 1.0,
        videoCropOffset: 0.0,
        overrideWidth: -1,
        overrideHeight: -1,
    };

    static propTypes = {
        speedtestURL: PropTypes.string,
        updateMoveBoxDimensions: PropTypes.func,
        speedOverride: PropTypes.number,
        qualityOverride: PropTypes.number,
        videoUrlPrefix: PropTypes.string,
        videoUrlPostfix2D: PropTypes.string,
        videoUrlPostfix3D: PropTypes.string,
        videoWidthCrop: PropTypes.number,
        overrideWidth: PropTypes.number,
        overrideHeight: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.videoEventCallback = {
            UpdateEnd : null,
            TimeUpdate : null
        };

        this.state = {
            speedOverride : this.props.speedOverride,
            qualityOverride : this.props.qualityOverride,
            showPlayButtonOverlay: false,
        };


        this.video = React.createRef();
        this.handlePlayButtonOverlayClick = this.handlePlayButtonOverlayClick.bind(this);

        this.appendedVideoLength=0;
        this.qualityselect=0;
        this.currentIdleoReq=undefined;
        this.currentAnsweroReq=undefined;
        this.currentFPS=0;
        this.videoWidth = 1920;
        this.videoHeight = 1080;
        this.videoCropWidth = 1920;
        this.speedtestURL=this.props.speedtestURL;
        this.qualityList = [];
        this.qualitySwitchCounter=0;
        this.videoHangCount=0;
        this.downloadList = [];
        this.qualityLimit = 0;
        this.videoStalledCounter = 0;
        this.SpeedtestDownloadspeed=0;

//        this.decodeMode='segments';
        this.decodeMode='sequence';

        this.idleLoopActive = 2;
        this.idleLoop1 = {
            fragmentList : [],
            fragmentData : [],
            fragmentQualitySwitchList : [],
            currentPlayFragment : 0,
            loadedFragmentCount : 0,
            totalFragmentCount : 0,
            currentQuality : 0,
            name : "idleLoop1",
            fileURL : "",
            downloadAbort : undefined,
            contentID : 0,
            downloadThread : undefined,
            elementsLoading : 0,
            fileID : "",
        };
        this.idleLoop2 = {
            fragmentList : [],
            fragmentData : [],
            fragmentQualitySwitchList : [],
            currentPlayFragment : 0,
            loadedFragmentCount : 0,
            totalFragmentCount : 0,
            currentQuality : 0,
            name : "idleLoop2",
            fileURL : "",
            downloadAbort : undefined,
            contentID : 0,
            downloadThread : undefined,
            elementsLoading : 0,
            fileID : "",
        };

        this.answerVideo = {
            fragmentList : [],
            fragmentData : [],
            fragmentQualitySwitchList : [],
            currentPlayFragment : 0,
            loadedFragmentCount : 0,
            totalFragmentCount : 0,
            playFromTimestamp : 0,
            playBufferOffset : 0,
            currentQuality : 0,
            nextIdleLoop : "",
            nextIdleLoopNr : 0,
            name : "AnswerBuffer",
            downloadAbort : undefined,
            contentID : 0,
            downloadThread : undefined,
            elementsLoading : 0,
            fileID : "",
        };
        this.newHeaderRequested = false;
        this.elementsLoading = 0;
        this.wasPlayingVideo = false;
        this.videoState = {
            currentIdleLoop : 1,
            transcodeRunning : false,
            transcodeStartTime : 0,
            currentList : null,
            playingVideo : false,
            downloadspeed : 0,
            is3D : false,
        };

        this.playerInfo = {
            inIdleLoop: true,
            timestampInVideo: 0,
            estimatedDownstream: 0,
            bufferedFragmentCount: 0,
            currentFragment: undefined,
            transcodeTime: 0,
            currentVideoBandwidth: 0,
            videoInfo : undefined,
            performanceInfoAvailable : false,
        }

        // Check if we are running on Safari for MacOS. Use video workaround
        if ((navigator.userAgent.search("Mac OS X")>-1)&&
            (navigator.userAgent.search("Chrome")<=-1)&&
            (navigator.userAgent.search("Safari")>-1))
        {
            console.log("Safari workarounds enabled")
            this.playInitVideoFirst=false;
            this.isSafari=true;
        }
        else
        {
            this.playInitVideoFirst=false;
            this.isSafari=false;
        }
    }
    

    componentDidMount() {
        console.log('component did mount with id: ' + this.props.id)

        if (this.props.id==="player_3d")
            this.videoState.is3D=true;
        else
            this.videoState.is3D=false;

        this.tsWorker = new Worker('/workers/tsRemuxer.mjs');
        this.tsWorkerResult = this.videoresult.bind(this);
        this.tsWorker.addEventListener('message', this.tsWorkerResult);

        this.mediaSource = new MediaSource();
        this.video.current.src = URL.createObjectURL(this.mediaSource);
        this.videoEventCallback.handleSourceOpen = this.handleSourceOpenFunc.bind(this);
        this.mediaSource.addEventListener('sourceopen', this.videoEventCallback.handleSourceOpen);
    };

    componentWillUnmount(){
        const video = this.video.current;
        console.log('component will unmount with id: ' + this.props.id)

        if (this.mediaSource){
            this.mediaSource.removeEventListener('sourceopen', this.videoEventCallback.handleSourceOpen);
        }
        if (this.videoSourceBuffer){
            this.videoSourceBuffer.removeEventListener('updateend', this.videoEventCallback.UpdateEnd);
        }
        if (video){
            video.removeEventListener('timeupdate', this.videoEventCallback.TimeUpdate);
            video.removeEventListener('stalled', this.videoEventCallback.Stalled);
            video.removeEventListener('waiting', this.videoEventCallback.Waiting);
            video.removeEventListener('suspend', this.videoEventCallback.Suspend);
            video.removeEventListener('empty', this.videoEventCallback.Empty);
            video.removeEventListener('error', this.videoEventCallback.Error);
        }
        if (this.tsWorker){
            this.tsWorker.removeEventListener('message', this.tsWorkerResult);
        }
        delete this.tsWorker;
    }

    handleSourceOpenFunc() {
        if (DEBUG_VIDEOSOURCE_CONSOLE_OUTPUT){
            console.log('media source open');
        }

        this.mimeCodec = 'video/mp4; codecs="avc1.64002a, mp4a.40.2"';
        this.videoSourceBuffer = this.mediaSource.addSourceBuffer(this.mimeCodec);
//        this.videoSourceBuffer.mode='sequence';
        this.videoSourceBuffer.mode=this.decodeMode;
        //append pending fragments in FIFO to videoSourceBuffer

        this.video.current.muted = false;
        this.videoEventCallback.UpdateEnd = this.videoElementCallbackFunc.bind(this,'updateend');
        this.videoEventCallback.TimeUpdate = this.videoElementCallbackFunc.bind(this,'timeupdate');
        this.videoEventCallback.Stalled = this.videoElementCallbackFunc.bind(this,'stalled');
        this.videoEventCallback.Waiting = this.videoElementCallbackFunc.bind(this,'waiting');
        this.videoEventCallback.Suspend = this.videoElementCallbackFunc.bind(this,'suspend');
        this.videoEventCallback.Empty   = this.videoElementCallbackFunc.bind(this,'empty');
        this.videoEventCallback.Error   = this.videoElementCallbackFunc.bind(this,'error');
        this.videoEventCallback.Timer   = this.videoElementCallbackFunc.bind(this,"timer");

        this.videoSourceBuffer.addEventListener('updateend', this.videoEventCallback.UpdateEnd);
        this.video.current.addEventListener('timeupdate', this.videoEventCallback.TimeUpdate);
        this.video.current.addEventListener('stalled', this.videoEventCallback.Stalled);
        this.video.current.addEventListener('waiting', this.videoEventCallback.Waiting);
        this.video.current.addEventListener('suspend', this.videoEventCallback.Suspend);
        this.video.current.addEventListener('empty', this.videoEventCallback.Empty);
        this.video.current.addEventListener('error', this.videoEventCallback.Error);

        window.setInterval(this.videoEventCallback.Timer, 250);

        this.speedtest(1);
    }

    speedtest(filesize)
    {
        this.SpeedtestoReq = new XMLHttpRequest();
        var sizename;
        var safariadd = "";
        if (this.isSafari===true)
        {
            safariadd = "?"+(Math.random()*0xFFFFFFFF).toString(16);
        }
        switch(filesize)
        {
            case 1:
                sizename="_1M"+safariadd;
            break;
            case 2:
                sizename="_10M"+safariadd;
            break;
            case 3:
                sizename="_100M"+safariadd;
            break;
            default: break;
        }

        if (this.state.speedOverride>0) 
        {
            // Skip download test if speedOverride is set
            this.videoState.downloadspeed=this.state.speedOverride;
            return;
        }

        var starttime = (new Date()).getTime();
        if (filesize>1) this.SpeedtestTimeout=setTimeout(this.speedtest_end.bind(this,starttime,filesize,true,""),4000);

        this.SpeedtestoReq.open("GET", this.speedtestURL+sizename, true);
        this.SpeedtestoReq.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
        this.SpeedtestoReq.responseType = "arraybuffer"; 
        
        this.SpeedtestoReq.onload = this.speedtest_end.bind(this,starttime,filesize,false,this.speedtestURL+sizename);
        this.SpeedtestoReq.send();

    }

    speedtest_end(starttime, filesize, onerror, fileurl)
    {
        var stoptime = (new Date()).getTime();

        var filesizebytes;
        clearTimeout(this.SpeedtestTimeout);

        if (onerror===false)
        {
            var perflist = performance.getEntriesByType('resource').filter(item => item.name.includes(fileurl));
            var perfnow = performance.now();
            var i;
            var perfentry=-1;
            for (i=0; i<perflist.length; i++)
            {
                if ((perfnow-perflist[i].responseEnd)<500)
                {
                    perfentry=i;
                } 
            }
            if (perfentry>=0)
            {
                if (perflist[perfentry].responseStart>0)
                {
                    starttime=perflist[perfentry].responseStart;
                    stoptime=perflist[perfentry].responseEnd;
                }
            }

            switch(filesize)
            {
                case 1: filesizebytes=1*1024*1024; break;
                case 2: filesizebytes=10*1024*1024; break;
                case 3: filesizebytes=100*1024*1024; break;
                default: break;
            }

            this.SpeedtestDownloadspeed = (filesizebytes / ((stoptime-starttime)/1000));

            if (((stoptime-starttime)<500)&&(filesize<3))
            {
                this.speedtest(filesize+1);
                return;
            }
        }
        else
        {
            this.SpeedtestoReq.abort();
        }

        this.videoState.downloadspeed=this.SpeedtestDownloadspeed;
        if (this.state.speedOverride>0) this.videoState.downloadspeed=this.state.speedOverride;
        console.log("Initial download speed check: "+(this.SpeedtestDownloadspeed/(1024.0*1024.0)).toFixed(3)+" MByte/sec");
    }

    getNextVideoEntry(justCheck = false)
    {
        var videodata = [];
        var switchedToAnswer = false;
        videodata.newHeader=false;
        var dataoffset;

        var videolist;

        if (this.videoState.transcodeRunning===true) return false; 
        if (this.videoState.playingVideo===true)
        {
            videolist = this.answerVideo;
            this.wasPlayingVideo=true;
        }
        else
        {
            if ((this.videoState.requestAnswer===true)&&(this.answerVideo.loadedFragmentCount>(this.answerVideo.currentPlayFragment+1)))
            {
                videolist = this.answerVideo;
                this.videoState.playingVideo=true;
                this.videoState.requestAnswer=false;
                switchedToAnswer=true;
                this.newHeaderRequested=true;
                this.videoState.currentIdleLoop=this.answerVideo.nextIdleLoopNr;
                this.wasPlayingVideo=true;
            }
            else
            {
                if (this.videoState.currentIdleLoop===1)
                    videolist = this.idleLoop1;
                else
                    videolist = this.idleLoop2;
            }
        }

        if (videolist.loadedFragmentCount===videolist.totalFragmentCount)
        {
            if (videolist.name==="AnswerBuffer")
            {
                this.playerInfo.bufferedFragmentCount=videolist.loadedFragmentCount-videolist.currentPlayFragment
                if (videolist.nextIdleLoopNr===1)
                {
                    if (this.idleLoop1.loadedFragmentCount===this.idleLoop1.totalFragmentCount) 
                        this.playerInfo.bufferedFragmentCount=FRAGMENT_PRELOAD_COUNT;
                    else
                        this.playerInfo.bufferedFragmentCount+=this.idleLoop1.loadedFragmentCount-this.idleLoop1.currentPlayFragment;
                }
                else
                {
                    if (this.idleLoop2.loadedFragmentCount===this.idleLoop2.totalFragmentCount) 
                        this.playerInfo.bufferedFragmentCount=FRAGMENT_PRELOAD_COUNT;
                    else
                        this.playerInfo.bufferedFragmentCount+=this.idleLoop2.loadedFragmentCount-this.idleLoop2.currentPlayFragment;
                }
            }
            else
            {
                // Idle loop -- loop fully loaded => buffer is always full
                this.playerInfo.bufferedFragmentCount=FRAGMENT_PRELOAD_COUNT;
            }
        }
        else
            this.playerInfo.bufferedFragmentCount=videolist.loadedFragmentCount-videolist.currentPlayFragment;
//        this.playerInfo.estimatedDownstream=this.videoState.downloadspeed;
        if (videolist===this.answerVideo) 
        {
//            dataoffset=this.answerVideo.playBufferOffset;
            dataoffset=0;
            this.playerInfo.inIdleLoop=false;
        }
        else   
        {
            dataoffset=0;
            if (this.playerInfo.inIdleLoop===false) this.newHeaderRequested=true;
            this.playerInfo.inIdleLoop=true;
        }
        this.playerInfo.currentFragment = videolist.name;

        if (justCheck===true) return;

        if (VIDEOPLAYER_DEBUG_LOGGING===true) console.log("Playing fragment #"+videolist.currentPlayFragment+ " of " + videolist.totalFragmentCount + " ("+videolist.loadedFragmentCount+" loaded) from List " + videolist.name);
        if (videolist.currentPlayFragment>=videolist.loadedFragmentCount)
        {
            return false; // No data available yet
        }

        if (videolist.fragmentList[0].fragments[videolist.currentPlayFragment].loadstate!==2)
        {
            return false; // Fragment not yet loaded
        }

        if ((this.wasPlayingVideo===true)&&(this.videoState.playingVideo===false))
        {
            this.newHeaderRequested=true;
            this.wasPlayingVideo=false;
        }

        if (this.videoFragmentQuality!==videolist.fragmentList[0].fragments[videolist.currentPlayFragment].quality)
        {
            this.newHeaderRequested=true;
        }
        this.videoFragmentQuality=videolist.fragmentList[0].fragments[videolist.currentPlayFragment].quality;


        if ((videolist.currentPlayFragment===0)||(switchedToAnswer===true)||(this.newHeaderRequested===true))
        {
            // New video -- create new header
            this.newHeaderRequested=false;
            videodata.newHeader=true;
        }


        this.videoState.transcodeRunning=true;
        this.videoState.transcodeStartTime=(new Date()).getTime();

        var timepos;
        var i;
        timepos=0;
        for (i=0; i<videolist.currentPlayFragment; i++)
        {
            timepos+=videolist.fragmentList[this.qualityselect].fragments[i].length;
        }
        this.playerInfo.timestampInVideo=timepos;

        if (videolist.fragmentQualitySwitchList[videolist.currentPlayFragment-dataoffset]===true) videodata.newHeader=true;

        videodata.resetPTS=false;
        videodata.mpeg2=videolist.fragmentData[videolist.currentPlayFragment-dataoffset];
        this.videoState.currentList=videolist;

        this.tsWorker.postMessage(videodata);
        return true;
    }


    videoresult(vidmessage)
    {
        var videolist = this.videoState.currentList;

        this.videoState.transcodeRunning=false;
        var transcodeStopTime=(new Date()).getTime();
        var transcodeTime = transcodeStopTime - this.videoState.transcodeStartTime;
        var vidfragment = vidmessage.data;

        this.playerInfo.transcodeTime = Math.round((this.playerInfo.transcodeTime*0.9)+(transcodeTime*0.1))
        this.playerInfo.videoInfo = { fps: vidfragment.fps, width: vidfragment.width, height: vidfragment.height};

        // New FPS, or if width/height changed and browser is Safari.
        // Chrome on MacOS has Safari in userAgent, so check for chrome specifically
        if ( (this.currentFPS!==vidfragment.fps)||
             (((this.videoWidth!==vidfragment.width)||(this.videoHeight!==vidfragment.height))&&
             (navigator.userAgent.search("Safari")>-1)&&(navigator.userAgent.search("Chrome")<=-1)&&(false))
           )
        {
            console.log("Video restart due to FPS change or other conditions");
            this.videoWidth=vidfragment.width;
            this.videoCropWidth=this.videoWidth-((1.0-this.props.videoWidthCrop)*this.videoWidth);
            this.videoHeight=vidfragment.height;

            //callback to resize movebox
            this.props.updateMoveBoxDimensions();

            if (this.currentFPS!==0)
            {
                this.restartVideo();
                this.currentFPS=vidfragment.fps;
                return;
            }
            else
                this.currentFPS=vidfragment.fps;
        }
        this.videoWidth=vidfragment.width;
        this.videoCropWidth=this.videoWidth-((1.0-this.props.videoWidthCrop)*this.videoWidth);
        this.videoHeight=vidfragment.height;

//        console.log("Append video buffer");
        if (this.videoSourceBuffer===null) return; // Retry later - videoSourceBuffer not ready

        try
        {
            this.videoSourceBuffer.appendBuffer(vidfragment.data);
        }
        catch (e)
        {
            console.log("VideoSourceBuffer.appendBuffer error");
            console.log(e);
        }
        this.appendedVideoLength=vidfragment.playlength/1000.0;

        videolist.currentPlayFragment++;

        if (videolist.currentPlayFragment>=videolist.totalFragmentCount)
        {
            videolist.currentPlayFragment=0;
//            this.tsRemuxer.videoFirstPacket=0;
            var videodata = [];
            videodata.mpeg2=null;
            videodata.newHeader=true;
            videodata.resetPTS=false;
            this.tsWorker.postMessage(videodata);

            if (this.videoState.playingVideo===true)
            {
                this.videoState.playingVideo=false;
            }
        }
    }

    restartVideo()
    {
        var video = this.video.current;
        if (video === undefined || video === null) return;

        console.log("RestartVideo");
        if (this.mediaSource) {
            this.mediaSource.removeEventListener('sourceopen', this.videoEventCallback.handleSourceOpen);
        }
        this.mediaSource = new MediaSource();
        video.src = URL.createObjectURL(this.mediaSource);
        video.currentTime=0;
        this.videoSourceBuffer=null;
        this.appendedVideoLength=0;
        console.log(video.buffered);
    }

    videoElementCallbackFunc(cbType)
    {
        var video = this.video.current;
        if (video===undefined) return;
        if (video===null) return;

        if (cbType==="error")
        {
            console.log("Video error");
            this.restartVideo();
            return;
        }

        if (this.videoSourceBuffer===null)
        {
            if (this.mediaSource.readyState==="open") 
            {
                this.videoSourceBuffer = this.mediaSource.addSourceBuffer(this.mimeCodec);
                this.videoSourceBuffer.mode=this.decodeMode;
                this.videoSourceBuffer.addEventListener('updateend', this.videoEventCallback.UpdateEnd);
                var videodata = [];
                videodata.mpeg2=null;
                videodata.resetPTS=true;
                videodata.newHeader=true;
                this.tsWorker.postMessage(videodata);
                console.log("Reset Video Buffer");
                console.log(video.buffered);
            }
//            return;
        }

        if (cbType==="timer")
        {
            if (this.videoSourceBuffer===null) return;

            if ((this.videoSourceBuffer.updating===false)&&(this.videoSourceBuffer.buffered.length<=0))
            {
                this.getNextVideoEntry();
            }

            var remaining_buffered_playtime;
            if (video.buffered.length>0)
                remaining_buffered_playtime = video.buffered.end(0) - video.currentTime;
            else
                remaining_buffered_playtime=0;

            if ( ((remaining_buffered_playtime+this.appendedVideoLength)<BUFFER_TARGET_TIME) && (this.videoSourceBuffer.updating===false) )
            {
                this.getNextVideoEntry();
            }

//            console.log(video);
//            console.log(this.playerInfo.bufferedFragmentCount);
            if (this.playerInfo.bufferedFragmentCount<4)
            {
                // Not enough fragments buffered -- ignore hang checks
                this.getNextVideoEntry(true);
                return;
            }

//            console.log("Timer: " + this.lastPlayTime + " -- "+video.currentTime + " -- " + this.videoSourceBuffer.buffered.length);
            if ((this.lastPlayTime===video.currentTime)&&(this.videoSourceBuffer.buffered.length>=1))
            {
                this.videoHangCount++;
                if (this.videoHangCount>5)
                {
                    console.log("Video automatic restart");
                    video.pause();
                    this.videoHangCount=0;
                }
            }
            else
                this.videoHangCount=0;

            this.lastPlayTime=video.currentTime;

            if (video.paused===true)
            {
                console.log("Video was paused -- start again");
                video.play().then(_ => {
                    console.log("Play resume OK");
                })
                .catch(error => {
                    console.log("Play resume Error");
                    console.log(error);
                    console.log(error.name);
                    if (error.name === 'NotAllowedError') {
                        //TODO: show play button overlay
                        console.log('require user interaction')
                        this.setState({showPlayButtonOverlay: true})
                    }
                    if (this.isSafari===false) 
                    {
                        this.restartVideo();
                    }
                    else
                    {
                        video.pause();
                    }
                
                });
            }
        }
        if (cbType==="waiting")
        {
            var remaining;
            
            if (video.buffered.length>0) remaining = video.buffered.end(0) - video.currentTime;
            console.log("HTMLVideo event called: " +cbType + " Remaining: "+remaining);

            if (this.playerInfo.bufferedFragmentCount<4)
            {
                // Buffered less than 2 seconds -- wait
                console.log("Waiting for buffer");
            }
            else
            {
                console.log("Waiting for decoder -- CPU/GPU too slow?");
                this.videoStalledCounter++;
                if (this.videoStalledCounter>5)
                {
                    this.videoStalledCounter=0;
                    if (this.qualityLimit<2) this.qualityLimit++;
                }

                if (video.buffered.length>0)
                {
                    console.log(video.buffered.end(0));
                    console.log(video.currentTime);
                    console.log(remaining);
//                    video.currentTime=((Math.floor(video.currentTime*5.0))/5.0)-0.05;
//                    video.currentTime=((Math.floor(video.currentTime*25.0))/25.0)-0.05;
                }
            }
//            console.log(this.videoSourceBuffer);
        }
        if (cbType==="timeupdate")
        {
//            console.log("HTMLVideo event called: " +cbType);
            if (video.buffered.length===0) return;
            var remaining_buffered_playtime = video.buffered.end(0) - video.currentTime;
            //debug
            if (DEBUG_VIDEOSOURCE_CONSOLE_OUTPUT){
//                console.log('remaining buffered playtime ' + remaining_buffered_playtime);
            }
            if ( ((remaining_buffered_playtime+this.appendedVideoLength)<BUFFER_TARGET_TIME) && (this.videoSourceBuffer.updating===false) )
            {
                this.getNextVideoEntry();
            }
        }
        if (cbType==="updateend")
        {
//            console.log("HTMLVideo event called: " +cbType);
            this.appendedVideoLength=0;
        }
        if (cbType==="stalled")
        {
            console.log("Video stalled");
        }
        if (cbType==="suspend")
        {
            console.log("Video suspend");
        }

    }

    handlePlayButtonOverlayClick(){
        if (this.video.current){
            this.video.current.play();
        }
        this.setState({showPlayButtonOverlay: false})
    }


    checkQualitySelection(fragment)
    {
        
        var fraginfo;
        var newqualityselect;
        var j;
        var fragmentList = fragment.fragmentList;

        if ((this.videoState.downloadspeed<1000)&&(this.state.qualityOverride===-1)) return -1; // No quality selection without valid download speed and no forced quality

//        console.log("QS: "+this.qualityselect+ " -- DS: "+ this.videoState.downloadspeed);

        if (this.state.qualityOverride!==-1) newqualityselect=this.state.qualityOverride; else newqualityselect=100;

        if (this.state.qualityOverride===-1)
        {
            for (var i=0; i<fragmentList.length; i++)
            {
                fraginfo=fragmentList[i];
                if ((newqualityselect>i)&&((fraginfo.bandwidth/8)<(this.videoState.downloadspeed*0.9)))
                {
//                    console.log("Quality index "+i+" ("+(fraginfo.bandwidth/(1024.0*1024.0)).toFixed(3)+" MBit/sec): Bandwidth OK - Checking screen resolution");

                    for (var j=fragmentList.length-1; j>=i; j--)
                    {
                        fraginfo=fragmentList[j];
                        var checkwidth = (this.props.overrideWidth>0)?this.props.overrideWidth:window.screen.width;
                        var checkheight = (this.props.overrideHeight>0)?this.props.overrideHeight:window.screen.height;
                        if ((fraginfo.resolution[0]>=(checkwidth*window.devicePixelRatio))&&
                            (fraginfo.resolution[1]>=(checkheight*window.devicePixelRatio))
                        )
                        {
                            i=j;
                            break;
                        }

                    }
                    newqualityselect=i;
                    if (newqualityselect<this.qualityselect)
                    {
                        this.qualitySwitchCounter++;
                        if (this.qualitySwitchCounter<30)
                            newqualityselect=this.qualityselect;
                        else   
                            this.qualitySwitchCounter=0;
                    }
                    else
                        this.qualitySwitchCounter=0;

                    if (newqualityselect<this.qualityLimit) newqualityselect=this.qualityLimit;
                    if (this.qualityselect!==newqualityselect) console.log("Selected Quality index "+i+" => "+(fraginfo.bandwidth/(1024.0*1024.0)).toFixed(3)+" MBit/sec Video bandwidth. Video Resolution: "+fraginfo.resolution);
                    break;
                } 
            }
        }
        else
        {
            i=newqualityselect;
            fraginfo=fragmentList[i];
            if (this.qualityselect!==newqualityselect) console.log("Override Quality index "+i+" => "+(fraginfo.bandwidth/(1024.0*1024.0)).toFixed(3)+" MBit/sec Video bandwidth. Video Resolution: "+fraginfo.resolution);
        }
        if (newqualityselect===100) newqualityselect=fragmentList.length-1;
        i=newqualityselect;
//        console.log("Current Quality index "+i+" => "+(fraginfo.bandwidth/(1024.0*1024.0)).toFixed(3)+" MBit/sec Video bandwidth. Video Resolution: "+fraginfo.resolution+ " Downstream: "+(this.videoState.downloadspeed/(1024*1024)).toFixed(2));
        
        this.qualityselect=newqualityselect;
        
        return 0;
    }

    downloadBaseM3U(url, isIdleLoop)
    {
        var parser = new m3u8_parser();
        var baseUrl = url.substring(0,url.lastIndexOf('/')+1);
        
        var fragment;
        if (isIdleLoop===1)
            fragment = this.idleLoop1;
        else if (isIdleLoop===2)
            fragment = this.idleLoop2;
        else
            fragment = this.answerVideo;

        delete fragment.fragmentList;
        delete fragment.fragmentData;
        fragment.fragmentList=[];
        fragment.fragmentData=[];
        fragment.fragmentQualitySwitchList=[];
        fragment.currentQuality=-1;
        fragment.currentPlayFragment=0;
        fragment.loadedFragmentCount=0;
        fragment.totalFragmentCount=0;
        
        if (fragment.downloadAbort!==undefined)
        {
            fragment.downloadAbort.abort();
        }
        fragment.downloadAbort = new AbortController();
        if (fragment.downloadThread!==undefined)
        {
            clearInterval(fragment.downloadThread);
            fragment.downloadThread=undefined;
        }
        fragment.contentID++;

        if (isIdleLoop===0)
        {
            this.videoState.playingVideo=false; // Make sure we are playing an idle loop if we need to load new answer video
            this.videoState.requestAnswer=true;
        }

        var urlMod = function(basePath, item) {
            item.url=basePath+item.url;
        }

        var parseM3U = function(isIdleLoop, m3u) {
            var fragment;

//            console.log("M3U: ");
//            console.log(m3u);

            if (isIdleLoop===1)
                fragment = this.idleLoop1;
            else if (isIdleLoop===2)
                fragment = this.idleLoop2;
            else
                fragment = this.answerVideo;

            var fragmentList = fragment.fragmentList;

            if (m3u.segments.length===0)
            {
                console.log("No Entries found in Base M3U8. Aborting");
                return;
            }

            if (this.state.speedOverride>0) this.videoState.downloadspeed=this.state.speedOverride;

            fragment.loadedFragmentCount=0;
            fragment.elementsLoading=m3u.segments.length;


            this.qualityList=[];
            if (this.playInitVideoFirst===true)
            {
                if (isIdleLoop===1)
                {
                    this.idleLoop1.fileURL="";
                }
                else if (isIdleLoop===2)
                {
                    this.idleLoop2.fileURL="";
                }
                for (var i=0; i<m3u.segments.length; i++)
                {
                    if (m3u.segments[i].streamInf.resolution[0]<=640) break;
                }
                var seg=i;
                for (var i=0; i<m3u.segments.length; i++)
                {
                    if (m3u.segments[i].streamInf===undefined) return; // error reading file
                    var fraginfo = {
                        bandwidth : m3u.segments[seg].streamInf.bandwidth,
                        resolution : m3u.segments[seg].streamInf.resolution,
                        playTime : 0,
                        entrycnt : 0,
                        baseUrl : m3u.segments[seg].url,
                        fragments : [],
                    }
                    fragmentList.push(fraginfo);
                    this.qualityList.push(m3u.segments[i].streamInf.resolution[0]+"x"+m3u.segments[i].streamInf.resolution[1]+"@"+((m3u.segments[i].streamInf.bandwidth/(1024*1024)).toFixed(1))+" Mbit/s");
                }   
                this.playInitVideoFirst=false;
            }
            else
            {
                for (var i=0; i<m3u.segments.length; i++)
                {
                    if (m3u.segments[i].streamInf===undefined) return; // error reading file
                    var fraginfo = {
                        bandwidth : m3u.segments[i].streamInf.bandwidth,
                        resolution : m3u.segments[i].streamInf.resolution,
                        playTime : 0,
                        entrycnt : 0,
                        baseUrl : m3u.segments[i].url,
                        fragments : [],
                    }
                    fragmentList.push(fraginfo);
                    this.qualityList.push(m3u.segments[i].streamInf.resolution[0]+"x"+m3u.segments[i].streamInf.resolution[1]+"@"+((m3u.segments[i].streamInf.bandwidth/(1024*1024)).toFixed(1))+" Mbit/s");
                }    
            }

            this.checkQualitySelection(fragment);

            for (var i=0; i<fragmentList.length; i++)
            {
                this.downloadStreamM3U(fragmentList[i].baseUrl,isIdleLoop,i,fragment);
            }
            
        }
    
        parser.evt.on('item',urlMod.bind(this,baseUrl));
        parser.evt.on('m3u', parseM3U.bind(this, isIdleLoop));
        parser.readURL(url);
//        request(url).pipe(parser);
    }

    downloadStreamM3U(url, isIdleLoop, index, fragment)
    {
        var parser = new m3u8_parser();
        var baseUrl = url.substring(0,url.lastIndexOf('/')+1);
        
        var urlMod = function(basePath, item) {
            item.url=basePath+item.url;
//            item.set('uri', basePath + item.get('uri'));
        }

        var parseM3U = function(isIdleLoop, index, m3u) {
            var fragmentList;
            var fragment;
//            console.log("M3U-stream: ");
//            console.log(m3u);

            if (isIdleLoop===1)
                fragment = this.idleLoop1;
            else if (isIdleLoop===2)
                fragment = this.idleLoop2;
            else
                fragment = this.answerVideo;


            fragmentList = fragment.fragmentList[index];
            if (m3u.segments.length===0)
            {
                console.log("No Entries found in Stream M3U8. Aborting");
                return;
            }
            for (var i=0; i<m3u.segments.length; i++)
            {
                if (m3u.segments[i].inf===undefined) return; // error reading file
                var entry = {
                    url : "",
                    length : 0,
                    loadstate : 0,
                    quality : -1,
                };
//                console.log("Fragment "+i+": "+m3u.segments[i].inf.duration)
                if (m3u.segments[i].inf.duration>0)
                {
                    entry.url=m3u.segments[i].url;
                    entry.length=m3u.segments[i].inf.duration;
                    entry.loadstate=0;
                    fragmentList.entrycnt++;
                    fragmentList.playTime+=entry.length;
                    fragmentList.fragments.push(entry);
                }
            }
            fragment.elementsLoading--;
            if (fragment.elementsLoading===0)
            {
                fragment.fragmentData= Array.from(Array(fragment.fragmentList[0].entrycnt), _ => undefined);
                this.downloadM3UReady(isIdleLoop, this.qualityselect);
            }
        }
    
        parser.evt.on('item',urlMod.bind(this,baseUrl));
        parser.evt.on('m3u', parseM3U.bind(this, isIdleLoop, index));
        parser.readURL(url);
//        request(url).pipe(parser);

    }

    downloadM3UReady(isIdleLoop, index)
    {
//        console.log("Loading finished");

        var fragment;
        var fragment_time;
        var fragment_nr;
        if (isIdleLoop===1)
            fragment = this.idleLoop1;
        else if (isIdleLoop===2)
            fragment = this.idleLoop2;
        else
            fragment = this.answerVideo;
        var fragmentList = fragment.fragmentList[index];
        
        fragment.totalFragmentCount=fragmentList.entrycnt;
        fragment.loadedFragmentCount=0;
        if (isIdleLoop===0)
        {
            // Jump to selected timestamp
            fragment_time=0;
            for (fragment_nr=0; fragment_nr<fragment.totalFragmentCount; fragment_nr++)
            {
                if (fragment_time>=fragment.playFromTimestamp) break;
                fragment_time+=this.answerVideo.fragmentList[index].fragments[fragment_nr].length;
            }
            this.answerVideo.playBufferOffset=fragment_nr;
            fragment.loadedFragmentCount=fragment_nr;
            fragment.currentPlayFragment=fragment_nr;
        }

        console.log("Loading stream data in buffer #"+isIdleLoop);
        fragment.downloadThread=setInterval(this.fragmentProcessor.bind(this,fragment,fragment.contentID),100);
    }

    updateBandwidth()
    {
        var i;
        var latesttime;
        var latestpos;


        var now = (new Date()).getTime();
        while (true)
        {
            if (this.downloadList.length===0) break;

            if ((this.downloadList[0].endTime+10000)<now) // Entry old (older than 10 sec) -- remove it
                this.downloadList.shift();
            else
                break;
        }
        latesttime=0;
        latestpos=0;
        for (i=0; i<this.downloadList.length; i++)
        {
            if (this.downloadList[i].endTime>latesttime)
            {
                latesttime=this.downloadList[i].endTime;
                latestpos=i;
            }
        }

        var equivalentTime=0;
        var bandwidthsum=0;
        var bytepermsec = this.downloadList[latestpos].bytesPerMsec;
        var refstart = this.downloadList[latestpos].startTime;
        var refend = this.downloadList[latestpos].endTime;
        var concurrentDownloads=0;

        for (i=0; i<this.downloadList.length; i++)
        {
            if (this.downloadList[i].startTime>refend) continue;
            if (this.downloadList[i].startTime<=0) continue;

            if ((this.downloadList[i].endTime<refstart)&&(this.downloadList[i].endTime>0)) continue;

            concurrentDownloads++;
            if (this.downloadList[i].endTime<=0)
            {
                if (this.downloadList[i].startTime<=refstart)
                {
                    equivalentTime+=refend-refstart;
                    bandwidthsum+=(bytepermsec*1000);///(refend-refstart);
                }
                else
                {
                    equivalentTime+=refend-this.downloadList[i].startTime;
                    bandwidthsum+=(bytepermsec*1000)*((refend-this.downloadList[i].startTime)/(refend-refstart));
                }
            }
            else
            {
                if (this.downloadList[i].startTime<=refstart)
                {
                    equivalentTime+=this.downloadList[i].endTime-refstart;
                    bandwidthsum+=(this.downloadList[i].bytesPerMsec*1000)*((this.downloadList[i].endTime-refstart)/(refend-refstart));
                }
                else
                {
                    equivalentTime+=this.downloadList[i].endTime-this.downloadList[i].startTime;
                    bandwidthsum+=(this.downloadList[i].bytesPerMsec*1000)*((this.downloadList[i].endTime-this.downloadList[i].startTime)/(refend-refstart));
                }
            }
        }

        if (this.state.speedOverride>0) 
            this.videoState.downloadspeed=this.state.speedOverride;
        else
        {
            this.videoState.downloadspeed=(this.videoState.downloadspeed*0.8)+((bytepermsec*1000)*(equivalentTime/(refend-refstart))*0.2);
        }   
        this.playerInfo.estimatedDownstream=this.videoState.downloadspeed*8;

    }

    async fetchFragment(url, fragment, entrynr, currentContentID, entry)
    {
        const {signal}=fragment.downloadAbort;
        const timectrl = new AbortController();
        const {timeout}=timectrl;

        setTimeout(()=> timectrl.abort(), 2000);

        var resp;
        try {
            resp = await fetch(url, { signal, timeout, agent:new https.Agent({keepAlive: true}) });
            if (currentContentID!==fragment.contentID)
            {
                console.log("Ignored Fetch -- old contentID!");
                entry.startTime=-1;
            }
            else
            {
                var data = await resp.arrayBuffer();
//                console.log("Fetched "+url);
                fragment.fragmentList[0].fragments[entrynr].loadstate=2;
                fragment.fragmentData[entrynr]=new Uint8Array(data);
                entry.endTime=(new Date()).getTime();
                entry.bytesPerMsec=(fragment.fragmentData[entrynr].length)/(entry.endTime-entry.startTime);

                var perflist = performance.getEntriesByType('resource').filter(item => item.name.includes(url));
                var perfnow = performance.now();
                var i;
                var perfentry=-1;
                for (i=0; i<perflist.length; i++)
                {
                    if ((perfnow-perflist[i].responseEnd)<500)
                    {
                        perfentry=i;
                    } 
                }
                if (perfentry>=0)
                {
                    if (perflist[perfentry].responseStart>0)
                    {
                        entry.bytesPerMsec=(fragment.fragmentData[entrynr].length)/(perflist[perfentry].responseEnd-perflist[perfentry].responseStart);
                        entry.startTime=entry.endTime-(perflist[perfentry].responseEnd-perflist[perfentry].responseStart);
                        this.playerInfo.performanceInfoAvailable=true;
                    }
                    else
                    {
                        this.playerInfo.performanceInfoAvailable=false;
                    }
                }

                this.updateBandwidth();

            }
        } catch(err)
        {
            if (currentContentID!==fragment.contentID)
            {
                console.log("Fetch abort -- new content loading");
                entry.startTime=-1;
            }
            else
            {
                fragment.fragmentList[0].fragments[entrynr].loadstate=-1; // Fetch error/timeout - try again
                entry.startTime=-1;
                console.log("Fetch error");
                console.log(err);
            }
        }
    }

    getQualitySelector(fragment)
    {
        if (this.state.speedOverride>0) this.videoState.downloadspeed=this.state.speedOverride;
        var ret=this.checkQualitySelection(fragment);

        if (ret<0) return -1;
        if (fragment.fragmentList[this.qualityselect]===undefined) return -1;

        this.playerInfo.currentVideoBandwidth = fragment.fragmentList[this.qualityselect].bandwidth;

        return this.qualityselect;

    }

    fragmentProcessor(fragment, currentContentID)
    {
//        console.log("fragmentProcessor: ContentID="+currentContentID);
//        console.log(fragment);
        if (currentContentID!==fragment.contentID)
        {
            console.log("Terminating fragmentProcessor for "+fragment.name+", ID:"+currentContentID);
            clearInterval(fragment.downloadThread);
        }

        if (this.videoState.downloadspeed<1000) return; // Wait until speedtest is ready

        var fragscheck_end;
        var fragments_ahead;
        var i;

        fragscheck_end=fragment.currentPlayFragment+FRAGMENT_PRELOAD_COUNT;
        if (fragscheck_end>fragment.totalFragmentCount) fragscheck_end=fragment.totalFragmentCount;

        // Update loadedFragmentCount
        if (fragment.loadedFragmentCount<fragment.totalFragmentCount)
        {
            for (i=fragment.loadedFragmentCount; i<fragscheck_end; i++)
            {
//                if (fragment.fragmentList[0].fragments[i]===undefined) break;
                if (fragment.fragmentList[0].fragments[i].loadstate===2)
                {
                    fragment.loadedFragmentCount++;
                }
                else
                {
                    break;
                }
            }
        }

        var loadahead;
        fragments_ahead=fragment.loadedFragmentCount-fragment.currentPlayFragment;
        if (fragments_ahead<0) fragments_ahead=0;
        if (fragments_ahead>FRAGMENT_PRELOAD_COUNT) fragments_ahead=FRAGMENT_PRELOAD_COUNT;
        loadahead=(fragments_ahead*1.5)+3;
        if (loadahead>FRAGMENT_PRELOAD_COUNT) loadahead=FRAGMENT_PRELOAD_COUNT;        

        if ((this.playerInfo.inIdleLoop===false)||(this.videoState.requestAnswer===true))
        {
            if (fragment.name!=="AnswerBuffer")
            {
                // We are loading an idle loop -- limit download if answer is not fully loaded
                if ((this.answerVideo.loadedFragmentCount<this.answerVideo.totalFragmentCount)||
                    (this.answerVideo.totalFragmentCount===0))
                    {
                        loadahead=3;
                    }
                if ((this.answerVideo.nextIdleLoopNr===1)&&(fragment.name==="idleLoop2")) loadahead=3; // Limit load ahead for unused idle loop
                if ((this.answerVideo.nextIdleLoopNr===2)&&(fragment.name==="idleLoop1")) loadahead=3; // Limit load ahead for unused idle loop
            }
        }
        else
        {
            if ((this.videoState.currentIdleLoop===1)&&(fragment.name==="idleLoop2")) loadahead=3; // Limit load ahead for unused idle loop
            if ((this.videoState.currentIdleLoop===2)&&(fragment.name==="idleLoop1")) loadahead=3; // Limit load ahead for unused idle loop
        }

        fragscheck_end=fragment.currentPlayFragment+loadahead;
        if (fragscheck_end>fragment.totalFragmentCount) fragscheck_end=fragment.totalFragmentCount;
        
        if (fragment.loadedFragmentCount<fragment.totalFragmentCount)
        {
            for (i=fragment.loadedFragmentCount; i<=fragscheck_end; i++)
            {
                if (fragment.fragmentList[0].fragments[i]===undefined) continue;
                if (fragment.fragmentList[0].fragments[i].loadstate<=0)
                {
                    let quality = this.getQualitySelector(fragment);
                    if ((quality>=0)&&(fragment.fragmentList[quality].fragments[i]!==undefined)) // If quality could not be selected, try again later
                    {
                        fragment.fragmentList[0].fragments[i].loadstate=1;
                        let url = fragment.fragmentList[quality].fragments[i].url;
                        fragment.fragmentList[0].fragments[i].quality=quality;
//                        console.log("Fetching URL "+url);
                        var entry = {
                            startTime : (new Date()).getTime(),
                            endTime : -1,
                            bytesPerMsec : 0
                        }
                        this.downloadList.push(entry);
                        this.fetchFragment(url, fragment, i, currentContentID, entry);
                    }
                }
            }
        }
    }

    playAnswer(answerURL, nextIdleLoopURL)
    {
        this.answerVideo.nextIdleLoop=this.props.videoUrlPrefix+nextIdleLoopURL+((this.videoState.is3D===true)?this.props.videoUrlPostfix3D:this.props.videoUrlPostfix2D);
        this.answerVideo.playFromTimestamp=0.0;
        this.answerVideo.fileID=answerURL;
        this.downloadBaseM3U(this.props.videoUrlPrefix+answerURL+((this.videoState.is3D===true)?this.props.videoUrlPostfix3D:this.props.videoUrlPostfix2D),0);
        this.playIdleLoopDelayed(nextIdleLoopURL,true);
    }

    playAnswerFromTimestamp(answerURL, timestamp, nextIdleLoopURL)
    {
        this.answerVideo.nextIdleLoop=this.props.videoUrlPrefix+nextIdleLoopURL+((this.videoState.is3D===true)?this.props.videoUrlPostfix3D:this.props.videoUrlPostfix2D);
        this.answerVideo.playFromTimestamp=timestamp;
        this.answerVideo.fileID=answerURL;
        this.downloadBaseM3U(this.props.videoUrlPrefix+answerURL+((this.videoState.is3D===true)?this.props.videoUrlPostfix3D:this.props.videoUrlPostfix2D),0);
        this.playIdleLoopDelayed(nextIdleLoopURL,true);
    }

    playIdleLoopDelayed(nextIdleLoopURL, afterAnswer)
    {
        var loopnr;
        var nextloop;
        var fullurl=this.answerVideo.nextIdleLoop=this.props.videoUrlPrefix+nextIdleLoopURL+((this.videoState.is3D===true)?this.props.videoUrlPostfix3D:this.props.videoUrlPostfix2D);

        if (this.idleLoop1.fileURL===fullurl)
            nextloop=1;
        else if (this.idleLoop2.fileURL===fullurl)
            nextloop=2;
        else
        {
            if (this.videoState.currentIdleLoop===1) loopnr=2; else loopnr=1;
            if (loopnr===1)
            {
                this.idleLoop1.fileURL=fullurl;
                this.idleLoop1.fileID=nextIdleLoopURL;
            }
            else
            {
                this.idleLoop2.fileURL=fullurl;
                this.idleLoop2.fileID=nextIdleLoopURL;
            }

            nextloop=loopnr;
            this.downloadBaseM3U(fullurl,loopnr);
        }
        if (afterAnswer===false)
        {    
           this.videoState.currentIdleLoop=nextloop;
            this.videoState.playingVideo = false;
        }
        else
            this.answerVideo.nextIdleLoopNr=nextloop;
    }

    playIdleLoop(nextIdleLoopURL)
    {
        this.playIdleLoopDelayed(nextIdleLoopURL,false);
    }

    render() {
        var vidwidth = Math.round(100.0/this.props.videoWidthCrop)+"%";
        var vidoffset = -((50.0/(this.props.videoWidthCrop))-50)
        vidoffset = vidoffset - (this.props.videoCropOffset*100.0);
        vidoffset = vidoffset +"%";

        return (
            <React.Fragment>
            <div style={{ width: '100%', height: '100%', overflow: "hidden"}}>
                <video
                    key={"video_" + this.props.id}
                    className="videoCanvas"
                    ref={this.video}
                    autoPlay={false}
                    preload="auto"
                    style={{ width: vidwidth, height: '100%', marginLeft: vidoffset}}
                    controls={false}/>
            </div>
            <PlayButtonOverlay show={this.state.showPlayButtonOverlay} handleClickPlay={this.handlePlayButtonOverlayClick}/>
            </React.Fragment>
        );
    };
}

