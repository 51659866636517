import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import AppRecorder from '../recorder/appRecorder.js';
import AppPlayer from '../player/appPlayer.js';
import AppControls from '../controls/appControls.js';

import phoneMockup from '../assets/Mockup_iphone.png';

import globalConfig from '../globalConfig.js'
var config = {};

export default class IntegratedView extends React.Component {
    static defaultProps = {
        height: window.innerHeight,
        width: window.innerWidth,
        handleLogout: undefined,
        handleReload: undefined,
        handleAudioRecording: undefined,
        handleTextInput: undefined,
        videoUrlPrefix: "",
        videoUrlPostfix2D: "",
        videoUrlPostfix3D: "",
        speedtestURL: "",
        videoCropWidth: 1.0,
        videoCropOffset: 0.0,
        introVersion: undefined,
        introListShort: undefined,
        introListLong: undefined,
        mode: config.application, //currently supported are full, host, guest and demo
    };

    static propTypes = {
        height: PropTypes.number,
        width: PropTypes.number,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        handleAudioRecording: PropTypes.func,
        handleTextInput: PropTypes.func,
        videoUrlPrefix: PropTypes.string,
        videoUrlPostfix2D: PropTypes.string,
        videoUrlPostfix3D: PropTypes.string,
        speedtestURL: PropTypes.string,
        videoCropWidth: PropTypes.number,
        videoCropOffset: PropTypes.number,
        introVersion: PropTypes.string,
        introListShort: PropTypes.array,
        introListLong: PropTypes.array,
        mode: PropTypes.string,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);

        if (config.application === "demo" || this.props.mode === "demo" ){
            this.state = {
                showControls: false,
                allowUserControls: false,
                showRecorderLogoutButton: false,
                showPlayerLogoutButton: false,
                showCurrentQuestion: false /* this state overwrites this.props.showCurrentQuestion */
            }
        } else if (this.props.mode === "full" || this.props.mode === "host"){
            this.state = {
                showControls: true,
                allowUserControls: true,
                showRecorderLogoutButton: false,
                showPlayerLogoutButton: false,
                showCurrentQuestion: true,
            }
        } else  { //(mode === "guest") is fallback mode
            this.state = {
                showControls: false,
                allowUserControls: true,
                showRecorderLogoutButton: true,
                showPlayerLogoutButton: false,
                showCurrentQuestion: true
            }
        } 
        
    };

    render() {
        
        var controlsWidth = Math.max(540, 0.3*this.props.width);
        if (this.props.mode === "demo"){
            controlsWidth = this.props.height/2; //respect aspect ratio of the mobile phone mockup
        }
        var videoWidth = this.props.width - controlsWidth;
        
        var recorderHeight, controlsHeight;
        if (this.state.showControls === true) {
            recorderHeight = Math.max(400,0.4*this.props.height);
            controlsHeight = this.props.height - recorderHeight;
        } else {
            controlsHeight = 0;
            recorderHeight = this.props.height;
        }

        var recorderMarginsHorizontal = 0;
        var recorderMarginsVertical = 0;
        var borderClass = "divborder";
        if (this.props.mode === "demo"){
            //make space for background image
            recorderMarginsVertical = 110;
            var appHeight = recorderHeight - recorderMarginsVertical;
            var appWidth = appHeight / 1.95;
            recorderMarginsHorizontal = controlsWidth - appWidth; //180;
            
            borderClass = ""
        }

        return (
            <div className="app-body" style={{display: 'flex', flexWrap: 'nowrap', maxHeight: '100vh', height: this.props.height, width: this.props.width, flexDirection: 'row'}}>
                
                <div style={{display: 'flex', flex: '1 1', height: this.props.height, width: videoWidth}}>
                    <AppPlayer 
                        showLogoutButton={this.state.showPlayerLogoutButton}
                        handleLogout={this.props.handleLogout}
                        handleReload={this.props.handleReload}
                        ref={this.props.appPlayerRef} 
                        speedtestURL={this.props.speedtestURL}
                        speedOverride={this.props.speedOverride}
                        width={videoWidth}
                        height={this.props.height}
                        videoDimensions3D={false} //Video cannot be 3D in integrated view
                        videoUrlPrefix={this.props.videoUrlPrefix}
                        videoUrlPostfix2D={this.props.videoUrlPostfix2D}
                        videoUrlPostfix3D={this.props.videoUrlPostfix3D}
                        videoCropWidth={this.props.videoCropWidth}
                        videoCropOffset={this.props.videoCropOffset}
                        allowUserControls={this.state.allowUserControls}
                        showCurrentQuestion={this.state.showCurrentQuestion}/>
                </div>
                <div className={borderClass} style={{display: 'flex', flex: '0 0', flexWrap: 'nowrap', flexDirection: 'column', justifyContent: 'center', height: this.props.height, width: controlsWidth}}>
                    {this.state.showControls === true ? 
                    <div className={borderClass} style={{display: 'flex', flex: '1 1', height: controlsHeight, width: controlsWidth}}>
                        <AppControls 
                            ref={this.props.appControlsRef}
                            handleLogout={this.props.handleLogout}
                            handleReload={this.props.handleReload}
                            handleEvents={this.props.handleControlsEvents} 
                            loginData={this.props.loginData}
                            width={controlsWidth} 
                            height={controlsHeight-20}
                            introVersion = {this.props.introVersion}
                            introListShort = {this.props.introListShort}
                            introListLong = {this.props.introListLong}
                            defaultSessionDetails = {this.props.defaultSessionDetails}/>
                    </div>
                    : null }
                    <div className={borderClass + 'flex-container-column'} style={{justifyContent: 'center', flex: '0 0', zIndex: '1', height: recorderHeight, width: controlsWidth}}>
                        <div className={borderClass} 
                            style={{
                                overflow: 'hidden', 
                                height: recorderHeight - recorderMarginsVertical, 
                                width: controlsWidth - recorderMarginsHorizontal}}>
                            <AppRecorder 
                                ref={this.props.appRecorderRef}
                                showLogoutButton={this.state.showRecorderLogoutButton}
                                handleLogout={this.props.handleLogout}
                                handleReload={this.props.handleReload}
                                handleAudioRecording={this.props.handleAudioRecording}
                                handleTextInput={this.props.handleTextInput}
                                handleSelectRespondent = {this.props.handleSelectRespondent}
                                width={controlsWidth - recorderMarginsHorizontal} 
                                height={recorderHeight - recorderMarginsVertical-20}/>
                        </div>
                        {this.props.mode === "demo" ?
                            <img className="position-absolute" 
                                style={{pointerEvents: 'none', top: -recorderMarginsVertical/2, height: recorderHeight}}
                                src={phoneMockup}
                                alt="Iphone Mockup"
                                draggable="false"
                                onError={(e) => console.log(e.target.style.display = 'none')}/>
                            : null }                        
                    </div>
                </div>
            </div>
        );
    };
}