import React from "react";
import { Resizable } from "re-resizable"; //npm install --save re-resizable
import PropTypes from 'prop-types'; //npm install --save prop-types
import Draggable from 'react-draggable'; //npm i react-draggable
import LogoutButton from '../utils/logoutButton.js';

import MoveIcon from '../icons/move_icon_bold.svg';
import RecenterIcon from '../icons/recenter_icon_bold.svg';
import ZoomInIcon from '../icons/zoomin_icon_bold.svg';
import ZoomOutIcon from '../icons/zoomout_icon_bold.svg';
import SettingsIcon from '../icons/settings_icon_bold.svg';

export default class MoveBox extends React.Component {
    static defaultProps = {
        videoWidth: 960,
        videoHeight: 540,
        showLogoutButton: true,
        handleLogout: undefined,
        handleReload: undefined,
        handleShowSettings: undefined,
        player: undefined,
        currentQuestion: "",
        subtitleWidth: 100, /* in percent */
        fontsize: 18,
        allowUserControls: true
    };

    static propTypes = {
        videoWidth: PropTypes.number,
        videoHeight: PropTypes.number, 
        showLogoutButton: PropTypes.bool,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        handleShowSettings: PropTypes.func,
        player: PropTypes.object,
        currentQuestion: PropTypes.string,
        fontsize: PropTypes.number,
        allowUserControls: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.dragComponent = React.createRef();
        this.state = {
            offsetX: 0,
            offsetY: 0,
            width: this.props.videoWidth,
            height: this.props.videoHeight,
            aspectRatio: this.props.videoWidth / this.props.videoHeight,
            resizeStartWidth: this.props.videoWidth,
            resizeStartHeight: this.props.videoHeight,
            resizeMaxWidth: window.innerWidth,
            resizeMaxHeight: window.innerHeight,
            controlsVisible: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            dragWindowMaxOffsetX: 0,
            dragWindowMaxOffsetY: 0,
        };
        this.handleSetDragBoundaries = this.handleSetDragBoundaries.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handleScale = this.handleScale.bind(this);
        this.debugCanvas = React.createRef();
        this.debugTimer = null;
        this.debugState = 0;
        this.bandwidthMax=0;
        this.bandwidthList=[];
        for (var i=0; i<220; i++)
        {
            this.bandwidthList.push(0);
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);

        this.canvasContext = this.debugCanvas.current.getContext("2d");
    };

    componentDidUpdate(prevProps) {
        if (isNaN(this.props.videoWidth)) return;
        if (isNaN(this.props.videoHeight)) return;
        if ((prevProps.videoWidth !== this.props.videoWidth) || (prevProps.videoHeight !== this.props.videoHeight)) {
            //console.log("Movebox did update");
            //console.log(prevProps);
            //console.log(this.props);
            this.setState({
                width: this.props.videoWidth,
                height: this.props.videoHeight,
                aspectRatio: this.props.videoWidth / this.props.videoHeight,
                resizeStartWidth: this.props.videoWidth,
                resizeStartHeight: this.props.videoHeight,
            })
        }
    }
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
    }; 
    
    handleWindowResize = (e) => {
        this.setState({ 
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        });
    };

    handleSetDragBoundaries() {
        this.setState({
            dragWindowMaxOffsetX: (this.state.windowWidth - this.state.width)/2,
            dragWindowMaxOffsetY: (this.state.windowHeight - this.state.height)/2,
        });
    };

    handleMove(deltaX, deltaY){
        this.handleSetDragBoundaries();
        var d = {};
        d.deltaX = deltaX;
        d.deltaY = deltaY;
        this.handleDrag(null, d);
    }

    handleDrag = (e, d) => {
        var newXOffset = this.state.offsetX + d.deltaX;
        var newYOffset = this.state.offsetY + d.deltaY;
        if (newXOffset < -this.state.dragWindowMaxOffsetX){
            newXOffset = -this.state.dragWindowMaxOffsetX;
        } else if (newXOffset > this.state.dragWindowMaxOffsetX){
            newXOffset = this.state.dragWindowMaxOffsetX;
        }
        if (newYOffset < -this.state.dragWindowMaxOffsetY){
            newYOffset = -this.state.dragWindowMaxOffsetY;
        } else if (newYOffset > this.state.dragWindowMaxOffsetY){
            newYOffset = this.state.dragWindowMaxOffsetY;
        }
        this.setState({
            offsetX: newXOffset,
            offsetY: newYOffset,
        });
    };

    handleRecenter = () => {
        this.setState({
            offsetX: 0,
            offsetY: 0,
        });
    };

    handleScale(delta) {
        var maxHeight = this.state.windowHeight - 2*Math.abs(this.state.offsetY)
        var maxWidth = this.state.windowWidth - 2*Math.abs(this.state.offsetX)
        var maxDelta = Math.min(maxWidth / this.state.width, maxHeight / this.state.height);
        if (delta > maxDelta) {
            delta = maxDelta;
        }
        this.setState({
            width: this.state.width*delta,
            height: this.state.height*delta,
        });
    };

    handleFrameResizeStart = (e, direction, ref) => {
        this.setState({
            resizeStartWidth: this.state.width,
            resizeStartHeight: this.state.height,
            resizeMaxWidth: this.state.windowWidth - 2*Math.abs(this.state.offsetX),
            resizeMaxHeight: this.state.windowHeight - 2*Math.abs(this.state.offsetY),
        });
    };

    handleFrameResize = (e, direction, ref, d) => {
        var newWidth = this.state.resizeStartWidth + d.width;
        var newHeight = this.state.resizeStartHeight + d.height;
        if (newWidth > this.state.resizeMaxWidth){
            newWidth = this.state.resizeMaxWidth;
            newHeight = newWidth/this.state.aspectRatio;
        }
        if (newHeight > this.state.resizeMaxHeight){
            newHeight = this.state.resizeMaxHeight;
            newWidth = newHeight*this.state.aspectRatio;
        }
        this.setState({
            width: newWidth,
            height: newHeight,
        });
    };

    handleShowControls = () => {
        this.setState({
            controlsVisible: true,
        });
    };

    
    handleHideControls = () => {
        this.setState({
            controlsVisible: false,
        });
    };

    toggleDebug = () => {
        if (this.debugState==0)
        {
            this.debugTimer = window.setInterval(this.handleDebugInfo,100);
            this.debugState=1;
        }
        else
        {
            window.clearInterval(this.debugTimer);
            this.debugState=0;
            this.canvasContext.clearRect(0,0,500,500);
//            this.canvasContext.fillStyle='#000000';
//            this.canvasContext.fillRect(0,0,500,300);
            }
    }
    
    handleDebugInfo = () => {
//        this.canvasContext.fillStyle='#00000000';
//        this.canvasContext.fillRect(0,0,500,300);
        this.canvasContext.clearRect(0,0,500,500);

        this.canvasContext.font = "15px Roboto";

        var fragcnt = 0;
        if (this.props.player.current) {
            fragcnt = this.props.player.current.playerInfo.bufferedFragmentCount;
        }
        
        this.canvasContext.strokeStyle = '#FFFFFF';
        this.canvasContext.beginPath();
        this.canvasContext.moveTo(2, 2);
        this.canvasContext.lineTo(228, 2);
        this.canvasContext.lineTo(228, 13);
        this.canvasContext.lineTo(2, 13);
        this.canvasContext.lineTo(2, 2);
        this.canvasContext.stroke();

        if (fragcnt<5)
            this.canvasContext.fillStyle='#FF0000';
        else if (fragcnt<10)
            this.canvasContext.fillStyle='#FFFF00';
        else
            this.canvasContext.fillStyle='#00FF00';

        if (fragcnt>21) fragcnt=21;
        this.canvasContext.fillRect(5,5,(fragcnt*10)+7,5);

        var i,j;
        var frag;
        var yoffset=0;
        var x,y;
        var quality=-1;
        var cpu;

        yoffset=30;

        this.canvasContext.fillStyle='#E0E0E0';
        this.canvasContext.fillText("CPU:", 5, yoffset+12);

        this.canvasContext.strokeStyle = '#FFFFFF';
        this.canvasContext.beginPath();
        this.canvasContext.rect(50,yoffset,188,15);
        this.canvasContext.stroke();

        if (this.props.player.current){
            cpu=(this.props.player.current.playerInfo.transcodeTime/500.0);
        } else {
            cpu = 0.0;
        }
        if (cpu<0.0) cpu=0.0;
        if (cpu>1.0) cpu=1.0;

        if (cpu<0.5) this.canvasContext.fillStyle='#00FF00';
        else if (cpu<0.8) this.canvasContext.fillStyle='#FFFF00';
        else this.canvasContext.fillStyle='#FF0000';
        cpu*=180.0;

        this.canvasContext.fillRect(54,yoffset+4,cpu,7);
        yoffset+=30;

        if (this.props.player.current && this.props.player.current.playerInfo.videoInfo!==undefined)
        {
            this.canvasContext.fillStyle='#E0E0E0';
            this.canvasContext.fillText("Video: "+this.props.player.current.playerInfo.videoInfo.width+"x"+this.props.player.current.playerInfo.videoInfo.height+"@"+this.props.player.current.playerInfo.videoInfo.fps+" FPS",5, yoffset+10);
    
            yoffset+=25;
        }

        this.canvasContext.fillStyle='#E0E0E0';
        var performanceInfo = "";
        if (this.props.player.current) {
            performanceInfo = (this.props.player.current.playerInfo.performanceInfoAvailable===true)?" (P)":"";
        }
        this.canvasContext.fillText("Bandwidth: (Max="+Math.round(this.bandwidthMax/(1024*1024))+" Mb/s)"+performanceInfo,5, yoffset+10);
        yoffset+=5;
        this.canvasContext.strokeStyle = '#FFFFFF';
        this.canvasContext.beginPath();
        this.canvasContext.rect(5,13+yoffset,232,40);
        this.canvasContext.stroke();

        if (this.props.player.current){
            this.bandwidthList.push(this.props.player.current.playerInfo.estimatedDownstream);
            this.bandwidthList.shift();
        }
//        if (this.props.player.current.playerInfo.estimatedDownstream>this.bandwidthMax) this.bandwidthMax=this.props.player.current.playerInfo.estimatedDownstream;

        this.bandwidthMax=0;
        for (j=0; j<this.bandwidthList.length; j++)
        {
            if (this.bandwidthList[j]>this.bandwidthMax) this.bandwidthMax=this.bandwidthList[j];
        }

        var scale = 30/this.bandwidthMax;
//        var scale = 15/this.props.player.current.playerInfo.currentVideoBandwidth;
        var val;
        for (j=0; j<this.bandwidthList.length; j++)
        {
            val=Math.round(this.bandwidthList[j]*scale);
            if (val>30) val=30;
            this.canvasContext.strokeStyle = '#00FF00';
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(10+j, 13+yoffset+35);
            this.canvasContext.lineTo(10+j, 13+yoffset+35-val);
            this.canvasContext.stroke();
        }

        val=this.props.player.current.playerInfo.currentVideoBandwidth*scale;
        if (val>30) val=30;

        this.canvasContext.strokeStyle = '#FF0000';
        this.canvasContext.beginPath();
        this.canvasContext.moveTo(10, 13+yoffset+35-val);
        this.canvasContext.lineTo(10+this.bandwidthList.length, 13+yoffset+35-val);
        this.canvasContext.stroke();

        yoffset+=70;

        for (j=0; j<3; j++)
        {
            quality=-1;
//                    if (j===0) frag=this.props.player.current.playerInfo.currentFragment;
            if (j===0) frag=this.props.player.current.answerVideo;
            else if (j===1) frag=this.props.player.current.idleLoop1;
            else if (j===2) frag=this.props.player.current.idleLoop2;

            this.canvasContext.fillStyle='#E0E0E0';
            this.canvasContext.fillText(frag.name+": "+frag.fileID, 5, yoffset+10);
            yoffset+=5;

            if (frag.fragmentList[0]===undefined)
            {
//                        this.canvasContext.fillStyle='#303030';
//                        this.canvasContext.fillRect(5,yoffset+20,200,20);

                this.canvasContext.fillStyle='#808080';
                this.canvasContext.fillText("Buffer Empty", 15, yoffset+20+15);
                this.canvasContext.strokeStyle = '#FFFFFF';
                this.canvasContext.beginPath();
                this.canvasContext.rect(5,13+yoffset,232,35);
                this.canvasContext.stroke();
                yoffset+=25+40;
                continue;
            } 

            for (i=0; i<frag.fragmentList[0].entrycnt; i++)
            {
                if (frag.fragmentList[0].fragments[i]===undefined) break;

                if (frag.currentPlayFragment===i)
                    this.canvasContext.fillStyle='#FF0000';
                else if (frag.fragmentList[0].fragments[i].loadstate===0)
                    this.canvasContext.fillStyle='#505050';
                else if (frag.fragmentList[0].fragments[i].loadstate===1)
                    this.canvasContext.fillStyle='#FFFF00';
                else if (frag.fragmentList[0].fragments[i].loadstate===2)
                    this.canvasContext.fillStyle='#00FF00';
                else
                    this.canvasContext.fillStyle='#0000FF';

                // Check for switched quality setting
                if ((frag.fragmentList[0].fragments[i].loadstate===2)&&(quality>=0)&&(quality!==frag.fragmentList[0].fragments[i].quality)) 
                {
                    this.canvasContext.fillStyle='#008000';
                }
                quality=frag.fragmentList[0].fragments[i].quality;

                x = (i % 32)*7;
                y = (i >> 5)*7;

                this.canvasContext.fillRect(x+10,y+20+yoffset,4,4);
            }
            this.canvasContext.strokeStyle = '#FFFFFF';
            this.canvasContext.beginPath();
            this.canvasContext.rect(5,13+yoffset,232,y+17);
            this.canvasContext.stroke();
            yoffset+=y+47;
        }
    }

    render() {
        const BUTTON_DIAMETER = 40;
        const MOVEFRAME_BORDER_WIDTH = 2;
        const MOVEFRAME_GAPSIZE = 5
        const MOVEFRAME_MIN_HEIGHT = 5*BUTTON_DIAMETER + 2*(MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE);
        var BUTTON_STYLE = { //style is set here to override mouse event appareance changes
            width: BUTTON_DIAMETER, 
            height: BUTTON_DIAMETER, 
            padding: '0px',
            margin: '1px', 
            outline: 'none', 
            boxShadow: 'none',
            display: (this.state.controlsVisible && this.props.allowUserControls) ? 'inline-flex' : 'none'};
        var borderStyle;
        if (this.state.controlsVisible && this.props.allowUserControls){
            borderStyle = MOVEFRAME_BORDER_WIDTH + 'px solid grey'
        } else {
            borderStyle = MOVEFRAME_BORDER_WIDTH + 'px solid transparent'
        }
        var videoCenterX = this.state.offsetX - this.state.width/2 + BUTTON_DIAMETER/2 + MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE;
        var videoCenterY = this.state.offsetY - this.state.height + MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE;

        var videoPointEvents;
        if (this.dragComponent.current !== null && this.dragComponent.current.state.dragging === true){
            videoPointEvents = 'none';
        } else {
            videoPointEvents = 'auto';
        }

        return (
            <div 
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '0', width: '100%', height: '100%'}}> 
            <div style={{position: 'fixed', left: '0', top: '0', zIndex: '2', pointerEvents: "none"}}>
                <canvas 
                    id="debugCanvas" 
                    ref={this.debugCanvas} 
                    width='500'
                    height='500'
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        margin: '0',
                        }}>
                </canvas>
            </div>
            <Resizable
                style={{padding: MOVEFRAME_GAPSIZE, border: borderStyle, position: 'relative', top: this.state.offsetY, left: this.state.offsetX}}
                size={{ width: this.state.width, height: this.state.height}}
                minHeight={MOVEFRAME_MIN_HEIGHT}
                onResizeStart={this.handleFrameResizeStart}
                onResize={this.handleFrameResize}
                lockAspectRatioExtraWidth={0}
                lockAspectRatio={true}
                resizeRatio={2}
                onMouseOver={this.handleShowControls}
                onMouseOut={this.handleHideControls}>
                {this.props.showLogoutButton ?
                <LogoutButton 
                    zIndex='1'
                    handleLogout={this.props.handleLogout}
                    handleReload={this.props.handleReload} 
                    size={BUTTON_DIAMETER} gap={MOVEFRAME_GAPSIZE} 
                    windowWidth={this.state.width-MOVEFRAME_BORDER_WIDTH-MOVEFRAME_GAPSIZE} 
                    visibility = {this.state.controlsVisible}/>: null }
                <div style={{pointerEvents: videoPointEvents} /*suppress pointer events on video element when dragging*/}> 
                    {this.props.children}
                </div>
                <div className="flex-container-column" style={{position: 'absolute', width: '100%', left: '0', top: this.state.height, zIndex: '3'}}>
                    <label style={{display: 'block', width: this.props.subtitleWidth + '%', textAlign: 'center', fontSize: this.props.fontsize}}> 
                        {this.props.currentQuestion}
                    </label>
                </div>
            </Resizable>
            <Draggable
                ref={this.dragComponent}
                axis="both"
                handle=".handle"
                grid={[1, 1]}
                defaultPosition={{x: videoCenterX, y: videoCenterY}}
                position={{x: videoCenterX, y: videoCenterY}}
                bounds={{
                    left: -this.state.windowWidth/2 + MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE + BUTTON_DIAMETER/2, 
                    right: this.state.windowWidth/2 + MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE + BUTTON_DIAMETER/2 - this.state.width,
                    top: -this.state.windowHeight/2 - this.state.height/2 + MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE, 
                    bottom: this.state.windowHeight/2 - this.state.height*1.5 + MOVEFRAME_BORDER_WIDTH + MOVEFRAME_GAPSIZE,}}
                onStart={this.handleSetDragBoundaries}
                onDrag={this.handleDrag}>
                <div 
                    style={{display: 'block', height: '0', width: BUTTON_DIAMETER}}
                    onMouseOver={this.handleShowControls}
                    onMouseOut={this.handleHideControls}>
                    <button   
                        className="handle btn btn-secondary-nofilter"
                        style={BUTTON_STYLE}>
                        <img className="filter-lightgrey" src={MoveIcon} alt="Move Icon" width="80%" margin="auto" padding="0" draggable="false"/>
                    </button>
                    <button 
                        className="btn btn-secondary-nofilter"
                        style={BUTTON_STYLE}
                        onClick={this.handleRecenter}>
                        <img className="filter-lightgrey" src={RecenterIcon} alt="Recenter Icon" width="80%" margin="auto" padding="0" draggable="false"/>
                    </button>
                    <button 
                        className="btn btn-secondary-nofilter"
                        style={BUTTON_STYLE}
                        onClick={() => this.handleScale(0.9)}>
                        <img className="filter-lightgrey" src={ZoomOutIcon} alt="Zoomout Icon" width="80%" margin="auto" padding="0" draggable="false"/>
                    </button>
                    <button 
                        className="btn btn-secondary-nofilter"
                        style={BUTTON_STYLE}
                        onClick={() => this.handleScale(1.1)}>
                        <img className="filter-lightgrey" src={ZoomInIcon} alt="Zoomin Icon" width="80%" margin="auto" padding="0" draggable="false"/>
                    </button>  
                    <button 
                        className="btn btn-secondary-nofilter"
                        style={BUTTON_STYLE}
                        onClick={this.props.handleShowSettings}>
                        <img className="filter-lightgrey" src={SettingsIcon} alt="Zoomin Icon" width="80%" margin="auto" padding="0" draggable="false"/>
                    </button>
                </div>
            </Draggable>
            </div>
        );
    };
}