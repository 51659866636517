import React from "react";

import AppSelector from './appSelector.js';
import AppRecorder from '../recorder/appRecorder.js';
import AppPlayer from '../player/appPlayer.js';
import AppControls from '../controls/appControls.js';
import Backend from './backendCalls.js';
import IntegratedView from './integratedView.js';

import WaitingForServerOverlay from '../utils/waitingForServerOverlay.js';
import ErrorPopup from '../utils/errorPopup.js';

export default class appLoader extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            currentApp: '',
            permissions: 'guest',
            waitingForServer: false,
            loginData: undefined,
            videoIs3D: false,
            connectionLost: false,
            videoUrlPrefix: undefined,
            videoUrlPostfix2D: undefined,
            videoUrlPostfix3D: undefined,
            videoCropWidth: 0.47,
            videoCropOffset: -0.05,
            myDeviceList: undefined,
            speedtestURL: "https://lediz.cat-lab.com/stream/speedtest" 
        };

        this.firstRendered = false;
        this.respondent_list = [];
        this.handleResize = this.handleResize.bind(this);
        this.renderApps = this.renderApps.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleReload = this.handleReload.bind(this);
        this.setSelectedApp = this.setSelectedApp.bind(this);
        this.setVideoFormat3D = this.setVideoFormat3D.bind(this);
        this.handleAudioRecording = this.handleAudioRecording.bind(this);
        this.handleTextInput = this.handleTextInput.bind(this);
        this.handleSelectRespondent = this.handleSelectRespondent.bind(this);
        this.unblockMic = this.unblockMic.bind(this);
        this.recorderSetMicReady = this.recorderSetMicReady.bind(this);
        this.recorderSetMicBlocked = this.recorderSetMicBlocked.bind(this);
        this.recorderSetProcessingDone = this.recorderSetProcessingDone.bind(this);
        this.recorderSetProcessingError = this.recorderSetProcessingError.bind(this);
        this.recorderShowSelectRespondentPopup = this.recorderShowSelectRespondentPopup.bind(this);
        this.stopAnswer = this.stopAnswer.bind(this);
        this.handleControlsEvents = this.handleControlsEvents.bind(this);
        this.appPlayerRef = React.createRef();
        this.appRecorderRef = React.createRef();
        this.appControlsRef = React.createRef();
        this.errorPopupRef = React.createRef();
        this.speedOverride=-1;
        this.handleServerLogin = this.handleServerLogin.bind(this);
        this.showErrorPopup = this.showErrorPopup.bind(this);
        
	    this.serverStatus = this.serverStatus.bind(this);
        this.server = new Backend();
    };

    handleResize(){
        //detect when it is necessary to rearrage flex-box items in columns/rows
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    componentDidMount() {
        this.handleServerLogin();
    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleServerLogin(){
        var cachedLoginData;
        var cachedCurrentApp;
        var loginData = null;
        var currentApp = null;

        window.addEventListener("resize", this.handleResize);

        if (this.props.location && this.props.location.state){
            //check for login data in router parameters
            loginData = {
                name: this.props.location.state.name,
                id: this.props.location.state.id,
                key: this.props.location.state.key
            }
            if (this.props.location.state.currentApp) {
                currentApp = this.props.location.state.currentApp
            }
        } else {
            //check for login data in cookies
            cachedLoginData = localStorage.getItem('loginData');
            if (cachedLoginData !== null) {
                cachedLoginData = JSON.parse(cachedLoginData); //parse from string to object
                loginData = {
                    name: cachedLoginData.name,
                    id: cachedLoginData.id,
                    key: cachedLoginData.key,
                }
                cachedCurrentApp = localStorage.getItem('currentApp');
                if (cachedCurrentApp !== null && (cachedCurrentApp === 'integrated' || cachedCurrentApp === 'demo')) {
                    //restore current App only if it is 'integrated' or 'demo', show appSelector otherwise
                    currentApp = cachedCurrentApp
                }
            } else {
                //no valid login data. This can only happen by calling '/app' as direct link instead of routing from login page or by refresh
                this.props.history.push("/");
                return;
            }
        }
        
        var connection_id = this.createRandomKey(16,4);
        this.setState({waitingForServer: true});
        //this.server.set_dev_type(currentApp);
        //if (currentApp==="integrated") this.firstRendered = true;
        console.log("login Data appLoader: ", loginData);
        this.server.connect("joinSess", loginData, connection_id, this.serverStatus);

        //set state
        if (currentApp !== null) {
            this.setState({
                loginData: {
                    name: loginData.name,
                    id: loginData.id,
                    key: loginData.key,
                },
                currentApp: currentApp,
            })
        } else {
            this.setState({
                loginData: {
                    name: loginData.name,
                    id: loginData.id,
                    key: loginData.key,
                }
            })
        }

        //set cookies
        localStorage.setItem('loginData', JSON.stringify(loginData));
        localStorage.setItem('currentApp', JSON.stringify(currentApp));
    }


    handleLogout(){
       //set cookies
       localStorage.removeItem('loginData');
       localStorage.removeItem('currentApp');
        
        //then return to home
        this.props.history.push("/");

        //TODO close backend connection
        this.server.logout();
    }

    handleReload(){
        this.props.history.go(0);
    }

    setSelectedApp(app){
        if (app !== this.state.currentApp){
            this.setState({currentApp: app});
        }
        var data = {
            command: "dev_type",
            parameters:{
                life_time: 0,
                sess_id: "",
                sess_key: "",
                guest_key: "",
                dev_name: "",
                client_id: "",
                sess_context: "",
                dev_type: app,
                mic_blocking: true,
                subtitle_showing: true,
                num_of_devices: 0,
            }
        };

        this.server.set_dev_type(app);
        this.server.sendData(data);
        this.firstRendered = true;
        //set cookies
        localStorage.setItem('currentApp', JSON.stringify(app));
    }

    setVideoFormat3D(format){
        if (this.state.videoIs3D !== format) {
            this.setState({videoIs3D: format})
        }
    }

    unblockMic() {
        if(this.appRecorderRef.current.state.micBlocked === false) {
            this.recorderSetMicReady();
        }
    }


    serverStatus(data){
        //let prefix = "https://192.168.0.128:3000/video/";
        //let prefix = "https://lediz.cat-lab.com:3000/stream/";
        //let prefix = "https://lediz.cat-lab.com/stream/";
        //console.log(status);
        //TODO: Timeout and error message
        //
        switch(data) {

            case "credentials_ack_host":
                console.log("ACK of credential in appLoader"); 
                this.setState({permissions: 'host', waitingForServer: false}); 
                if (this.state.currentApp === 'integrated') { 
                    //no selection over appSelector, set app type directly
                    this.setSelectedApp('integrated');
                }
                break;
            
            case "credentials_ack_guest":
                console.log("ACK of credential in appLoader"); 
                this.setState({permissions: 'guest', waitingForServer: false}); 
                if (this.state.currentApp === 'integrated') { 
                    //no selection over appSelector, set app type directly
                    this.setSelectedApp('integrated');
                }
                break;

            case "credentialsNACK":
                this.setState({waitingForServer: "credentialsNACK"});
                //remove cookies
                localStorage.removeItem('loginData');
                localStorage.removeItem('currentApp');
                //return to login
                this.props.history.push({
                    pathname: '/session/login/' + this.state.loginData.id, 
                    state: {
                        sessionKey: this.state.loginData.key, 
                        errortype: "loginrefused"
                    }
                });
                break;

            case "max_clients":
                //this.showErrorPopup("max_clients");
                this.setState({waitingForServer: "credentialsNACK"});
                //TODO Redirect to login page!
                console.log("show error"); 
                this.props.history.push({
                    pathname: '/session/login/' + this.state.loginData.id, 
                    state: {
                        sessionKey: this.state.loginData.key, 
                        errortype: "max_clients"
                    }
                });
                break;

            case "ack_audio_request":
                this.recorderSetProcessingDone();
                break;

            case "nack_audio_request":
                this.recorderSetProcessingError();
                break;

            case "bad_audio_popup":
                this.showErrorPopup("badaudio");
                break;

            case "no_match_popup":
                this.showErrorPopup("nomatch");
                break;

            case "failed_audio_request":
                // TODO show correct error message
                this.recorderSetProcessingError();
                setTimeout(this.recorderSetMicReady, 2000);
                break;

            case "serverError":
                this.setState({waitingForServer: "serverError"});
                break;

            case "connection_lost":
                this.setState({
                    connectionLost: true
                });
                break;

            case "connected": 
                //this.setState({waitingForServer: false});
                //if (this.state.currentApp === 'integrated'){ 
                //    //no selection over appSelector, set app type directly
                //    this.setSelectedApp('integrated');
                //}
                break;

            case "reconnected": 
                console.log("reconnected");
                this.setState({
                    connectionLost: false
                });
                this.recorderSetMicReady();
                //TODO setting dev_type or setting auswahl
                break;

            default:
                break;
        }

        if(data.type !== undefined) {

            switch(data.type) {
                
                case "video_info":
                    console.log(data.video);
                    this.setState({
                        videoUrlPrefix: data.video.url_prefix,
                        videoUrlPostfix2D: data.video.url_postfix_2D,
                        videoUrlPostfix3D: data.video.url_postfix_3D,
                    });
                    break;

                case "show_respondent_list":
                    console.log("respondent list in appLoader: ", data.list);
                    this.respondent_list = data.list;
                    this.recorderShowSelectRespondentPopup(data.list);
                    break;

                case "flagged_answers_download":
                    this.appControlsRef.current.handleDownloadFlaggedListReady(data.data);
                    break;

                case "status_update":
                    
                    if(data.state.event_list.includes('play_answer')) {
                        if(data.state.video_type === 'answer') {
                            this.playAnswerVideoFromTimestamp(data.state.video_id,
                                data.state.video_time,data.state.idle_loop);

                            console.log("playing answer video: ", data.state.video_id, data.state.video_time);
                            console.log("event_list: ", data.state.event_list);

                            if(data.state.mic_blocked) {
                                this.recorderSetMicBlocked();
                            } else {
                                this.recorderSetMicReady();
                            }
                        } else if(data.state.video_type === 'idle') {
                            this.playIdleLoop(data.state.idle_loop);
                            this.firstRendered = false;
                            console.log("play idle loop ", data.state.idle_loop); 
                        }

                        this.firstRendered = false;
                    }
                    else if(data.state.event_list.includes('skip_answer_event')) {
                        this.stopAnswer();
                        console.log("##### answer skipped");
                    }

                    if(data.state.event_list.includes('show_popup')) {
                        this.recorderShowSelectRespondentPopup(this.respondent_list);
                    }

                    if(data.state.event_list.includes('mic_event')) {
                        console.log('mic event', data.state.mic_blocked);

                        if(data.state.mic_blocked) {
                            this.recorderSetMicBlocked();
                        } else {
                            this.recorderSetMicReady();
                        }
                    }

                    if(data.state.event_list.includes('token_error')) {
                        this.showErrorPopup("token_error");
                        console.log("Token ERROR");
                    } else if(data.state.event_list.includes('df_request_error')) {
                        this.showErrorPopup("df_request_error");
                        console.log("DF Request ERROR");
                    }

                    if(data.state.event_list.includes('change_session_details') 
                        || this.firstRendered === true) {
                        if(this.state.currentApp === 'controls' 
                            || this.state.currentApp === 'integrated') {
                            if (this.appControlsRef.current){
                                var details = {
                                    expiresBy: this.appControlsRef.current.state
                                        .sessionDetails.expiresBy,
                                    numberOfDevices: this.appControlsRef.current.state
                                        .sessionDetails.numberOfDevices,
                                    options: {
                                        microphonesEnabled: data.state.mic_blocking,
                                        showQuestions: data.state.subtitle_showing
                                    }
                                };
                                this.appControlsRef.current.setSessionDetails(details);
                            }
                            if (this.appPlayerRef.current){
                                this.appPlayerRef.current.setShowSubtitles(data.state.subtitle_showing);
                            }
                        }
                    }
                    
                    if(this.firstRendered === true){
                        this.playIdleLoop(data.state.idle_loop);
                        this.firstRendered = false;

                        console.log("!! playing Idle Loop");
                    }

                    if (this.state.currentApp === 'player' 
                        || this.state.currentApp === 'controls' 
                        || this.state.currentApp === 'integrated') {

                        this.videoState = {
                            video_id: data.state.video_id,
                            video_length: data.state.video_length,
                            video_time: data.state.video_time,
                            video_type: data.state.video_type,
                            video_question: data.state.video_question,
                            query_text: data.state.query_text,
                            intent_confidence: data.state.intent_confidence,
                        }

                        this.deviceList = data.state.device_list.slice();

                        if (this.appControlsRef.current){
                            this.appControlsRef.current.setVideoState(this.videoState);
                            this.appControlsRef.current.setDeviceList(this.deviceList)
                        }

                        if (this.appPlayerRef.current){
                            this.appPlayerRef.current
                                .setVideoQuestion(this.videoState.video_question);
                        }
                    }
                    // update video dbg info IF we are in the player App
                    let video_dbg = {};
                    if (this.state.currentApp === 'player' 
                        || this.state.currentApp === 'integrated') {
                        video_dbg = this.getVideoPlayerInfo();
                    }

                    // set userAgent
                    if (navigator !== undefined){
                        video_dbg.user_agent = navigator.userAgent;
                        this.server.setVideoDbg(video_dbg);
                    }

                    break;


                default:
                    break;
            }
        }


        //if(data === "credentialsACK") { 
        //    console.log("ACK of credential in appLoader"); 
        //    this.setState({waitingForServer: false});
        //    if (this.state.currentApp === 'integrated') { 
        //        //no selection over appSelector, set app type directly
        //        this.setSelectedApp('integrated');
        //    }
        //}
        //else if(data === "credentialsNACK"){
        //    this.setState({waitingForServer: "credentialsNACK"});
        //    //remove cookies
        //    localStorage.removeItem('loginData');
        //    localStorage.removeItem('currentApp');
        //    //return to login
        //    this.props.history.push({
        //        pathname: '/session/login/' + this.state.loginData.id, 
        //        state: {
        //            sessionKey: this.state.loginData.key, 
        //            errortype: "loginrefused"
        //        }
        //    });
        //}
        //else if(data === "ack_audio_request") {
        //    this.recorderSetProcessingDone();
        //}
        //else if(data === "nack_audio_request") {
        //    this.recorderSetProcessingError();
        //}
        //else if(data === "bad_audio_popup") {
        //    this.showErrorPopup("badaudio");
        //}
        //else if(data === "no_match_popup") {
        //    this.showErrorPopup("nomatch");
        //}
        //else if(data === "failed_audio_request") {
        //    // TODO show correct error message
        //    this.recorderSetProcessingError();
        //    setTimeout(this.recorderSetMicReady, 2000);
        //}
        //else if(data === "serverError"){
        //    this.setState({waitingForServer: "serverError"});
        //}
        ///*else if(data === "playAnswer"){
        //    this.playAnswerVideo("https://localhost:3000/video/KRAUSE_0023",
        //        "https://localhost:3000/video/KRAUSE_0023");
        //}*/
        //else if(data === "connection_lost") {
        //    this.setState({
        //        connectionLost: true
        //    });
        //}
        //else if(data === "connected") {
        //    //this.setState({waitingForServer: false});
        //    //if (this.state.currentApp === 'integrated'){ 
        //    //    //no selection over appSelector, set app type directly
        //    //    this.setSelectedApp('integrated');
        //    //}
        //}
        //else if(data === "reconnected") {
        //    console.log("reconnected");
        //    this.setState({
        //        connectionLost: false
        //    });
        //    this.recorderSetMicReady();
        //    //TODO setting dev_type or setting auswahl
        //}
        //if(data.type === "video_info") {
        //    console.log(data.video);
        //    this.setState({
        //        videoUrlPrefix: data.video.url_prefix,
        //        videoUrlPostfix2D: data.video.url_postfix_2D,
        //        videoUrlPostfix3D: data.video.url_postfix_3D,
        //    });
        //}
        //else if(data.type === "show_respondent_list") {
        //    console.log("respondent list in appLoader: ", data.list);
        //    this.respondent_list = data.list;
        //    this.recorderShowSelectRespondentPopup(data.list);
        //}
        //else if(data.type === "status_update") {
        //    if(data.state.event_list.includes('play_answer')) {
        //        this.playAnswerVideoFromTimestamp(data.state.video_id,
        //            data.state.video_time,data.state.idle_loop);

        //        console.log("playing answer video: ", data.state.video_id);
        //        console.log("event_list: ", data.state.event_list);

        //        this.firstRendered = false;
        //    }
        //    else if(data.state.event_list.includes('skip_answer_event')) {
        //        this.stopAnswer();
        //        console.log("##### answer skipped");
        //    }

        //    if(data.state.event_list.includes('show_popup')) {
        //        this.recorderShowSelectRespondentPopup(this.respondent_list);
        //    }

        //    if(data.state.event_list.includes('mic_event')) {
        //        console.log('mic event', data.state.mic_blocked);

        //        if(data.state.mic_blocked) {
        //            this.recorderSetMicBlocked();
        //        } else {
        //            this.recorderSetMicReady();
        //        }
        //    }

        //    if(this.firstRendered === true){
        //        this.playIdleLoop(data.state.idle_loop);
        //        this.firstRendered = false;

        //        console.log("!! playing Idle Loop");
        //    }

        //    if (this.state.currentApp === 'player' 
        //        || this.state.currentApp === 'controls' 
        //        || this.state.currentApp === 'integrated') {

        //        this.videoState = {
        //            video_id: data.state.video_id,
        //            video_length: data.state.video_length,
        //            video_time: data.state.video_time,
        //            video_type: data.state.video_type,
        //            video_question: data.state.video_question,
        //            query_text: data.state.query_text,
        //            intent_confidence: data.state.intent_confidence,
        //        }

        //        this.deviceList = data.state.device_list.slice();

        //        if (this.appControlsRef.current){
        //            this.appControlsRef.current.setVideoState(this.videoState);
        //            this.appControlsRef.current.setDeviceList(this.deviceList)
        //        }

        //        if (this.appPlayerRef.current){
        //            this.appPlayerRef.current.setVideoQuestion(this.videoState.video_question);
        //        }

        //    }

        //    // update video dbg info IF we are in the player App
        //    let video_dbg = {};
        //    if (this.state.currentApp === 'player' || this.state.currentApp === 'integrated') {
        //        video_dbg = this.getVideoPlayerInfo();
        //    }

        //    // set userAgent
        //    if (navigator !== undefined){
        //        video_dbg.user_agent = navigator.userAgent;
        //        this.server.setVideoDbg(video_dbg);
        //    }
        //}
        //else if(data.type === "flagged_answers_download") {
        //    this.appControlsRef.current.handleDownloadFlaggedListReady(data.data);
        //}
    }

    stopAnswer()
    {
        if (this.appPlayerRef.current===null) return;
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return;
        this.appPlayerRef.current.videoPlayerRef.current.videoState.playingVideo=false;
    }

    handleAudioRecording(chunk){
        console.log(chunk);
        var data = { 
            command: 'audio_request',
            data: {
                agent: chunk.meta,
                audio_input: btoa(String.fromCharCode.apply(null, chunk.chunk[0]))
            }
        };
        this.server.sendAudio(data);
    }

    async getText(chunk) {
	    var t = await chunk.text();
	    return t;
    }

    handleTextInput(chunk){
        //TODO: modify code to your needs depending on your backend, please :).
        console.log(chunk);
	//var reader = new FileReader();
	//reader.readAsText(chunk.chunk);
	//var text = reader.result;
	var text = chunk.chunk
	console.log(text);
        var data = { 
            command: 'text_request',
            data: {
                agent: chunk.meta,
                audio_input: text
                //audio_input: btoa(String.fromCharCode.apply(null, chunk.chunk[0]))
            }
        };
	console.log(data);
        this.server.sendAudio(data);        
    }


    createRandomKey(totalLength, blockLength){
        if (totalLength < 1 || blockLength < 1) {
            return;
        }

        var randomString
        var randomStringLength;
        var randomKey = '';
        var i = 0;

        //create random string of sufficient length
        randomString = Math.random().toString(36).slice(2);
        randomStringLength = randomString.length;
        while (randomStringLength < totalLength){
            randomString = randomString + Math.random().toString(36).slice(2);
            randomStringLength = randomString.length;
        }

        randomString = randomString.toUpperCase();

        //create blocks
        if (blockLength >= totalLength){
            randomKey = randomString.substring(0, totalLength);
        } else {
            while(i + blockLength < totalLength){
                randomKey = randomKey + randomString.substring(i,i+blockLength);
                i = i + blockLength;
                randomKey = randomKey + '-';
            }
            randomKey = randomKey + randomString.substring(i,totalLength);

        }

        return randomKey
    }

    
    handleSelectRespondent(name){
        /* TODO: sent name of responder to backend */
        this.server.select_respondent(name);
        console.log(">>> Callback information selection: ", name)
    }

    recorderSetMicReady() {
        if (this.appRecorderRef.current) {
            this.appRecorderRef.current.setMicrophoneReady()
        }
    }

    recorderSetMicBlocked(){
        if (this.appRecorderRef.current) {
            this.appRecorderRef.current.setMicrophoneBlocked()
        }
    }

    recorderSetProcessingDone() {
        if (this.appRecorderRef.current) {
            this.appRecorderRef.current.setProcessingDone();
        }
        setTimeout(this.unblockMic, 2000);
    }

    recorderSetProcessingError() {
        if (this.appRecorderRef.current) {
            this.appRecorderRef.current.setProcessingError();
        }
    }

    recorderShowSelectRespondentPopup(respondentList){
        //open selector popup with list of selected respondents to choose from. 
        //If list is empty, then portraitList prop of appRecorder is used as default
        if (this.appRecorderRef.current) {
            this.appRecorderRef.current.showSelectorPopup(respondentList);
        }
    }

    recorderSetLocalAudioTestingMode()
    {
        if (this.appRecorderRef.current) {
            this.appRecorderRef.current.setLocalAudioTestingMode();
        }
    }

    handleControlsEvents(event, params=undefined){
        //console.log([event, params])
        switch (event) {
            case 'skip_answer': 
                var data = {
                    command: "skip_answer",
                    /*parameters:{
                        life_time: "",
                        sess_id: "",
                        sess_key: "",
                        dev_name: "",
                        client_id: "",
                        sess_context: "",
                        dev_type: "",
                        mic_blocking: true
                    }*/
                };
                this.server.sendData(data);

                //TODO
                break;
            case 'play_pause': 
                //TODO
                break;
            case 'flag_answer':
                //TODO: params: query_text of last question
                console.log('Query text: ' + params)
                var data = {
                    command: "flag_answer",
                    query_text: params
                };
                this.server.sendData(data);

                break;
            case 'download_flagged_list':
                //TODO: request list from server and call handleDownloadFlaggedListReady(list)
                //, when ready
                
                //console.log('download flagged answers');
                var data = {
                    command: "download_flagged_list",
                };
                this.server.sendData(data);
                ////For demo, use a simple timer instead.
                //var testlist = "Dieses Feature ist noch nicht vollständig implementiert. Bitte benutzen Sie zum aktuellen Zeitpunkt den Flaggen-Knopf um die jeweils aktuelle Anfrage in die Zwischenablage zu kopieren."
                //if (this.appControlsRef.current){
                //    setTimeout(() => this.appControlsRef.current.handleDownloadFlaggedListReady(testlist), 2000);
                //}
                break;
            case 'play_video':
                this.server.play_video_file(params) 
                break;
            case 'toggle_session_options':
                //TODO: params: 'microphonesEnabled' or 'showQuestions', on reply from server just update the sessionDetails props of the appControls
                console.log('Toggle session options for option: ' + params)
                /*var toggle_buttons = {
                    mic_blocking: true,
                    subtext_showing: true,
                };*/

                switch(params) {
                    case 'microphonesEnabled':
                        var data = {
                            command: "",
                        };
                        console.log("toggle button: ", this.appControlsRef.current.state
                            .sessionDetails.options.microphonesEnabled);
                        if(this.appControlsRef.current.state
                            .sessionDetails.options.microphonesEnabled) {
                            data.command = "mic_unblocking";
                        } else {
                            data.command = "mic_blocking";
                        }
                        this.server.sendData(data);
                        break;
                    
                    case 'showQuestions':
                        var data = {
                            command: "",
                        };
                        console.log("toggle button: ", this.appControlsRef.current.state
                            .sessionDetails.options.showQuestions);
                        if(this.appControlsRef.current.state
                            .sessionDetails.options.showQuestions) {
                            data.command = "subtitle_not_showing";
                        } else {
                            data.command = "subtitle_showing";
                        }
                        this.server.sendData(data);
                        break;
                }
                break;
            case 'delete_session':
                var data = {
                    command: "delete_session"
                };
                console.log("Delete Button pressed");
                this.server.sendData(data);
                break;
            default: 
                //no recognized event
                return;
         }
    }

    playAnswerVideo(answerURL, nextIdleLoopURL)
    {
        if (this.appPlayerRef.current===null) return;
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return;
        this.appPlayerRef.current.videoPlayerRef.current.playAnswer(answerURL, nextIdleLoopURL);
    }

    playAnswerVideoFromTimestamp(answerURL, timestamp, nextIdleLoopURL)
    {
        if (this.appPlayerRef.current===null) return;
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return;
        this.appPlayerRef.current.videoPlayerRef.current.playAnswerFromTimestamp(answerURL, timestamp, nextIdleLoopURL);
    }

    playIdleLoop(IdleLoopURL)
    {
        if (this.appPlayerRef.current===null) return;
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return;
        this.appPlayerRef.current.videoPlayerRef.current.playIdleLoop(IdleLoopURL);
    }
    
    playIdleLoopDelayed(IdleLoopURL, delayed)
    {
        if (this.appPlayerRef.current===null) return;
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return;
        this.appPlayerRef.current.videoPlayerRef.current.playIdleLoopDelayed(IdleLoopURL, delayed);
    }

    changeVideoBandwidthOverride(bytePerSec)
    {
        if (this.appPlayerRef.current===null) return;
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return;
        this.appPlayerRef.current.videoPlayerRef.current.setState({speedOverride : bytePerSec});
        this.speedOverride=bytePerSec;
    }

    getVideoPlayerInfo()
    {
        if (this.appPlayerRef.current===null) return {};
        if (this.appPlayerRef.current.videoPlayerRef.current===null) return {};
        return this.appPlayerRef.current.videoPlayerRef.current.playerInfo;
    }

    showErrorPopup(errortype){
        //errortype can be "badaudio" or "nomatch"
        if (this.errorPopupRef.current){
            this.errorPopupRef.current.showPopup(errortype);
        }
    }

    renderApps(){
        if (this.state.waitingForServer === true){
            return (<h1>waiting for server...</h1>); //TODO: add animation
        }
        else if (this.state.waitingForServer === "credentialsNACK"){
            return (<h1>login credentials not accepted...</h1>); //TODO: what to do now?
        }
        else if (this.state.waitingForServer === "serverError"){
            return (<h1>server not responding...</h1>); //TODO: what to do now?
        }
        else if (this.state.currentApp === ''){
            return(<AppSelector 
                        handleLogout={this.handleLogout} 
                        handleReload={this.handleReload}
                        setSelectedApp={this.setSelectedApp}
                        setVideoFormat3D={this.setVideoFormat3D} 
                        userType={this.state.permissions}
                        width={this.state.width} height={this.state.height}/>)
        } else if (this.state.currentApp === 'recorder'){
            return(<AppRecorder 
                        ref={this.appRecorderRef}
                        handleSelectRespondent = {this.handleSelectRespondent}
                        handleLogout={this.handleLogout} 
                        handleReload={this.handleReload}
                        handleAudioRecording={this.handleAudioRecording}
                        handleTextInput={this.handleTextInput} 
                        width={this.state.width} height={this.state.height}/>)
        } else if (this.state.currentApp === 'player'){
            return(<AppPlayer 
                        handleLogout={this.handleLogout} 
                        handleReload={this.handleReload}
                        ref={this.appPlayerRef} 
                        speedtestURL={this.state.speedtestURL}
                        speedOverride={this.speedOverride}
                        width={this.state.width}
                        height={this.state.height}
                        videoDimensions3D={this.state.videoIs3D}
                        videoUrlPrefix={this.state.videoUrlPrefix}
                        videoUrlPostfix2D={this.state.videoUrlPostfix2D}
                        videoUrlPostfix3D={this.state.videoUrlPostfix3D}
                        videoCropWidth={this.state.videoCropWidth}
                        videoCropOffset={this.state.videoCropOffset}
                    />)
        } else if (this.state.currentApp === 'controls'){
            return(<AppControls 
                        ref={this.appControlsRef}
                        handleLogout={this.handleLogout} 
                        handleReload={this.handleReload}
                        handleEvents={this.handleControlsEvents} 
                        loginData={this.state.loginData} 
                        width={this.state.width} height={this.state.height}/>)
        } else if (this.state.currentApp === 'integrated'){
            return(<IntegratedView 
                        appRecorderRef={this.appRecorderRef}
                        handleSelectRespondent = {this.handleSelectRespondent}
                        handleLogout={this.handleLogout}
                        handleReload={this.handleReload}
                        handleControlsEvents={this.handleControlsEvents} 
                        handleAudioRecording={this.handleAudioRecording} 
                        handleTextInput={this.handleTextInput}
                        loginData={this.state.loginData}
                        width={this.state.width} height={this.state.height}
                        appPlayerRef={this.appPlayerRef}
                        appControlsRef={this.appControlsRef}
                        videoUrlPrefix={this.state.videoUrlPrefix}
                        videoUrlPostfix2D={this.state.videoUrlPostfix2D}
                        videoUrlPostfix3D={this.state.videoUrlPostfix3D}
                        videoCropWidth={this.state.videoCropWidth}
                        videoCropOffset={this.state.videoCropOffset}
                        speedtestURL={this.state.speedtestURL}
                        mode={this.state.permissions}/>)
        } else {
            console.log("Requested App Type not implemented. Return Home")
            this.props.history.push("/")
        }
    }

    render() {
        return (
            <main className="app-body" style={{height: this.state.height}}>
                {this.renderApps()}
                <ErrorPopup ref={this.errorPopupRef}/>
                <WaitingForServerOverlay visible={this.state.connectionLost || this.state.waitingForServer}/>
                <div style={{display: 'flex', overflow: 'hidden', position: 'absolute', bottom: '0', left:'0', zIndex: '10'}}>
                    {/*<button className="btn btn-secondary" onClick={() => this.setState({connectionLost: !this.state.connectionLost})}>connection lost</button>*/}
                    {(this.state.currentApp === 'player' || this.state.currentApp === 'integrated') ? 
                        
                        /*
                        <button className="btn btn-secondary" onClick={() => this.playAnswerVideo("KRAUSE_0020","KRAUSE_IDLE_LOOP_1")}>KR20</button>
                        <button className="btn btn-secondary" onClick={() => this.playAnswerVideo("KWIEK_0021","KWIEK_IDLE_LOOP_1")}>KW21</button>
                        <button className="btn btn-secondary" onClick={() => this.playAnswerVideoFromTimestamp("KRAUSE_0021",5.0,"KRAUSE_IDLE_LOOP_1")}>Ans 5sec</button>
                        <button className="btn btn-secondary" onClick={() => this.playIdleLoop("KRAUSE_IDLE_LOOP_1")}>Idle Loop</button>
                        */
                        
                        null 
                        /*
                        <button className="btn btn-secondary" onClick={() => console.log(this.getVideoPlayerInfo())}>Pl. Info</button>
                        <button className="btn btn-secondary" onClick={() => this.changeVideoBandwidthOverride(100*1024)}>100k</button>
                        <button className="btn btn-secondary" onClick={() => this.changeVideoBandwidthOverride(500*1024)}>500k</button>
                        <button className="btn btn-secondary" onClick={() => this.changeVideoBandwidthOverride(1024*1024)}>1M</button>
                        <button className="btn btn-secondary" onClick={() => this.changeVideoBandwidthOverride(5*1024*1024)}>5M</button>
                        <button className="btn btn-secondary" onClick={() => this.changeVideoBandwidthOverride(10*1024*1024)}>10M</button> */
                    : null }
                    {(this.state.currentApp === 'recorder' || this.state.currentApp === 'integrated')? 
                        <React.Fragment>
                            {/*}
                        <button className="btn btn-secondary" onClick={this.recorderSetProcessingDone}>Set Processing Done</button>
                        <button className="btn btn-secondary" onClick={this.recorderSetMicReady}>Release Mic</button>
                        <button className="btn btn-secondary" onClick={() => this.recorderShowSelectRespondentPopup(["diepold", "anton", "falta", "krause", "kwiek"])}>Show Popup</button>
                        <button className="btn btn-secondary" onClick={() => this.recorderSetLocalAudioTestingMode()}>Audiotest</button>
                    {*/}
                        </React.Fragment>
                    : null }

                </div>
            </main>
        );
    };
}
