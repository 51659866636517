import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import ModalPopup from './modalPopup.js';

import LogoutIcon from '../icons/logout_icon_bold.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class LogoutButton extends React.Component {
    static defaultProps = {
        size: 50,
        gap: 0,
        windowWidth: Math.min(window.innerWidth, 640),
        visibility: true,
        handleLogout: undefined,
        handleReload: undefined,
        iconFilter: "filter-lightgrey",
    };

    static propTypes = {
        size: PropTypes.number,
        gap: PropTypes.number,
        windowWidth: PropTypes.number,
        visibility: PropTypes.bool,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        iconFilter: PropTypes.string,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);
        this.state = {
            popupOpen: false,
        }
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleReload = this.handleReload.bind(this);
    };

    handleOpen(){
        this.setState({popupOpen: true});
    }

    handleClose(){
        this.setState({popupOpen: false});
    }

    handleLogout(){
        this.handleClose();
        this.props.handleLogout();
    }

    handleReload(){
        this.handleClose();
        this.props.handleReload();
    }

    render(){
        return (
            <>
            <button className="btn-secondary-nofilter position-absolute" 
                style={{
                    zIndex: this.props.zIndex,
                    top: this.props.gap, 
                    right: this.props.gap, 
                    width: this.props.size, 
                    height: this.props.size,
                    minHeight: this.props.size,
                    minWidth: this.props.size,
                    padding: '0',
                    paddingTop: '2px',
                    visibility: this.props.visibility ? 'visible' : 'hidden'}}
                onClick={this.handleOpen}>
                <img 
                    className={this.props.iconFilter}
                    id="logoutimg" 
                    src={LogoutIcon} 
                    alt="Logout Icon" 
                    width="80%"/>
            </button>
            <ModalPopup 
                title = {config.copy.logout.title}
                msg = {config.copy.logout.body}
                abortButtonText = {config.copy.logout.reload}
                actionButtonText = {config.copy.logout.logout}
                open = {this.state.popupOpen}
                maxwidth = {this.props.windowWidth}
                handleSecondaryAction = {this.handleReload}
                handlePrimaryAction = {this.handleLogout}
                handleCancel = {this.handleClose}/>
            </>
        );
    }
}
