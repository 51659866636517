import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import CloseIcon from '../icons/close_icon_bold.svg';

export default class ModalPopup extends React.Component {
    static defaultProps = {
        title: '',
        msg: '',
        abortButtonText: 'Abbrechen',
        actionButtonText: 'OK',
        open: false,
        maxwidth: window.innerWidth,
        handleSecondaryAction: undefined,
        handlePrimaryAction: undefined,
        handleCancel: undefined,
    };

    static propTypes = {
        title: PropTypes.string,
        msg: PropTypes.string,
        abortButtonText: PropTypes.string,
        actionButtonText: PropTypes.string,
        open: PropTypes.bool,
        maxwidth: PropTypes.number,
        handleSecondaryAction: PropTypes.func,
        handlePrimaryAction: PropTypes.func,
        handleCancel: PropTypes.func,
    };

    constructor(props) {
        super(props);
        //set handleCancel or default to handleSecondaryAction
        if (this.props.handleCancel === undefined) {
            this.handleCancel = this.props.handleSecondaryAction;
        } else {
            this.handleCancel = this.props.handleCancel;
        }
    }

    render(){
        return (
            <div
                className="modal-background"
                style={{display: this.props.open ? 'flex' : 'none'}}>
                <div className="modal-foreground flex-container-column" style={{maxWidth: this.props.maxwidth}}>
                    <div className="flex-container-row" style={{justifyContent: 'space-between'}}>
                        <label className="label-onbackground">{this.props.title}</label>
                        <img 
                            onClick={this.handleCancel}
                            src={CloseIcon} 
                            alt="Close" 
                            className="filter-mediumgrey modal-close-icon"/>
                    </div>
                    <div className="flex-container-row modal-itemcontainer">
                        <p className="label-onbackground">
                            {this.props.msg}
                        </p>    
                    </div>
                    <div className="flex-container-row modal-buttoncontainer">
                        <button className="btn btn-secondary" onClick={this.props.handleSecondaryAction}>
                            {this.props.abortButtonText}
                        </button>
                        <button className="btn btn-primary" onClick={this.props.handlePrimaryAction}>
                            {this.props.actionButtonText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}