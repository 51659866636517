import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import { Link } from 'react-router-dom'

import HomeIcon from '../icons/home_icon.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class PageNotFound extends React.Component {
    static defaultProps = {
        maxWidth: 640,
    };

    static propTypes = {
        maxWidth: PropTypes.number,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
        
        this.handleResize = this.handleResize.bind(this);

    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleResize(){
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    render() {
        return (
            <main className="app-body" style={{height: '100vh'}}>
                <div className="flex-container-column" style={{maxWidth: this.props.maxWidth, padding: '25px'}}>
                
                    <h1>{config.copy.pagenotfound.title}</h1>
                    <label className="label-onbackground">{config.copy.pagenotfound.body}</label>

                    <Link
                        to="/"
                        className="btn btn-primary" 
                        type="submit" 
                        style={{width: '100%', flex: '1 1 0px', padding:'0px', marginTop: '30px', align: 'center'}}>
                        <img
                            src={HomeIcon} 
                            alt="Home Icon" 
                            height="90%"
                            style={{marginRight:"5px"}}/>
                        {config.copy.pagenotfound.home}
                    </Link>

                </div>
            </main>
        );
    };
}
