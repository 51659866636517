import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import CarouselSelector from './carouselSelector.js';
import LogoutButton from '../utils/logoutButton.js';
import PopupSelector from './popupSelector.js';
import TextInputButton from './textInputButton.js';
import MicInput from './micInput.js';

// Import styles
import './appRecorder.css';
import defaultPortraitList from './defaultPortraitList.json'

import globalConfig from '../globalConfig.js'
var config = {};

export default class AppRecorder extends React.Component {
    static defaultProps = {
        // Props that can be updated:
        height: window.innerHeight,
        width: window.innerWidth,
        // Props that are only read in constructor:
        maxWidth: 640,
        showLogoutButton: true,
        handleLogout: undefined,
        handleReload: undefined,
        handleAudioRecording: undefined,
        handleTextInput: undefined,
        handleSelectRespondent: undefined
    };

    static propTypes = {
        // Props that can be updated:
        height: PropTypes.number,
        width: PropTypes.number,
        // Props that are only read in constructor:
        maxWidth: PropTypes.number,
        showLogoutButton: PropTypes.bool,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        handleAudioRecording: PropTypes.func,
        handleTextInput: PropTypes.func,
        handleSelectRespondent: PropTypes.func,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            componentShouldUpdate: false,
            selectedPortraitCaroussel: 1,
            audioTestMode : false,
            portraitList: defaultPortraitList.list,
            micBlocked: false,
        }
        this.handleSelectTarget = this.handleSelectTarget.bind(this);
        this.wrapAudioRecording = this.wrapAudioRecording.bind(this);
        this.wrapTextInput = this.wrapTextInput.bind(this);
        this.setProcessingDone = this.setProcessingDone.bind(this);
        this.setProcessingError = this.setProcessingError.bind(this);
        this.setMicrophoneReady = this.setMicrophoneReady.bind(this);
        this.setMicrophoneBlocked = this.setMicrophoneBlocked.bind(this);
        this.showSelectorPopup = this.showSelectorPopup.bind(this);
        this.setPortraitList = this.setPortraitList.bind(this);
        this.getAudioMeta = this.getAudioMeta.bind(this);

        this.micInputRef = React.createRef();
        this.popupSelectorRef = React.createRef();
        this.carouselSelector = React.createRef();
    };

    componentDidMount() {
        document.addEventListener("contextmenu", function(evt){
            evt.preventDefault();
          }, false);
          document.addEventListener("touchmove", function(evt){
            evt.preventDefault();
          }, {passive: false});          
    };

    componentWillUnmount() {
        document.removeEventListener("contextmenu", function(evt){
            evt.preventDefault();
          }, false);
          document.removeEventListener("touchmove", function(evt){
            evt.preventDefault();
          }, {passive: false});
    }; 

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.componentShouldUpdate === true) {
            this.setState({componentShouldUpdate: false})
            return true;
        }
        if ((this.state.selectedPortraitCaroussel !== nextState.selectedPortraitCaroussel) ||
            (this.state.audioTestMode !== nextState.audioTestMode)) {
            return true;
        }
        //update only on resize events to prevent flickering
        if ((nextProps.height !== this.props.height) ||
            (nextProps.width !== this.props.width)){
            return true;
        } else {
            return false;
        }
    }

    handleSelectTarget (val) {
        this.setState({selectedPortraitCaroussel: val});
    }

    getAudioMeta(){
        var meta;

        if (this.state.selectedPortraitCaroussel === 1){ //default to first entry of list
            meta = this.state.portraitList[0].id
        } else {
            if (this.carouselSelector.current) {
                meta = this.carouselSelector.current.getSelected();
            } else {
                meta = this.state.portraitList[0].id //default fallback
            }
        }

        //TODO: remove this line to use correct meta data instead
        //var meta = 'Krause'
        //if(meta === 'wittmann') {
            //&& meta != 'krause' && meta != 'group' && meta != 'broschinski'
            //&& meta != 'falta' && meta != 'diepold') {
        //    meta = 'krause';
        //} 
        
        return meta;
    }

    wrapAudioRecording(chunk){
        var meta = this.getAudioMeta();
        var wrappedChunk = {'chunk': chunk, 'meta': meta}
        if (this.props.handleAudioRecording){
            this.props.handleAudioRecording(wrappedChunk);
        }
    }

    wrapTextInput(text){
        var meta = this.getAudioMeta();
        var chunk = new Blob([text], {type: "application/octet-stream"})
        var wrappedChunk = {'chunk': text, 'meta': meta}
        //var wrappedChunk = {'chunk': chunk, 'meta': meta}
        if (this.props.handleTextInput){
            this.props.handleTextInput(wrappedChunk);
        }
    }

    setMicrophoneReady() {
        if (this.micInputRef.current) {
            this.micInputRef.current.setMicrophoneReady()
        }
        this.setState({
            componentShouldUpdate: true,
            micBlocked: false
        })
    }

    setProcessingDone() {
        if (this.micInputRef.current) {
            this.micInputRef.current.setProcessingDone();
        }
    }

    setProcessingError() {
        if (this.micInputRef.current) {
            this.micInputRef.current.setProcessingError();
        }
    }

    setMicrophoneBlocked() {
        if (this.micInputRef.current) {
            this.micInputRef.current.setMicrophoneBlocked();
        }
        this.setState({
            componentShouldUpdate: true,
            micBlocked: true
        })
    }

    showSelectorPopup(respondentList){
        //TODO: decide how to pass the list of portraits to show
        if (this.popupSelectorRef.current) {
            this.popupSelectorRef.current.handleOpen(respondentList);
        }
    }

    setPortraitList(portraitList){
        //note: this only sets a reference to the list
        this.setState({
            componentShouldUpdate: true,
            portraitList: portraitList
        })
        if (this.carouselSelector.current) {
            //hand down to carouselSelector
            this.carouselSelector.current.setPortraitList(portraitList.slice(1,portraitList.length));
        }
    }

    setLocalAudioTestingMode(){
        this.setState({audioTestMode : true});
    }

    render() {

        var outerPadding = 8;
        var recorderWidth = Math.min(this.props.width, this.props.maxWidth);
        var recorderInnerWidth = recorderWidth - 2*outerPadding;

        
        const topLabelHeight = 35;
        
        //leave space for portrait or selector, if there is at least one image in the portraitlist
        var toggleButtonHeight, marginCarousel;
        if (this.state.portraitList.length > 1) {
            toggleButtonHeight = 50;
            marginCarousel = 16;
        } else {
            toggleButtonHeight = 0;
            marginCarousel = 0;
        }

        //leave space for text Input Button, if enabled in config
        var textInputButtonHeight = 0;
        if (config.configflags.includes("enable_text_requests")){
            textInputButtonHeight = topLabelHeight;
        }

        const arrowWidth = recorderInnerWidth * 0.1;
        const audioCanvasMinHeight = 160;

        const toggleButtonTop = topLabelHeight;
        const pictureSelectorTop = toggleButtonHeight + toggleButtonTop;
        const pictureSelectorWidth = recorderInnerWidth - 2*arrowWidth;
        
        var pictureSelectorHeight, audioCanvasTop, audioCanvasHeight;
        if (this.state.portraitList.length === 1 && this.state.portraitList[0].imgUrl === "") {
            pictureSelectorHeight = 0;
            audioCanvasTop = toggleButtonTop;
            audioCanvasHeight = this.props.height - toggleButtonTop - textInputButtonHeight;
        } else {
            audioCanvasHeight = Math.max(this.props.height - textInputButtonHeight - (pictureSelectorWidth * 0.75 + pictureSelectorTop), audioCanvasMinHeight);
            audioCanvasTop = this.props.height - audioCanvasHeight - textInputButtonHeight;
            pictureSelectorHeight = audioCanvasTop - pictureSelectorTop - 2*marginCarousel;
        }

        return (
            <div className="app-body" style={{height: this.props.height, width: this.props.width, padding:outerPadding}}>
                <div className="flex-container-column"
                    style={{width: '100%', maxWidth: recorderInnerWidth, height: this.props.height}}>
                    <div className="flex-container-row" style={{minHeight: topLabelHeight}}>
                        <label className="label-onbackground">{config.copy.recorder.title}</label>
                        {this.props.showLogoutButton ? 
                        <LogoutButton handleLogout={this.props.handleLogout} handleReload={this.props.handleReload} size={topLabelHeight} iconFilter="filter-lightgrey"/>
                        : null }
                    </div>
                    <div className="flex-container-row"
                        style={{height: toggleButtonHeight, display: this.state.portraitList.length > 1 ? 'flex' : 'none'}}>
                        <button className={this.state.selectedPortraitCaroussel === 1 ? 
                            "btn btn-primary btn-group-left recorder-toggle-btn btn-primary-pressed " : "btn btn-primary btn-group-left recorder-toggle-btn"}
                            onClick={() => this.handleSelectTarget(1)}>{config.copy.recorder.all}</button>
                        <button className={this.state.selectedPortraitCaroussel === 2 ? 
                            "btn btn-primary btn-group-right recorder-toggle-btn btn-primary-pressed" : "btn btn-primary btn-group-right recorder-toggle-btn"}
                            onClick={() => this.handleSelectTarget(2)}>{config.copy.recorder.choose}</button>
                    </div>
                    <div className="flex-container-column" 
                        style={{
                            height: pictureSelectorHeight,
                            maxHeight: pictureSelectorHeight,
                            marginTop: marginCarousel}}>
                        {this.state.portraitList.length > 1 ?
                        <CarouselSelector 
                            ref={this.carouselSelector}
                            height={pictureSelectorHeight} 
                            width={recorderWidth}
                            visibility={this.state.selectedPortraitCaroussel === 1 ? 'hidden' : 'visible'}
                            buttonWidth={arrowWidth}
                            defaultPortraitList = {defaultPortraitList.list.slice(1,defaultPortraitList.list.length)}/>
                        : null }
                        <div className="flex-container-column recorder-portrait-img-container position-absolute"
                            style={{
                                height: pictureSelectorHeight,
                                visibility: this.state.selectedPortraitCaroussel === 1 ? 'visible' : 'hidden'}}>
                            <img 
                                id="groupimage" 
                                src={this.state.portraitList[0].imgUrl} 
                                alt="group portrait" 
                                height={pictureSelectorHeight}
                                draggable="false"
                                onError={(e) => console.log(e.target.style.display = 'none')}/>
                        </div>
                    </div>
                </div>
                <MicInput ref={this.micInputRef} width={recorderInnerWidth} height={audioCanvasHeight} handleAudioRecording={this.wrapAudioRecording} audioTestMode={this.state.audioTestMode}/>
                {textInputButtonHeight > 0 ?
                    <TextInputButton 
                        buttonHeight={textInputButtonHeight} 
                        width={recorderInnerWidth} 
                        label={config.copy.recorder.textinput}
                        blocked={this.state.micBlocked}
                        handleSend={this.wrapTextInput}>
                    </TextInputButton>
                : null }
                <PopupSelector ref={this.popupSelectorRef} handleSelectRespondent={this.props.handleSelectRespondent} imageList = {this.state.portraitList.slice(1)} width={recorderWidth}/>
            </div>
        );
    };
}
