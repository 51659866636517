import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import PlayIcon from '../icons/play_icon_bold.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class PlayButtonOverlay extends React.Component {
    static defaultProps = {
        handleClickPlay: undefined,
        show: false,
    };

    static propTypes = {
        handleClickPlay: PropTypes.func,
        show: PropTypes.bool,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
        }
    };

    render(){
        
        return (
            <div className="modal-background" 
                onClick={this.props.handleClickPlay}
                style={{display: (this.props.show === true) ? 'flex' : 'none'}}>
                <div> 
                    <img 
                        height="80px"
                        className="filter-lightgrey"
                        src={PlayIcon} 
                        alt="play" />
                    <p>{config.copy.player.requirepressplay}</p>
                </div>
            </div>
        );
    }
}