import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

const POSITION_MOVE_STEPSIZE = 25;
const POSITION_SCALE_RATIO = 0.1;

export default class KeyboardInputController extends React.Component {
    static defaultProps = {
        registeredComponents: {}
    };

    static propTypes = {
        registeredComponents: PropTypes.shape({
            position: PropTypes.arrayOf(PropTypes.oneOfType([
                    PropTypes.object,
                    PropTypes.func,
                    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
                ])),
            vidctrl: PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.shape({ current: PropTypes.instanceOf(Element) })
            ])),
            effect3D: PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.shape({ current: PropTypes.instanceOf(Element) })
            ])),
            settings: PropTypes.func
        }),
    };

    constructor(props) {
        super(props);
        this.state = {}
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handleScale = this.handleScale.bind(this);
        this.handlePlayPause = this.handlePlayPause.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
    };

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    };
      
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    };

    handleKeyDown(event){

        if (event.isComposing || event.keyCode === 229) {//needed to catch a bug in firefox
            return;
        }
        // do something
        switch(event.keyCode){
            case 37: //left
            case 'A'.charCodeAt(0):
                this.handleMove(-POSITION_MOVE_STEPSIZE,0);
                break;
            case 38: //up
            case 'W'.charCodeAt(0):
                this.handleMove(0, -POSITION_MOVE_STEPSIZE);
                break;
            case 39: //right 
            case 'D'.charCodeAt(0):
                this.handleMove(POSITION_MOVE_STEPSIZE,0);
                break;
            case 40: //down
            case 'S'.charCodeAt(0):
                this.handleMove(0, POSITION_MOVE_STEPSIZE);
                break;
            case 171: //+
            case 187: //+
            case 107: //+ on numpad
                this.handleScale(1 + POSITION_SCALE_RATIO);
                break;
            case 173: //-
            case 189: //-
            case 109: //- on numpad
                this.handleScale(1 - POSITION_SCALE_RATIO);
                break;
            case 33: //Page up
                this.handleConvergence(1);
                break;
            case 34: //Page down
                this.handleConvergence(-1);
                break;
            case 32: //space
                this.handlePlayPause();
                break;
            case 113: // F2
                this.handleDebugInfo();
                break;
            case 27: //esc;
                this.handleEscape();
                break;
            case 'C'.charCodeAt(0):
                if (this.props.registeredComponents.settings !== undefined){
                    this.props.registeredComponents.settings();
                }
                break;
            default:
                //do nothing
                //console.log('no match for:' + event.keyCode)
        }
    }

    handleMove(deltaX, deltaY){ //position event
        var refList = this.props.registeredComponents.position;

        this.handleIterateList(refList, 'handleMove', (canvas) => {
            canvas.handleMove(deltaX, deltaY);
        })
    }

    handleScale(delta){ //position event
        var refList = this.props.registeredComponents.position;

        this.handleIterateList(refList, 'handleScale', (canvas) => {
            canvas.handleScale(delta);
        })  
    }

    handleConvergence(delta){ // 3D convergence
        var refList = this.props.registeredComponents.position;

        this.handleIterateList(refList, 'handleConvergence', (canvas) => {
            canvas.handleConvergence(delta);
        })
    }

    handleEscape(){
        var refList = this.props.registeredComponents.position;

        this.handleIterateList(refList, 'handleCloseFullscreen', (canvas) => {
            canvas.handleCloseFullscreen();
        })    
    }

    handleDebugInfo(){

        var refList = this.props.registeredComponents.vidctrl;
        this.handleIterateList(refList, 'toggleDebug', (video) => {
            video.toggleDebug();
        })            

        var refList = this.props.registeredComponents.position;
        this.handleIterateList(refList, 'toggleDebug', (video) => {
            video.toggleDebug();
        })            
    }

    handlePlayPause(){ //control event
        var refList = this.props.registeredComponents.vidctrl;

        this.handleIterateList(refList, 'play', (video) => {
            if (video.paused){
                video.play();
            } else {
                video.pause();
            }
        })
    }

    handleIterateList(refList, functionname, actioncb){
        var refListEntry;
        for (var i = 0; i < refList.length; i++) {
            if (typeof refList[i] === "function"){
                refListEntry = refList[i]();
            } else {
                refListEntry = refList[i];
            }
            if (refListEntry && refListEntry.current && typeof refListEntry.current[functionname] === "function"){
                actioncb(refListEntry.current);
            }
        }
    }

    render() {
        return (null);
    };
}