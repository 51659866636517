import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types

import * as Clipboardy from 'clipboardy'; //npm install clipboardy

// Import Components
import LogoutButton from '../utils/logoutButton.js';
import CollapseBar from './collapseBar.js';
import DeleteSessionModal from './deleteSessionModal.js';
import DeviceOverview from './deviceOverview.js';
import KeyboardInputController from '../utils/keyboardInputController.js';

// Import Icons
import PlayIcon from '../icons/play_icon.svg';
import PauseIcon from '../icons/pause_icon.svg';
import PlayNextIcon from '../icons/play_next_icon.svg';
import SmallPlayNextIcon from '../icons/play_next_icon_bold.svg';
import FlagIcon from '../icons/flag_icon_bold.svg';
import DeleteSessionIcon from '../icons/delete_session_icon.svg';
import ListAddedIcon from '../icons/listadded_icon_bold.svg';
import DownloadIcon from '../icons/download_icon.svg';
import LoadingIcon from '../icons/loading_icon.svg';

// Import Styles
import './appControls.css';

import globalConfig from '../globalConfig.js'
var config = {};

export default class AppControls extends React.Component {
    static defaultProps = {
        // Props that can be updated:
        width: window.innerWidth,
        height: window.innerHeight,
        // Props that are only read in constructor:
        maxWidth: 640,
        haveLogoutButton: true,
        handleLogout: undefined,
        handleReload: undefined,
        handleEvents: undefined,
        loginData: undefined,
        defaultIntroVersion: 'short',
    };

    static propTypes = {
        // Props that can be updated:
        width: PropTypes.number,
        height: PropTypes.number,
        // Props that are only read in constructor:
        maxWidth: PropTypes.number,
        handleLogout: PropTypes.func,
        handleReload: PropTypes.func,
        handleEvents: PropTypes.func,
        loginData: PropTypes.object,
        haveLogoutButton: PropTypes.bool,
        defaultIntroVersion: PropTypes.string,
    };

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            playing: false,
            introVersion: this.props.defaultIntroVersion,
            rotateScreen: false,
            lastQuestion: {
                id: "",
                question: "",
                query_text: "",
                confidence: 0.0,
            },
            lastQuestionNeedsRefresh: false,
            lastQuestionFlagged: false,
            waitingForFlaggedListDownload: false,
            microphonesEnabled: true,
            showQuestions: true,
            videoState: {
                video_id: "",
                video_length: 0,
                video_time: 0,
                video_type: "idle",
                video_question: "",
                query_text: "",
                intent_confidence: 0.0
            },
            deviceList: [],
            sessionDetails: {
                expiresBy: String(new Date(Date.now() + 24*60*60*1000)).substring(0,24),
                numberOfDevices: 5,
                options: {
                    microphonesEnabled: true,
                    showQuestions: true,
                }
            },
            componentShouldUpdate: false,
        }

        this.handlePlayPause = this.handlePlayPause.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
        this.handlePlayVideo = this.handlePlayVideo.bind(this);
        this.handleDeleteSession = this.handleDeleteSession.bind(this);
        this.handleFlagAnswer = this.handleFlagAnswer.bind(this);
        this.handleStartDownloadFlaggedList = this.handleStartDownloadFlaggedList.bind(this);
        this.handleDownloadFlaggedListReady = this.handleDownloadFlaggedListReady.bind(this);
        this.handleMicrophonesEnabled = this.handleMicrophonesEnabled.bind(this);
        this.handleShowQuestionsEnabled = this.handleShowQuestionsEnabled.bind(this);

        this.setVideoState = this.setVideoState.bind(this);
        this.setDeviceList = this.setDeviceList.bind(this);

        this.deviceOverviewRef = React.createRef();
        
    };

    componentDidMount() {
        document.addEventListener("contextmenu", function(evt){
            evt.preventDefault();
        }, false);
    };

    componentWillUnmount() {
        document.removeEventListener("contextmenu", function(evt){
            evt.preventDefault();
          }, false);
    };

    shouldComponentUpdate(nextProps, nextState) {
        //always return true if there was a state update from a setter function
        if (nextState.componentShouldUpdate === true){
            this.setState({componentShouldUpdate: false})
            return true;
        }
        //update only on resize events to prevent flickering
        else if ((nextProps.height !== this.props.height) ||
            (nextProps.width !== this.props.width)){
            this.setState({componentShouldUpdate: false})
            return true;
        } else {
            return false;
        }
    }

    setSessionDetails(newSessionDetails){
        this.setState({
            componentShouldUpdate: true,
            microphonesEnabled: newSessionDetails.options.microphonesEnabled,
            showQuestions: newSessionDetails.options.showQuestions,
            sessionDetails: newSessionDetails,
        });
        console.log(newSessionDetails);
    }

    setVideoState(newVideoState){
        //check if lastQuestion needs to be updated
        if (newVideoState.video_type === "answer" && 
            (this.state.lastQuestionNeedsRefresh === true || 
                newVideoState.query_text !== this.state.lastQuestion.query_text || 
                newVideoState.video_id !== this.state.lastQuestion.video_id)) {
            this.setState({
                componentShouldUpdate: true,
                lastQuestion: {
                    id: newVideoState.video_id,
                    question: newVideoState.video_question,
                    query_text: newVideoState.query_text,
                    confidence: newVideoState.intent_confidence,
                },
                lastQuestionNeedsRefresh: false,
                lastQuestionFlagged: false, //do not trigger another state update
                videoState: newVideoState //this is by reference!
            })
        } else {
            //only update videoState
            this.setState({
                componentShouldUpdate: true,
                videoState: newVideoState
            })
        }
    }

    setDeviceList(newDeviceList){
        this.setState({
            componentShouldUpdate: true,
            deviceList: newDeviceList
        })
    }

    handleIntroVersionChange(val){
        this.setState({
            componentShouldUpdate: true,
            introVersion: val
        });
    }

    handlePlayPause(){
        /* Note: Pausing the video is not currently implemented */
        //this.setState({playing: !this.state.playing});
        this.props.handleEvents('play_pause');
    }

    handleSkip() {
        //make sure new answer triggers update:
        this.setState({
            lastQuestionNeedsRefresh: true
        })
        this.props.handleEvents('skip_answer');
    }


    timeToSecs(timeString){
        if (timeString === "" || timeString === 0){
            return 0;
        }
        console.log(timeString)
        var timeArray = timeString.split(':');
        if (timeArray.length > 2){ //allow minutes and seconds only
            return 0;
        }
        var floatTime = 0.0;
        for (var i = 0; i < timeArray.length; i++){
            floatTime = floatTime*60 + parseFloat(timeArray[i]);
        }
        floatTime = Math.round(floatTime*10)/10;
        return floatTime;
    }

    handlePlayVideo(videofile, loopfile, timestamp){
        
        var timeInSecs = this.timeToSecs(timestamp)
        if (timeInSecs !== 0){
            videofile = videofile + ':' + timeInSecs;
        }
        console.log(videofile)

        if (videofile === "") {
            this.props.handleEvents('play_video',{next_idle_loop: loopfile});
        } else if (loopfile === ""){
            this.props.handleEvents('play_video',{video_file: videofile});
        } else {
            this.props.handleEvents('play_video',{video_file: videofile, next_idle_loop: loopfile});
        }
    }

    handleDeleteSession(){
        this.props.handleEvents('delete_session');
    }

    handleFlagAnswer(){
        if (this.state.lastQuestion.query_text === "") {
            return;
        }
        Clipboardy.write(this.state.lastQuestion.query_text + "\t" + this.state.lastQuestion.question + "\t" + this.state.lastQuestion.id)
        this.props.handleEvents('flag_answer', this.state.lastQuestion.query_text);
        this.setState({
            componentShouldUpdate: true,
            lastQuestionFlagged: true
        })
    }

    handleStartDownloadFlaggedList(){
        this.props.handleEvents('download_flagged_list');
        this.setState({
            componentShouldUpdate: true,
            waitingForFlaggedListDownload: true
        })
    }

    handleDownloadFlaggedListReady(flaggedAnswerString){
        var element = document.createElement('a');
        var url = window.URL.createObjectURL(new Blob([flaggedAnswerString], {type: "application/octet-stream"}))
        element.setAttribute('href', url);
        element.setAttribute('download', "flaggedAnswers.txt");
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
        window.URL.revokeObjectURL(url);
        this.setState({
            componentShouldUpdate: true, 
            waitingForFlaggedListDownload: false
        })
    }

    formatSecondsToTime(time){
        var minutes = Math.floor(time / 60);
        var seconds = Math.floor(time - minutes * 60);

        var timeString = ("00" + minutes).slice (-2) + ':' + ("00" + seconds).slice (-2);
        return timeString;
    }

    formatVideoPlayTime(currentTime, totalTime){
        return this.formatSecondsToTime(currentTime) + '/' + this.formatSecondsToTime(totalTime)
    }


    handleMicrophonesEnabled(e){
        this.setState({
            componentShouldUpdate: true,
            microphonesEnabled: "disabled"
        });
        this.props.handleEvents('toggle_session_options', 'microphonesEnabled');
    }

    handleShowQuestionsEnabled(e){
        this.setState({
            componentShouldUpdate: true,
            showQuestions: "disabled"
        });
        this.props.handleEvents('toggle_session_options', 'showQuestions');
    }

    expandIntroList(list){
        var returnList = []
        for (var j = 0; j < list.length; j++) {
            for (var i = 0; i < list[j].jumppoints.length; i++){
                returnList.push(
                    {
                        filename: list[j].filename,
                        description: list[j].jumppoints[i].description,
                        jumppoint: list[j].jumppoints[i].timestamp,
                        jumpindex: i
                    }
                )
            }
        }

        return returnList;
    }

    render() {
        const listButtonSize = 35; //30;
        const smallButtonSize = 35;
        const largeButtonSize = 70;
        const BUTTON_RADIUS = 5;
        const BUTTON_MARGIN = 1;

        const deleteSessionModalRef = React.createRef();

        const lineColor = getComputedStyle(document.documentElement).getPropertyValue('--color-supportelements-active');

        var expandedIntroList = this.expandIntroList(config.controlsConfig.intro.list);
        var expandedExamplesList = this.expandIntroList(config.controlsConfig.examples.list);

        var controlsWidth = Math.min(this.props.width, this.props.maxWidth)

        return (
            <div className="app-body controls" 
                style={{overflowY: 'scroll', overflowX: 'hidden', top: '0px', marginBottom: '8px', height: this.props.height, width: this.props.width, padding: '8px'}}>
                <div className="flex-container-column"
                    style={{
                        width: '100%',
                        maxWidth: controlsWidth,
                        height: this.props.height}}>
                    {this.props.haveLogoutButton ?
                    <div className="flex-container-row" style={{minHeight: smallButtonSize, marginBottom: '2px'}}>
                        <label className="label-onbackground">
                                {config.copy.controls.activesession + ': ' + this.props.loginData.name}</label>
                        <LogoutButton handleLogout={this.props.handleLogout} handleReload={this.props.handleReload} size={smallButtonSize} iconFilter="filter-lightgrey"/>
                    </div> 
                    : null }
                    <div style={{width: '100%', position: 'relative', display: 'inline-block'}}>
                        <CollapseBar controlsOpen={true} name={config.copy.controls.video.title}>
                            <div className="flex-container-row" style={{alignItems: 'flex-start'}}>
                                <button
                                    onClick={this.handlePlayPause}
                                    className="btn btn-primary controls-large-btn"
                                    disabled="disabled">
                                    <img
                                        src={this.state.playing ? PauseIcon : PlayIcon} 
                                        alt="Play/Pause"/>
                                </button>
                                <button
                                    onClick={this.handleSkip}
                                    className="btn btn-primary controls-large-btn"
                                    disabled={this.state.videoState.video_type === "idle" ? "disable" : false}>
                                    <img
                                        src={PlayNextIcon} 
                                        alt="Play Next"/>
                                </button>
                                <div className="flex-container-row" 
                                    style={{wrap: 'nowrap', alignItems: 'flex-start', justifyContent: 'space-between', marginLeft: '15px'}}
                                    onClick={this.state.lastQuestionFlagged ? null : this.handleFlagAnswer}>
                                    <label className="label-onbackground" style={{width: 'calc(100%-30px)', textAlign: 'left'}}>
                                        {config.copy.controls.video.lastquestion + ': ' + this.state.lastQuestion.question} 
                                    </label>
                                    <button
                                        className="btn btn-secondary controls-small-btn-secondary">
                                        <img
                                            src={this.state.lastQuestionFlagged ? ListAddedIcon : FlagIcon}
                                            alt="Flag Answer"/>
                                    </button>
                                </div>
                            </div>
                            <div className="flex-container-row" style={{marginTop: '8px', marginBottom: '-8px'}}>
                                    <label className="label-onbackground" style={{flex: '1 1 65%', textAlign: 'left'}}>
                                        {config.copy.controls.video.state + ': ' + config.copy.controls.video.type[this.state.videoState.video_type]}
                                    </label>
                                    <label className="label-onbackground"style={{flex: '1 1 35%', textAlign: 'right'}}>
                                        {config.copy.controls.video.time + ': ' + this.formatVideoPlayTime(this.state.videoState.video_time, this.state.videoState.video_length)}
                                    </label>
                            </div>
                        </CollapseBar>
                        <CollapseBar controlsOpen={false} name={config.copy.controls.debug.title}>
                            <div className="controls-matching-debug-container">
                                <div className="label-onbackground">{config.copy.controls.debug.speechtotext + ':'}</div>
                                <div className="label-onbackground">{this.state.lastQuestion.query_text}</div>
                                <div className="label-onbackground">{config.copy.controls.debug.matchedquestion + ':'}</div>
                                <div className="label-onbackground">{this.state.lastQuestion.question}</div>
                                <div className="label-onbackground">{config.copy.controls.debug.confidence + ':'}</div>
                                <div className="label-onbackground">{this.state.lastQuestion.confidence ? Math.round(Number(this.state.lastQuestion.confidence) * 100) + '%' : null}</div>
                                <div className="label-onbackground">{config.copy.controls.debug.videofile + ':'}</div>
                                <div className="label-onbackground">{this.state.lastQuestion.id}</div>
                            </div>
                        </CollapseBar>
                        <CollapseBar controlsOpen={false} name={config.copy.controls.intro.title}>
                            <div className="flex-container-row" style={{paddingBottom: '4px'}}>
                                <button
                                    onClick={() => this.handlePlayVideo(config.controlsConfig.intro.soundtest_file, "", 0)}
                                    className="btn btn-primary controls-large-btn"
                                    style={{paddingRight: '8px', width: '50%'}}>
                                        <img
                                            src={PlayIcon} 
                                            alt="Play test"/>
                                        <label>{config.copy.controls.intro.testbutton}</label>
                                </button>
                                <button
                                    onClick={() => this.handlePlayVideo("", config.controlsConfig.intro.default_idleloop, 0)}
                                    className="btn btn-primary controls-large-btn"
                                    style={{marginRight: '0px', minWidth: '190px', width: '50%'}}>
                                        <img
                                            src={PlayIcon} 
                                            alt="Play Intro"/>
                                        <label>{config.copy.controls.intro.playintro}</label>
                                </button>
                                {/*
                                <div style={{width: "100%", display:'inline-block', textAlign: 'left', marginLeft: '10px'}}>
                                    <button id="kurzversion"
                                        className={this.state.introVersion === 'short' ? "btn btn-toggle active" :  "btn btn-toggle"}
                                        onClick={() => this.handleIntroVersionChange('short')}></button>
                                    <label htmlFor="kurzversion" className="label-onbackground">Kurzversion (20:09)</label><br/>
                                    <button id="langversion"
                                        className={this.state.introVersion === 'long' ? "btn btn-toggle active" :  "btn btn-toggle"}
                                        onClick={() => this.handleIntroVersionChange('long')}></button>
                                    <label htmlFor="langversion" className="label-onbackground">Langversion (44:15)</label>
                                </div>
                                */}
                            </div>
                            <div className="controls-intro-container">
                                {expandedIntroList.map((introEntry, index) => [
                                <div className="label-onbackground controls-griditem-underline" key={'text' + index}>{introEntry.description}</div>,
                                <div className="label-onbackground" style={{textAlign: 'right'}} key={'timestamp' + index}>{introEntry.jumppoint}</div>,
                                <button
                                    key={'btn' + index}
                                    onClick={() => this.handlePlayVideo(introEntry.filename, "", introEntry.jumppoint)}
                                    className={introEntry.jumpindex === 0 ? "btn btn-primary controls-list-btn" : "btn btn-secondary controls-list-btn-secondary"}>
                                    <img
                                        src={SmallPlayNextIcon} 
                                        key={'img' + index}
                                        alt="Play Intro"/>
                                </button>
                                ]
                                )}
                                {/*
                                <label htmlFor="customtimestamp" className="label-onbackground controls-griditem-underline">
                                    {config.copy.controls.intro.userjumppoint}
                                </label>
                                */}
                                <input type="text" 
                                    id="customfilename" 
                                    className="input-onbackground" 
                                    style={{marginTop: '2px', marginBottom: '2px'}}
                                    placeholder={config.copy.controls.intro.userjumppoint}/>
                                <input type="text" 
                                    id="customtimestamp" 
                                    className="input-onbackground" 
                                    style={{marginTop: '2px', marginBottom: '2px', marginRight: '-4px', textAlign: 'right'}}
                                    placeholder="00:00.0"/>
                                <button
                                    onClick={() => this.handlePlayVideo(document.getElementById("customfilename").value, "", document.getElementById("customtimestamp").value)}
                                    className="btn btn-primary controls-list-btn">
                                    <img 
                                        className="filter-mediumgrey"
                                        src={SmallPlayNextIcon} 
                                        alt="Play Intro"/>
                                </button>
                            </div>
                        </CollapseBar>
                        <CollapseBar controlsOpen={false} name={config.copy.controls.examples.title}>
                            <div className="controls-intro-container">
                                {expandedExamplesList.map((exampleEntry, index) => [
                                <div className="label-onbackground controls-griditem-underline" key={'text' + index}>{exampleEntry.description}</div>,
                                <div className="label-onbackground" style={{textAlign: 'right'}} key={'timestamp' + index}>{exampleEntry.jumppoint}</div>,
                                <button
                                    key={'btn' + index}
                                    onClick={() => this.handlePlayVideo(exampleEntry.filename, "", exampleEntry.jumppoint)}
                                    className={exampleEntry.jumpindex === 0 ? "btn btn-primary controls-list-btn" : "btn btn-secondary controls-list-btn-secondary"}>
                                    <img
                                        src={SmallPlayNextIcon} 
                                        key={'img' + index}
                                        alt="Play Example"/>
                                </button>
                                ]
                                )}
                            </div>
                        </CollapseBar>
                        <CollapseBar controlsOpen={true} name={config.copy.controls.session.title}>
                        <div className="flex-container-column">
                            <div className="controls-matching-debug-container" style={{marginBottom: '8px'}}>
                                <div className="label-onbackground">{config.copy.controls.session.sessionname + ': '}</div>
                                <div className="label-onbackground">{this.props.loginData.name}</div>
                                <div className="label-onbackground">{config.copy.controls.session.sessionid + ': '}</div>
                                <div className="label-onbackground">{this.props.loginData.id}</div>
                                <div className="label-onbackground">{config.copy.controls.session.validuntil +': '}</div>
                                <div className="label-onbackground">{this.state.sessionDetails.expiresBy}</div>
                                <button name="microphoneaktivinput" 
                                    className={this.state.microphonesEnabled === true ? "btn-checkbox active" : "btn-checkbox"}
                                    style={{marginLeft: '15px', marginTop: '10px'}} 
                                    onClick={this.handleMicrophonesEnabled}
                                    disabled = {this.state.microphonesEnabled === "disabled" ? "disabled" : false}>
                                </button>
                                <label htmlFor="microphoneaktivinput" className="label-onbackground">{config.copy.create.options.deactivatemics}</label>
                                <button name="questionsenabledinput" 
                                    className={this.state.showQuestions === true ? "btn-checkbox active" : "btn-checkbox"}
                                    style={{marginLeft: '15px', marginTop: '10px'}} 
                                    onClick={this.handleShowQuestionsEnabled}
                                    disabled = {this.state.showQuestions === "disabled" ? "disabled" : false}>
                                </button>
                                <label htmlFor="questionsenabledinput" className="label-onbackground">{config.copy.create.options.showquestions}</label>
                            </div>
                            
                            <DeleteSessionModal ref={deleteSessionModalRef} windowWidth = {controlsWidth} handleDeleteSession = {this.handleDeleteSession}/>
                            <div className="flex-container-row" style={{justifyContent: 'space-between'}}>
                                <button
                                    onClick={this.handleStartDownloadFlaggedList}
                                    className="btn btn-primary controls-large-btn"
                                    disabled = {this.state.waitingForFlaggedListDownload ? "disabled" : false}
                                    style={{marginLeft: '0px', flex:"1 1 50"}}>
                                        <img
                                            className={this.state.waitingForFlaggedListDownload ? "spin" : ""} 
                                            src={this.state.waitingForFlaggedListDownload ? LoadingIcon : DownloadIcon} 
                                            alt="Download"/>
                                        <label>{config.copy.controls.session.downloadflagged}</label>
                                </button>
                                <button
                                    onClick={() => deleteSessionModalRef.current.handleOpen()}
                                    className="btn btn-primary controls-large-btn"
                                    //disabled = "disabled"
                                    style={{marginRight: '0px', flex: "1 1 50%"}}>
                                        <img
                                            src={DeleteSessionIcon} 
                                            alt="Delete Session"/>
                                        <label>{config.copy.controls.session.deletesession}</label>
                                </button>
                            </div>
                        </div>  
                        </CollapseBar>
                        <CollapseBar controlsOpen={true} name={config.copy.controls.devices.title}>
                            {/*
                            <div className="flex-container-row">
                                <button
                                    onClick={this.handlePlayTest}
                                    className="btn btn-primary controls-large-btn"
                                    style={{paddingRight: '8px'}}>
                                        <img
                                            src={PlayIcon} 
                                            alt="Play test"/>
                                        <label>{config.copy.controls.devices.testbutton}</label>
                                </button>
                                
                                <label className="label-onbackground"
                                    style={{textAlign: 'left', whiteSpace: 'pre-wrap', marginLeft: '15px'}}>
                                    TODO: hier wäre noch Platz für Statuskram, z.B. eigene Verbingungsquali
                                </label> 
                            </div>
                            */}
                            <DeviceOverview imageSize={listButtonSize} deviceList={this.state.deviceList} ref={this.deviceOverviewRef}/>
                        </CollapseBar>
                    </div>
                </div>
                <KeyboardInputController
                    registeredComponents={{
                        position: [this.deviceOverviewRef], /*register for handleMove, handleScale callbacks */
                        vidctrl: [], /*register for htmlVideoPlayer callbacks, like play, pause */
                        effect3D: [], /* register for 3D effect callbacks handleChangeConvergence, ...*/
                        settings: this.handleShowSettings /* open settings menu */
                    }}
                />
            </div>
        );
    };
}
