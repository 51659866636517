//import m3u8_file_parser from 'm3u8-file-parser';
import m3u8_file_parser from './m3u8/reader.js';
const https = require('https');

const Writable = require('stream').Writable;
const emitter = require('events').EventEmitter;
//var util = require('util');


export default class m3u8parser extends Writable
{
    constructor ()
    {
        super();
        this.evt = new emitter();

        this.parser = new m3u8_file_parser();
        this.databuffer=[];
        this.result=[];
    }

    _write(chunk, encoding, next)
    {
        this.databuffer=this.databuffer.concat(chunk);
    }

    end()
    {
        this.parser.read(this.databuffer.toString());
        this.result=this.parser.getResult();
        for (var i=0; i<this.result.segments.length; i++)
        {
            this.evt.emit('item',this.result.segments[i]);
        }
        this.evt.emit('m3u',this.result);
        this.databuffer=[];
    }

    async fetchFragment(url)
    {
        const timectrl = new AbortController();
        const {timeout}=timectrl;

        setTimeout(()=> timectrl.abort(), 2000);

        var resp;
        var retry=0;
        var dataok=0;

        for (retry=0; retry<3; retry++)
        {
            try {
                resp = await fetch(url, { timeout, agent:new https.Agent({keepAlive: true}) });
                var data = await resp.arrayBuffer();
                dataok=1;
                break;
            } catch(err)
            {
                console.log("Error downloading M3U8 at "+url+ " -- Retry nr "+retry);
            }
        }
        if (dataok===0) return; // Error

        var strng = new TextDecoder().decode(data);
        this.parser.read(strng);
        this.result=this.parser.getResult();
        for (var i=0; i<this.result.segments.length; i++)
        {
            this.evt.emit('item',this.result.segments[i]);
        }
        this.evt.emit('m3u',this.result);
    }


    readURL(url)
    {
        this.fetchFragment(url);

    }
}
