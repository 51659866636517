import React from "react";
import PropTypes from 'prop-types'; //npm install --save prop-types
import { Link } from 'react-router-dom'; 
import * as Clipboardy from 'clipboardy'; //npm install clipboardy

import SessionBanner from '../utils/sessionBanner.js';

import CopyToClipBoardIcon from '../icons/copy_icon.svg';
import DownloadIcon from '../icons/download_icon.svg';
import JoinIcon from '../icons/join_icon.svg';

import globalConfig from '../globalConfig.js'
var config = {};

export default class confirmSession extends React.Component {
    static defaultProps = {
        maxWidth: 640,
    };

    static propTypes = {
        maxWidth: PropTypes.number,
    };

    constructor(props) {
        config = globalConfig.config;
        super(props);

        this.state = {
            sessionDescription: "no login data available",
            guestCredentials: "no login data available",
            sessionID: undefined,
            masterKey: undefined,
            guestKey: undefined,
        }
        this.handleResize = this.handleResize.bind(this);
        this.parseLoginData = this.parseLoginData.bind(this);
        this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
        this.handleSaveToFile = this.handleSaveToFile.bind(this);
    };



    parseLoginData(){
        var loginData;
        var sessionText = ""
        var guestText = ""

        function addLine(tag, value){
            return(tag + ': ' + value + '\r\n')
        }

        if (this.props.location && this.props.location.state){
            loginData = this.props.location.state.loginData

            var dateString = String(new Date(loginData.expiresBy));
            var timeSpacerPosition = dateString.search(":");
            dateString = dateString.substring(0, timeSpacerPosition+6)

            var sessionlink = window.location.origin + '/session/login/' + loginData.sessionID

            sessionText = sessionText + addLine(config.copy.confirm.directlink, sessionlink);
            sessionText = sessionText + addLine(config.copy.confirm.sessionname, loginData.name);
            sessionText = sessionText + addLine(config.copy.confirm.sessionid, loginData.sessionID);
            if (config.configflags.includes("enable_guest_session")) {
                sessionText = sessionText + addLine(config.copy.confirm.hostpassword, loginData.masterKey);
                sessionText = sessionText + addLine(config.copy.confirm.guestpassword, loginData.guestKey);

                guestText = guestText + addLine(config.copy.confirm.directlink, sessionlink);
                guestText = guestText + addLine(config.copy.confirm.sessionid, loginData.sessionID);
                guestText = guestText + addLine(config.copy.confirm.guestpassword, loginData.guestKey);
                guestText = guestText + addLine(config.copy.confirm.validuntil, dateString);
            } else {
                sessionText = sessionText + addLine(config.copy.confirm.password, loginData.masterKey);
            }
            sessionText = sessionText + addLine(config.copy.confirm.validuntil, dateString);
            sessionText = sessionText + addLine(config.copy.confirm.optionstitle, '');
            if (loginData.options.microphonesEnabled){
                sessionText = sessionText + '\t- ' + config.copy.confirm.options.deactivatemics + '\r\n'; /*\u2713 does not exist */
            }
            if (loginData.options.showQuestions){
                sessionText = sessionText + '\t- ' + config.copy.confirm.options.showquestions + '\r\n'; /*\u2713 does not exist */
            }
            
            this.setState({
                sessionDescription: sessionText,
                guestCredentials: guestText,
                sessionID: loginData.sessionID,
                masterKey: loginData.masterKey,
                guestKey: loginData.guestKey,
            })
        } else {
            //you should not be here, return to home
            var history = this.props.history;
            history.push("/");
        }
    }

    handleCopyToClipboard(text){
        Clipboardy.write(text)
    }

    handleSaveToFile(filename, sessionText){
        var element = document.createElement('a');

        element.setAttribute('href', window.URL.createObjectURL(new Blob([sessionText], {type: 'text/plain'})));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);

    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.parseLoginData();
    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleResize(){
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    render() {

        const VSPACE = '15px';
        const CONTAINER_PADDING = 7;
        var maximumWidth = Math.min(this.props.maxWidth, window.innerWidth)
        var maximumContainerWidth = maximumWidth - 2*CONTAINER_PADDING; //enforce maximum width on certain elements

        var showButtonText = true;
        var iconMarginRight = '5px';
        if (window.innerWidth < this.props.maxWidth){
            showButtonText = false;
            iconMarginRight = '0px';
        }

        return (
            <main className="app-body" style={{overflow: 'hidden', height: '100vh'}}>
                <SessionBanner type="header" width={this.state.width}/>
                <div className="flex-container-column" style={{height: "100%", justifyContent: 'center'}}>
                    <div style={{width: '100%', maxWidth: this.props.maxWidth, padding: '25px'}}>
                        <div className="grid-container" width="100%">
                            <label className="grid-itemspan label-onbackground">{config.copy.confirm.title}</label>
                            <div className="grid-itemspan div-readonlyinput" style={{maxWidth: maximumContainerWidth}}>
                                {this.state.sessionDescription}
                            </div>
                            <div className="grid-itemspan flex-container-row">
                                <Link
                                    to={{pathname:'/session/login/' + this.state.sessionID, state: {sessionKey: this.state.masterKey}}}
                                    className="btn btn-primary" 
                                    style={{flex: '1 1 0px', padding:'0px', align: 'center', margin: '1px'}}>
                                    <img 
                                        src={JoinIcon} 
                                        alt="Copy Icon" 
                                        height="90%"
                                        style={{marginRight: iconMarginRight}}/>
                                    {showButtonText ? config.copy.confirm.login : ''}
                                </Link>
                                <button 
                                    className="btn btn-primary" 
                                    style={{flex: '1 1 0px', padding:'0px', minHeight: 'calc(0.9*48px)', margin: '1px'}}
                                    onClick={() => this.handleCopyToClipboard(this.state.sessionDescription)} >
                                    <img 
                                        src={CopyToClipBoardIcon} 
                                        alt="Copy Icon" 
                                        height="90%"
                                        style={{marginRight: iconMarginRight}}/>
                                    {showButtonText ? config.copy.confirm.copy : ''}
                                </button>
                                <button
                                    className="btn btn-primary" 
                                    style={{flex: '1 1 0px', padding: '0px', margin: '1px'}}
                                    onClick={() => this.handleSaveToFile(config.copy.confirm.filenameprefix + "_host.txt", this.state.sessionDescription)} >
                                    <img 
                                        src={DownloadIcon} 
                                        alt="Copy Icon" 
                                        height="90%"
                                        style={{marginRight: iconMarginRight}}/>
                                    {showButtonText ? config.copy.confirm.download : ''}
                                </button>
                            </div>
                            {config.configflags.includes("enable_guest_session") ? 
                            <React.Fragment>
                            <label className="grid-itemspan label-onbackground">{config.copy.confirm.titleguestlogin}</label>
                            <div className="grid-itemspan div-readonlyinput" style={{maxWidth: maximumContainerWidth}}>
                                {this.state.guestCredentials}
                            </div>
                            <div className="grid-itemspan flex-container-row">
                                <Link
                                    to={{pathname:'/session/login/' + this.state.sessionID, state: {sessionKey: this.state.guestKey}}}
                                    className="btn btn-primary" 
                                    style={{flex: '1 1 0px', padding:'0px', align: 'center', margin: '1px'}}>
                                    <img 
                                        src={JoinIcon} 
                                        alt="Copy Icon" 
                                        height="90%"
                                        style={{marginRight: iconMarginRight}}/>
                                    {showButtonText ? config.copy.confirm.login : ''}
                                </Link>
                                <button 
                                    className="btn btn-primary" 
                                    style={{flex: '1 1 0px', padding:'0px', minHeight: 'calc(0.9*48px)', margin: '1px'}}
                                    onClick={() => this.handleCopyToClipboard(this.state.guestCredentials)} >
                                    <img 
                                        src={CopyToClipBoardIcon} 
                                        alt="Copy Icon" 
                                        height="90%"
                                        style={{marginRight: iconMarginRight}}/>
                                    {showButtonText ? config.copy.confirm.copy : ''}
                                </button>
                                <button
                                    className="btn btn-primary" 
                                    style={{flex: '1 1 0px', padding: '0px', margin: '1px'}}
                                    onClick={() => this.handleSaveToFile(config.copy.confirm.filenameprefix + "_guest.txt", this.state.guestCredentials)} >
                                    <img 
                                        src={DownloadIcon} 
                                        alt="Copy Icon" 
                                        height="90%"
                                        style={{marginRight: iconMarginRight}}/>
                                    {showButtonText ? config.copy.confirm.download : ''}
                                </button>
                            </div>
                            </React.Fragment>
                            : null }
                        </div>
                    </div>
                </div>
                <SessionBanner type="footer" width={this.state.width}/>
            </main>
        );
    };
}

