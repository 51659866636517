import React from "react";
import { Link } from 'react-router-dom'

// Import Components
import Backend from './sessions/backendCalls.js';
import createRandomKey from './utils/randomKeys.js';
import SessionBanner from './utils/sessionBanner.js';
import ErrorPopup from './utils/errorPopup.js';
import WaitingForServerOverlay from './utils/waitingForServerOverlay.js';

// Import Icons
import JoinSessionIcon from './icons/join_group_icon.svg';
import CreateSessionIcon from './icons/create_group_icon.svg';
import PrivateSessionIcon from './icons/private_session_icon.svg';

import globalConfig from './globalConfig.js'
var config = {};

export default class home extends React.Component {

    constructor(props) {
        config = globalConfig.config;

        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            loginData: undefined,
            waitingForServer: false,
        }
        this.handleResize = this.handleResize.bind(this);
        this.createCredentials = this.createCredentials.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleServerStatus = this.handleServerStatus.bind(this);

        this.errorPopupRef = React.createRef();
        this.server = new Backend();
    };

    handleResize(){
        //detect when it is necessary to rearrage flex-box items in columns/rows
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    createCredentials(){
        //create ID and password
        var sessionID = createRandomKey(16,4);
        var masterKey = createRandomKey(6,6);

        var daysValid = 1;
        var dateToday = Date.now();
        var expireDate = dateToday + 24*60*60*1000*daysValid;

        /* TODO: what should be put in as default values for integrated sessions? */
        this.state.loginData = { /* no setState, as the update should be synchronous and there is no need for a rerender */
            daysValid: daysValid,
            expiresBy: expireDate, 
            name: config.copy.home.privatesession,
            numberOfDevices: 5, 
            sessionID: sessionID,
            masterKey: masterKey,
	    guestKey: "",
            options: {
                microphonesEnabled: true,
                showQuestions: true
            },
            currentApp: 'integrated'
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.createCredentials(); /* credentials for integrated session */
        if (config.application === "demo"){ /* in demo mode continue automatically to the appLoader*/
            this.handleButtonClick('/app')
        }
    };
      
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleButtonClick(path){

        if (path === '/app'){
            //create a new session
            console.log(this.loginData)
            this.server.connect('newSess', this.state.loginData, "", this.handleServerStatus);
            this.setState({waitingForServer: true})
        } else {
            this.props.history.push({pathname: path})
        }
    }

    handleServerStatus(status){
        if (status === "ack_new_sess"){
            this.setState({waitingForServer: false})
            //make deep copy before leaving the site
            this.props.history.push({
                pathname: '/app', 
                state: {
                    name: this.state.loginData.name, 
                    id: this.state.loginData.sessionID, 
                    key: this.state.loginData.masterKey, 
                    currentApp: this.state.loginData.currentApp
                }
            })
        } else if (status === "nack_new_sess"){
            //show error popup
            this.setState({waitingForServer: false})
            if (this.errorPopupRef.current){
                this.errorPopupRef.current.showPopup("newsessrefused");
            }
        }
    }

    render() {
        const BUTTON_MINWIDTH = 220;
        const BUTTON_MAXWIDTH = 260;
        const BUTTON_MINHEIGHT = 110;
        const BUTTON_MAXHEIGHT = 140;
        const BUTTON_RADIUS = 15;
        const BUTTON_MARGIN = 2;

        const loginButtons = [ 
            { id: 0, src: JoinSessionIcon, imageheight:"100%", buttontext: config.copy.home.join, link: '/session/login'},
            { id: 1, src: CreateSessionIcon, imageheight:"100%", buttontext: config.copy.home.create, link: '/session/create'},
            { id: 2, src: PrivateSessionIcon, imageheight:"100%", buttontext: config.copy.home.privatesession, link: '/app'},
        ]

        var buttonAlignment;
        var buttonHeight;
        var buttonWidth;
        if (this.state.width > 3*(BUTTON_MAXWIDTH + 2*BUTTON_MARGIN)) {
            buttonAlignment = 'row';
            buttonWidth = BUTTON_MAXWIDTH;
            buttonHeight = BUTTON_MAXHEIGHT;
        } else {
            buttonAlignment = 'column';
            buttonWidth = BUTTON_MINWIDTH;
            buttonHeight = BUTTON_MINHEIGHT;
        }

        return (
            <main className="app-body">
                <ErrorPopup ref={this.errorPopupRef}/>
                <WaitingForServerOverlay visible={this.state.waitingForServer}/>
                <SessionBanner type="header" width={this.state.width}/>
                <div id="container"
                    style={{
                        height: "100%",
                        display: 'flex',
                        flexDirection: buttonAlignment,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    {loginButtons.map((loginbtn) =>
                        <button
                            onClick = {() => this.handleButtonClick(loginbtn.link)}
                            /*to={{pathname: loginbtn.link, state: this.state.loginData}}*/
                            className="btn btn-primary"
                            key={loginbtn.id}
                            style={{
                                width: buttonWidth, 
                                height: buttonHeight,
                                padding: '0px',
                                paddingBottom: '2px',
                                margin: BUTTON_MARGIN,
                                borderRadius: BUTTON_RADIUS}}>
                            <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <img
                                    key={"loginimg" + loginbtn.id}  
                                    src={loginbtn.src} 
                                    alt="" 
                                    height="100px"
                                    style={{marginBottom:'-12px'}}/>
                                <label>{loginbtn.buttontext}</label>
                            </div>
                        </button>
                    )} 
                </div>
                <SessionBanner type="footer" width={this.state.width}/>
            </main>
        );
    };
}
